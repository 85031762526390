<script>
    export default {
        name: 'MDMDebug',
        props: ['headset', 'modelValue'],
        data(){
          return {
            lastMessagesEmittingPeer: []
          }
        },
        methods: {
          updateLastMessagesEmittingPeer() {
            if (this.headset?.emitingRTCConnection?.isClosed()) return []
            let monitoring = this.headset?.emitingRTCConnection?.monitoring
            let monitArray = monitoring?.received?.merge(
                'received',
                'sended',
                monitoring?.sended || []
            )
            this.lastMessagesEmittingPeer = monitArray?.lastMessages || []
          }
        },
        mounted() {
          this.updateLastMessagesEmittingPeer()
        },
        watch: {
          "headset.emitingRTCConnection.monitoring.received": {
            handler(){
              this.updateLastMessagesEmittingPeer()
            },
              deep: true
          }
        },
    }
</script>
<template>
    <div
        v-if="modelValue"
        class="mdm-debug-container"
    >
        <div>
            <p>
                Emitting Peer
                <b>
                  ({{
                        this.headset?.emitingRTCConnection?.active
                            ? 'Connected'
                            : 'Not Connected'
                    }})
                </b>
            </p>

            <div v-if="headset?.emitingRTCConnection">
                <span
                    >Messages sended :
                    {{
                        headset?.emitingRTCConnection?.monitoring.sended.length
                    }}
                    |
                </span>
                <span
                    >Messages receive :
                    {{
                        headset?.emitingRTCConnection?.monitoring.received
                            .length
                    }}
                    |
                </span>
                <span>
                    Tracks in stream :
                    {{
                        headset?.emitingRTCConnection?.monitoring.streamTracks
                            .length
                    }}
                </span>
            </div>
            <em v-else>No peer connected</em>
            <div class="messages-list">
                <div
                    v-for="(message, i) in lastMessagesEmittingPeer"
                    :key="i"
                >
                    <span
                        >{{ message.label == 'sended' ? '⬆️' : '⬇️' }}
                        {{ message.data }}</span
                    >
                </div>
            </div>
        </div>
        <div>
            <p>
                Receiving Peer
                <b
                    >({{
                        this.headset?.receivingRTCConnection?.active
                            ? 'Connected'
                            : 'Not Connected'
                    }})</b
                >
            </p>
            <div v-if="headset?.receivingRTCConnection">
                <span
                    >Messages sended :
                    {{
                        headset?.receivingRTCConnection?.monitoring.sended
                            .length
                    }}
                    |
                </span>
                <span
                    >Messages receive :
                    {{
                        headset?.receivingRTCConnection?.monitoring.received
                            .length
                    }}
                    |
                </span>
                <span>
                    Tracks in stream :
                    {{
                        headset?.receivingRTCConnection?.monitoring.streamTracks
                            .length
                    }}
                </span>
            </div>
            <em v-else>No peer connected</em>
        </div>
    </div>
</template>
<style lang="scss">
    .mdm-debug-container {
        padding: 1em;
        background: rgba(58, 58, 58, 0.5);
        color: yellow;
        position: absolute;
        bottom: 5em;
        left: 1em;
        pointer-events: none;
        max-width: calc(100% - 2em);
        z-index: 1000;
        display: flex;
        gap: 1em;

        .messages-list {
            font-size: smaller;
            opacity: 0.6;
        }
    }
</style>
