import ApiClient from '..'

export class FormersProvider {
    static api = ApiClient.getInstance()
    static alerts = {
        emailSended() {
            return this.$t('learner_list.onboarding_dialog.send_success')
        },
        added(user) {
            return this.$t('former.alerts.added', {
                name: user.identity,
                type: user.is_demo ? 'prospect' : 'client',
                link:
                    '/companies/' +
                    user.company_id +
                    '/users/' +
                    user.id +
                    '/profil',
            })
        },
    }

    static route(companyID) {
        return `/companies/${companyID}/users`
    }
    static getFormers(companyID, query = {}) {
        return this.api.get(this.route(companyID), query)
    }
    static getFormer(companyID, formerID, query = {}) {
        return this.api.get(this.route(companyID) + '/' + formerID, query)
    }
    static updateFormer(companyID, formerID, payload, query = {}) {
        return this.api.put(
            this.route(companyID) + '/' + formerID,
            payload,
            query
        )
    }
    static updateMultiple(companyID, payload, query = {}) {
        return this.api.post(this.route(companyID) + '/update', payload, query)
    }
    static createFormer(companyID, payload, query = {}) {
        return this.api.post(this.route(companyID), payload, query)
    }
    static deleteMultipleFormers(companyID, formerIds) {
        return this.api.post(this.route(companyID) + '/delete', formerIds)
    }
}
