import MyStats from './MyStats.routes.js'
import MyUsers from './MyUsers.routes.js'
import Formers from './Formers.routes.js'
import Learners from './Learners.routes.js'
import Content from './Content.routes.js'
import { headsetsRoutes } from '@core/router/routes/generators/Headset.js'
import { formersRoutesMeta } from '@core/router/routes/Former/meta.js'
import { AuthentificatedRoutesName } from '@core/router/routes/Authentificated/index.js'
import { adminFormersRoutesMeta as meta } from './meta.js'

export default [
    ...MyStats,
    ...MyUsers,
    ...Formers,
    ...Learners,
    ...Content,
    ...headsetsRoutes('', formersRoutesMeta, '/my-headsets'),
    {
        path: '/home',
        name: AuthentificatedRoutesName.home,
        meta: formersRoutesMeta,
        component: () => import('@pages/Home.vue'),
    },
]
