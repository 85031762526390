<script>
export default {
  props: ["questions"],
  name: "SessionsAutoEvaluationQuesionsRepartion",
};
</script>
<template>
  <ra-box class="questions-grid" padding="6,,,">
    <div v-for="q in questions" class="question rounded-lg pa-4">
      <div class="question-header">
        <b v-html="q.name"></b><br />
        <small>{{ q.answers_count }} réponses</small>
      </div>
      <div class="question-choices ma-4">
        <template v-for="(c, i) in q.choices">
          <span
              :class="[
                  c.content.length > 20 ? 'overflowing-text' : '',
              ]"
          >
              <span v-html="c.content"></span>
          </span>
          <div class="bar">
            <div
                :style="{
                    '--w':
                        q.answers_repartitions[i].percentage + '%',
                }"
                class="filler"
            ></div>
          </div>
          <span class="text-right">
            {{
              Math.round(q.answers_repartitions[i].percentage)
            }}%
          </span>
        </template>
      </div>
    </div>
  </ra-box>
</template>
<style lang="scss">
.questions-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 1rem;
  color: var(--primary);

  .question {
    background: #ecebf1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .question-choices {
      .overflowing-text {
        cursor: default;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        span {
          transform-origin: center center;
          display: inline-block;
          &:hover {
            animation: animateText 3s linear 1;
          }
        }
      }

      display: grid;
      font-weight: 600;
      gap: 5px;
      grid-auto-rows: auto;
      grid-template-columns: minmax(auto, 200px) 10fr auto;
      align-items: center;
      .bar {
        margin: 0 0.5rem;
        height: 16px;
        overflow: hidden;
        background: #dedde4;
        border-radius: 50px;
        .filler {
          border-radius: 50px;
          background: var(--primary-gradient);
          height: 100%;
          width: var(--w);
        }
      }
    }
  }
}

@keyframes animateText {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-40%);
  }
  100% {
    transform: translateX(0%);
  }
}
</style>
