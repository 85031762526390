import { useUserStore } from '@store/user.js'
import { APIError } from '@api/APIError.js'
import { AxiosError } from 'axios'

export async function loadUserStore() {
    const userStore = useUserStore()
    if (!window.IS_ONLINE) {
        userStore.isReady = true
        return
    }
    try {
        await userStore.rawFetch(false)
    } catch (e) {
        if (e instanceof AxiosError && e.code === 'ERR_NETWORK') {
            userStore.isReady = true
            return
        }

        if (!(e instanceof APIError) || !e.isAuthError()) {
            throw e
        }

        const success = await userStore.getNewToken(false)
        if (!success) {
            await userStore.logout()
            if (!window.IS_TABLET) window.location.href = '/'
        } else await userStore.rawFetch(false)
    } finally {
        userStore.isReady = true
    }
}
