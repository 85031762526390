import { mapState } from 'pinia'
import { useUserStore } from '@store/user.js'

export default {
    props: {
        targetedCompany: {
            type: Object,
            default: null,
        },
    },
    computed: {
        ...mapState(useUserStore, {
            _user: 'user',
            _apprenant: 'apprenant',
        }),

        company() {
            const authCompany = this._user?.company || this._apprenant?.company
            if (this._apprenant || !['god'].includes(this._user?.role?.name))
                return authCompany
            return this.targetedCompany || authCompany
        },
        companyID() {
            return this.company?.id
        },

        myCompany() {
            return this._user?.company || this._apprenant?.company
        },
    },
}
