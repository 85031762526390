<script>
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import { LangProvider } from '@providers'
    import { mapActions } from 'pinia'
    import { useLanguageStore } from '@store/lang.js'

    export default {
        name: 'LocaleEditDialog',
        mixins: [mixins.RaDialogMixin],
        props: {
            locales: {
                type: Array,
                default: () => [],
            },
        },
        data() {
            return {
                formElement: {
                    editLanguages: [],
                },
                errors: {},
            }
        },
        created() {
            this.formElement.editLanguages = [...this.locales]
        },
        computed: {
            errorsFormated() {
                let errors = {}
                for (let err in this.errors) {
                    errors[`editLanguages.${err}`] = this.errors[err]
                }
                return errors
            },
            inputs() {
                return [
                    {
                        type: 'form-row',
                        elements: [
                            {
                                is: 'form-input',
                                label: 'Langues',
                                type: 'related-items',
                                isDraggable: false,
                                property: 'editLanguages',
                                addText: 'Ajouter une langue',
                                default: {
                                    name: '',
                                    code: '',
                                },
                                errors: this.errorsFormated,
                                formData: {
                                    inputs: [
                                        {
                                            type: 'form-row',
                                            density: 'dense',
                                            elements: [
                                                {
                                                    is: 'form-input',
                                                    label: 'Nom',
                                                    placeholder: 'Nom',
                                                    translatable: false,
                                                    type: 'text',
                                                    property: 'name',
                                                    errors: this.errorsFormated,
                                                },
                                                {
                                                    is: 'form-input',
                                                    label: 'Code',
                                                    placeholder: 'Code',
                                                    translatable: false,
                                                    type: 'text',
                                                    property: 'code',
                                                    errors: this.errorsFormated,
                                                },
                                            ],
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                ]
            },
            value: {
                get() {
                    return this.locales
                },
                set(value) {
                    this.$emit('update:locales', value)
                },
            },
        },
        methods: {
            ...mapActions(useLanguageStore, ['setLocales']),
            async handleSubmit() {
                try {
                    const payload = this.formElement.editLanguages
                    const { data } = await LangProvider.updateLocales(payload)
                        .bindVue(this)
                        .call()
                    this.setLocales(data)
                    this.close()
                } catch (e) {
                    console.error(e)
                }
            },
        },
    }
</script>

<template>
    <ra-base-dialog>
        <template #title> Modifier les langues</template>
        <ra-form
            :element="formElement"
            :inputs="inputs"
            @submit="handleSubmit"
        />
    </ra-base-dialog>
</template>
