<script>
    export default {
        name: 'StartFormationProgress',
        props: {
            currentStep: {
                type: Number,
                default: null,
            },
        },
        computed: {
            steps() {
                return window.IS_TABLET
                    ? [
                          {
                              number: 1,
                              label: {
                                  light: this.$t(
                                      'headsets.pilotage.steps.step0.title.content2'
                                  ),
                                  huge: this.$t(
                                      'headsets.pilotage.steps.step0.title.content_huge'
                                  ),
                              },
                          },
                          {
                              number: 2,
                              label: {
                                  light: this.$t(
                                      'headsets.pilotage.steps.step1.title.content'
                                  ),
                                  huge: this.$t(
                                      'headsets.pilotage.steps.step1.title.content_huge'
                                  ),
                              },
                          },
                          {
                              number: 3,
                              label: {
                                  light: this.$t(
                                      'headsets.pilotage.steps.step2.title.content'
                                  ),
                                  huge: this.$t(
                                      'headsets.pilotage.steps.step2.title.content_huge'
                                  ),
                              },
                          },
                      ]
                    : [
                          {
                              number: 1,
                              label: {
                                  light: this.$t(
                                      'headsets.pilotage.steps.step1.title.content'
                                  ),
                                  huge: this.$t(
                                      'headsets.pilotage.steps.step1.title.content_huge'
                                  ),
                              },
                          },
                          {
                              number: 2,
                              label: {
                                  light: this.$t(
                                      'headsets.pilotage.steps.step2.title.content'
                                  ),
                                  huge: this.$t(
                                      'headsets.pilotage.steps.step2.title.content_huge'
                                  ),
                              },
                          },
                          {
                              number: 3,
                              label: {
                                  light: this.$t(
                                      'headsets.pilotage.steps.step3.title.content'
                                  ),
                                  huge: this.$t(
                                      'headsets.pilotage.steps.step3.title.content_huge'
                                  ),
                              },
                          },
                      ]
            },
            padding() {
                return window.IS_TABLET ? '6,10,0,10' : '0,4,6,4'
            },
            isTablet() {
                return window.IS_TABLET
            },
        },
        methods: {
            getStepClass(step, index) {
                if (index == this.currentStep) {
                    return 'active'
                }
                if (index < this.currentStep) {
                    return 'done'
                }
                return ''
            },
        },
    }
</script>

<template>
    <ra-box
        :padding="padding"
        class="start-formation-progress"
    >
        <v-btn
            class="start-formation-progress-close"
            density="comfortable"
            icon="mdi-arrow-left"
            variant="plain"
            @click="$emit('back')"
        />
        <ra-stack direction="horizontal">
            <ra-box
                v-for="(step, index) of steps"
                :class="getStepClass(step, index)"
                class="step"
            >
                <b class="chip bg-primary">{{ step.number }}</b>
                <ra-typo>
                    {{ step.label.light }}
                    <ra-typo bold>{{ step.label.huge }}</ra-typo>
                </ra-typo>
            </ra-box>
        </ra-stack>
    </ra-box>
</template>

<style lang="scss" scoped>
    .start-formation-progress {
        display: flex;
        align-items: center;
        position: relative;
        overflow: visible;

        &-close {
            position: absolute;
            left: 0px;

            [data-device='tablet'] & {
                left: 60px;
            }
        }

        .ra-stack {
            padding-left: 30px;

            [data-device='tablet'] & {
                padding-left: 75px;
            }

            @media screen and (max-width: 700px) {
                [data-device='web'] & {
                    flex-wrap: wrap;
                    gap: 20px !important;
                    .step {
                        &:after {
                            display: none;
                        }
                    }
                }
            }

            gap: 100px;

            .step {
                overflow: visible;
                display: flex;
                align-items: center;
                gap: 22px;

                & + .step {
                    position: relative;

                    &:after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        z-index: 10;
                        left: -90px;
                        width: 80px;
                        height: 1px;
                        border-bottom: 3px solid var(--primary);
                    }
                }

                &:not(.active):not(.done) {
                    opacity: 0.5;

                    &:after {
                        border: none;
                        --color: var(--primary);
                        height: 3px;
                        background-image: repeating-linear-gradient(
                                -3deg,
                                var(--color),
                                var(--color) 30px,
                                transparent 30px,
                                transparent 60px,
                                var(--color) 60px
                            ),
                            repeating-linear-gradient(
                                87deg,
                                var(--color),
                                var(--color) 30px,
                                transparent 30px,
                                transparent 60px,
                                var(--color) 60px
                            ),
                            repeating-linear-gradient(
                                177deg,
                                var(--color),
                                var(--color) 30px,
                                transparent 30px,
                                transparent 60px,
                                var(--color) 60px
                            ),
                            repeating-linear-gradient(
                                267deg,
                                var(--color),
                                var(--color) 30px,
                                transparent 30px,
                                transparent 60px,
                                var(--color) 60px
                            );
                        background-size:
                            7px 100%,
                            100% 7px,
                            7px 100%,
                            100% 7px;
                        background-position:
                            0 0,
                            0 0,
                            100% 0,
                            0 100%;
                        background-repeat: no-repeat;
                    }
                }

                &:not(.active) .ra-typography {
                    display: none;
                }
            }
        }
    }
</style>
