export class Structure {
    constructor(data) {
        if (data) this.patch(data)
    }
    static getRules() {
        throw new Error('Structure.getRules must be implemented')
    }
    static getDefault() {
        throw new Error('Structure.getDefault must be implemented')
    }

    static validate(data) {
        const rules = this.getRules()
        const errors = {}
        for (const key in rules) {
            const rule = rules[key]
            const value = data[key]
            if (rule === 'required' && !value) {
                errors[key] = 'required'
            }
        }
        return errors
    }

    patch(data) {
        for (const key in data) {
            this[key] = data[key]
        }
        return this
    }

    reset(object) {
        for (const key in this) {
            if (object[key] === undefined) {
                delete this[key]
            }
        }

        for (const key in object) {
            this[key] = object[key]
        }
    }

    clone() {
        return new this.constructor(this)
    }
}
