<script>
import AffectGroupDialog from "./AffectGroupDialog.vue";
import {mixins} from "@realityteam/reality-academy-ui-kit";
export default {
  name: "AffectGroupDialogContainer.vue",
  components: {AffectGroupDialog},
  mixins: [mixins.RaDialogsMixin],
}
</script>

<template>
  <ra-dialog ref="dialog-affect-group">
    <affect-group-dialog
        v-bind="$attrs"
    ></affect-group-dialog>
  </ra-dialog>
</template>