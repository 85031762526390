import { Structure } from '@libs/Structures/Structure.js'
import { useUserStore } from '@store/user.js'
import moment from 'moment'
import { FormationStructure } from '@libs/Structures/FormationStructure.js'

export class WorkshopStructure extends Structure {
    constructor(...args) {
        super(...args)
    }

    static getDefault() {
        const workshop = new WorkshopStructure()
        const userStore = useUserStore()
        return workshop.patch({
            id: null,
            name: '',
            parameters: {
                ModeLancement: 'utilisation',
            },
            formations: [],
            formation_modules: [],
            date: null,
            start_time: null,
            description: null,
            end_time: null,
            is_published: false,
            custom_company_name: null,
            custom_logo: null,
            company:
                userStore.hasRole(['god', 'super_god']) || !userStore.isAuth
                    ? null
                    : userStore.company,
            formateur: null,
            formateur_id: null,
            company_id:
                userStore.hasRole(['god', 'super_god']) || !userStore.isAuth
                    ? null
                    : userStore.company.id,
            slots: [
                {
                    start_time: null,
                    end_time: null,
                    max_apprenants: 6,
                },
            ],
            documents: [],
            questions: [],
            stats: null,
        })
    }

    patch(data) {
        super.patch(data)
        this.formations = this.formations.map((f) => new FormationStructure(f))
        return this
    }

    get status() {
        if (this.date) {
            let startDate = moment(
                this.date + ' ' + this.start_time,
                'YYYY-MM-DD HH:mm:ss'
            ).format()
            let endDate = moment(
                this.date + ' ' + this.end_time,
                'YYYY-MM-DD HH:mm:ss'
            ).format()

            // Date actuelle
            var now = moment().format()
            // Comparaison
            if (now < startDate) {
                return {
                    can_register: true,
                    color: 'warning',
                    text: 'workshops.upcomming',
                }
            } else if (now > endDate) {
                return {
                    can_register: false,
                    color: 'success',
                    text: 'workshops.ended',
                }
            } else {
                return {
                    can_register: false,
                    color: 'info',
                    text: 'workshops.inprogress',
                }
            }
        }
        return {
            can_register: false,
            color: 'warning',
            text: 'workshops.upcomming',
        }
    }
}
