<script setup>
    import { useRouter } from 'vue-router'

    const router = useRouter()

    function handleGoBack(){
        router.push('/')
    }
</script>

<template>
    <ra-stack class="not-found-error"  alignment="center" gap="0">
        <h4>404</h4>
        <ra-typo class="text-center cur">
            {{$t('generics.errors.not_found.base')}},
            <ra-typo
                margin="8,0"
                class="clickable" semi underline @click="handleGoBack">
                {{$t('generics.errors.not_found.cta')}}
            </ra-typo>
        </ra-typo>
    </ra-stack>
</template>

<style scoped lang="scss">
    .not-found-error{
        h4{
            opacity: 0.5;
            font-size: 3em;
        }

        .clickable{

        }
    }
</style>