import { godRoutesMeta as meta } from '@core/router/routes/God/meta.js'
import { DevRoutesNames } from '@routes/God/Dev.names.js'

export default [
    {
        path: '/dev',
        redirect: { name: DevRoutesNames.distributor.index },
        children: [
            {
                path: 'distributors',
                children: [
                    {
                        path: '',
                        name: DevRoutesNames.distributor.index,
                        component: () =>
                            import('@pages/Developers/DistributorsPage.vue'),
                        meta,
                    },
                ],
            },
            {
                path: 'websocket',
                name: DevRoutesNames.websocket.index,
                component: () => import('@pages/Developers/WebsocketPage.vue'),
                meta,
            },
        ],
    },
]
