export function humanizeBytes(bytes, precision = 2) {
    const divider = 1000 //use 1024 for more accurate but less humanized
    const units = ['o', 'Ko', 'Mo', 'Go', 'To']
    let index = 0

    while (bytes >= divider && index < units.length - 1) {
        bytes /= divider
        index++
    }

    let multiplier
    let fixed
    if (precision < 0) {
        multiplier = 1 / Math.pow(10, Math.abs(precision))
        fixed = 0
    } else {
        multiplier = Math.pow(10, precision)
        fixed = precision
    }
    bytes = Math.round(bytes * multiplier) / multiplier
    if (bytes === 0) return '0 o'
    return `${bytes.toFixed(fixed)} ${units[index]}`
}
