<script>
    import { MonitoringProvider } from '@providers/MonitoringProvider.js'

    export default {
        name: 'StorageStatusBar',
        data() {
            return {
                storageStatus: null,
            }
        },
        mounted() {
            this.handleFetch()
        },

        methods: {
            async handleFetch() {
                try {
                    const { data } =
                        await MonitoringProvider.getStorage().call()
                    this.storageStatus = data
                } catch (e) {
                    console.error(e)
                }
            },
        },
    }
</script>

<template>
    <ra-stack
        v-if="storageStatus"
        gap="1"
    >
        <ra-typo
            size="small"
            class="text-right"
        >
            {{ storageStatus.formatedUsed }} /
            {{ storageStatus.formatedTotal }}
        </ra-typo>
        <v-progress-linear
            class="rounded"
            v-model="storageStatus.percent"
            color="white"
            height="5"
        />
        <ra-typo
            size="x-small"
            class="text-right"
            >{{ storageStatus.percent }}%</ra-typo
        >
    </ra-stack>
</template>

<style scoped lang="scss">
    .ra-stack {
        border-bottom: 0px !important;
    }
</style>
