<script>
    import avatarChip from '@ui/AvatarChip.vue'
    export default {
        components: {
            avatarChip,
        },
        props: {
            user: {
                type: Object,
                default: undefined,
            },
        },
        data() {
            return {
                hasError: false,
            }
        },
    }
</script>
<template>
    <ra-box
        v-ripple
        centered-content
        class="account-container"
        padding=",3"
        v-bind="$attrs"
        data-test="user-account-activator"
    >
        <v-icon size="large">mdi-account-circle</v-icon>
        <ra-stack
            alignement="center"
            direction="horizontal"
        >
            <ra-box>
                <ra-typo bold> {{ user.identity }}</ra-typo>
                <ra-typo variant="subtitle">{{ user.role.title }}</ra-typo>
            </ra-box>
            <avatar-chip :user="user" />
        </ra-stack>
    </ra-box>
</template>
<style lang=""></style>
