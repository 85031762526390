import ApiClient from '..'

export class LangProvider {
    static route = '/languages'
    static getLocales() {
        const api = ApiClient.getInstance()
        return api.get(this.route)
    }

    static updateLocales(payload) {
        const api = ApiClient.getInstance()
        return api.post(this.route, payload)
    }
}
