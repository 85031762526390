<template>
    <div>
        <div class="my-6 mx-3 progress-circle d-flex justify-center align-center">
            <ra-stack :width="(Number(size) * 0.8) + 'px'" class="progress-circle__content">
                <div class="progress-circle__content__value">
                    <span v-html="completion"></span><small>%</small>
                </div>
                <div class="progress-circle__content__label">
                    <span v-html="label"></span>
                </div>
            </ra-stack>
            <div
                class="progress-circle__progress row justify-center align-center"
            >
                <svg
                    :height="Number(size)"
                    :width="Number(size)"
                    class="progress-circle__progress__svg"
                    viewBox="0 0 100 100"
                >
                    <circle
                        :stroke="barColor"
                        class="progress-circle__progress__svg__circle"
                        cx="50"
                        cy="50"
                        fill="transparent"
                        r="45"
                        stroke-dasharray="283"
                        stroke-dashoffset="0"
                    ></circle>
                    <circle
                        :stroke="fillColor"
                        :stroke-dashoffset="283 - (completion / 100) * 283"
                        class="progress-circle__progress__svg__circle"
                        cx="50"
                        cy="50"
                        fill="transparent"
                        r="45"
                        stroke-dasharray="283"
                    ></circle>
                </svg>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name:"ChartProgressCircle",
    props: {
      completion: {
            type: Number,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        fillColor: {
            type: String,
            default: "#aa3492",
        },
        barColor: {
            type: String,
            default: "#13136505",
        },
        size: {
            type: String,
            default: "200",
        },
    },
};
</script>
<style lang="scss">
.progress-circle__progress__svg {
    transform: rotateZ(-90deg);
}
.progress-circle__progress__svg__circle {
    stroke-width: 0.4em;
    transition: stroke-dashoffset 0.5s ease;
}
.progress-circle__content {
    position: absolute;
    width: 70%;
    text-align: center;
    text-transform: uppercase;
    .progress-circle__content__value {
        font-size: 2.5em;
        font-weight: 700;
    }

    max-width: 190px;
}
</style>
