import humanizer from 'humanize-duration'
export function minToHoursString(sec) {
    let ms = sec * 1000
    return humanizer(ms, {
        language: 'shortEn',
        languages: {
            shortEn: {
                h: () => '<small>h</small>',
                m: () => '<small>m</small>',
            },
        },
        units: ['h', 'm'],
        round: true,
        conjunction: ' ',
    })
}
