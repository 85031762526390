class Stack {
    constructor(data = [], needPersist = false) {
        this.items = data
        this.needPersist = needPersist
    }

    get isEmpty() {
        return !this.items.length
    }

    get peek() {
        return this.items[this.items.length - 1]
    }

    get size() {
        return this.items.length
    }

    _persist() {
        if (this.needPersist) {
            localStorage.setItem(this.needPersist, JSON.stringify(this.items))
        }
    }

    clear() {
        this.items = []
        this._persist()
    }

    push(element) {
        this.items.push(element)
        this._persist()
    }

    pop() {
        if (this.items.length == 0) return null
        let item = this.items.pop()
        this._persist()
        return item
    }
}

export default Stack
