<script>
    import { mapState } from 'pinia'
    import { useGlobalDialog } from '@store/globalDialog.js'

    export default {
        name: 'GlobalDialog',
        computed: {
            ...mapState(useGlobalDialog, ['globalDialog']),
        },
        methods: {
            hideDialog() {
                this.globalDialog.visible = false
            },
            closeDialog() {
                this.hideDialog()
                if (this.globalDialog.negativeButton.action)
                    this.globalDialog.negativeButton.action()
            },
            confirmDialog() {
                this.globalDialog.visible = false
                if (this.globalDialog.positiveButton.action)
                    this.globalDialog.positiveButton.action()
            },
        },
    }
</script>

<template>
    <v-dialog
        v-model="globalDialog.visible"
        :persistent="globalDialog.mandatory"
        max-width="550"
        style="min-width: 400px"
    >
        <web>
            <ra-base-dialog
                :hide-close="!globalDialog.cancelable"
                @close="closeDialog"
            >
                <template #title>
                    {{ globalDialog.title }}
                </template>
                <p
                    class="ma-0 mb-4"
                    :class="globalDialog?.class || ['text-center']"
                    v-html="globalDialog.message"
                ></p>
                <template #footer>
                    <v-btn
                        v-if="globalDialog.negativeButton"
                        class="light"
                        color="primary"
                        @click="closeDialog"
                    >
                        {{
                            globalDialog.negativeButton.title ??
                            $t('generics.cancel')
                        }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="globalDialog.positiveButton"
                        class="button bg-primary-gradient"
                        @click="confirmDialog"
                    >
                        {{
                            globalDialog.positiveButton.title ??
                            $t('generics.yes')
                        }}
                    </v-btn>
                </template>
            </ra-base-dialog>
        </web>
        <tablet>
            <ra-card class="text-center">
                <ra-stack>
                    <h3 class="text-uppercase">
                        {{ globalDialog.title }}
                    </h3>
                    <p v-html="globalDialog.message"></p>
                    <ra-box padding="5,5">
                        <ra-stack
                            class="container-test"
                            direction="horizontal"
                            distribution="fill-equaly"
                            gap="5"
                        >
                            <ra-button
                                bg="primary-lighten-5"
                                @click="closeDialog"
                            >
                                {{
                                    globalDialog?.negativeButton?.title ??
                                    $t('generics.cancel')
                                }}
                            </ra-button>
                            <ra-button
                                bg="primary"
                                @click="confirmDialog"
                            >
                                {{
                                    globalDialog.positiveButton?.title ??
                                    $t('generics.yes')
                                }}
                            </ra-button>
                        </ra-stack>
                    </ra-box>
                </ra-stack>
            </ra-card>
        </tablet>
    </v-dialog>
</template>
