import { defineStore } from 'pinia'
import { WorkshopsProvider } from '@providers/WorkshopsProvider.js'
import { WorkshopStructure } from '@libs/Structures/WorkshopStructure.js'
import { StatistiqueStructure } from '@libs/Structures/StatistiqueStructure.js'

export const useWorkshopStore = defineStore('workshop', {
    state: () => ({
        workshopItem: WorkshopStructure.getDefault(),
        workshopSurvey: null,
        workshopStats: null,
        workshopLoaded: false,
        error: {},
    }),
    getters: {
        workshopValue(state) {
            return state.workshopItem
        },
    },
    actions: {
        async setWorkshop(payload) {
            this.workshopItem.patch(payload)
        },
        async setWorkshopSurvey(payload) {
            this.workshopSurvey = payload
        },
        async saveWorkshop() {
            try {
                const response = await WorkshopsProvider.createWorkshop(
                    this.workshopItem
                )
                    .bindVue(this)
                    .call()
                this.setWorkshop(response.data.data)
            } catch (error) {
                console.error('Error while saving workshop:', error.message)
                throw error
            }
        },
        async updateWorkshop() {
            try {
                const response = await WorkshopsProvider.updateWorkshop(
                    this.workshopItem
                )
                    .bindVue(this)
                    .call()
                this.setWorkshop(response.data.data)
            } catch (error) {
                console.error('Error while saving workshop:', error.message)
                throw error
            }
        },
        async loadWorkshop(id, invited = false,ref) {
            try {
                this.workshopLoaded = false
                const response = await WorkshopsProvider.getWorkshop(
                    id,
                    invited
                )
                    .registerLoader(ref)
                    .call()

                this.workshopLoaded = true
                this.setWorkshop(response.data.data)
            } catch (error) {
                if (error.response.status == 404) {
                    this.workshopLoaded = true
                }
                console.error('Error while loading workshop:', error)
                throw error
            }
        },
        async loadStatsWorkshop() {
            if (!this.workshopLoaded) return
            this.workshopStats = new StatistiqueStructure(
                this.workshopItem.id,
                WorkshopsProvider.getWorkshopStats.bind(WorkshopsProvider)
            )
            this.workshopStats.query.dateType = 2
            this.workshopStats.query.dates_range = [
                null,
                this.workshopStats.query.dates_range[1],
            ]
            await this.workshopStats.fetch()
        },
        async resetWorkshop() {
            this.error = {}
            this.workshopItem = WorkshopStructure.getDefault()
        },
        async switchWorkshop() {
            try {
                const data = await WorkshopsProvider.switchWorkshop(
                    this.workshopItem.id
                ).call()
                this.setWorkshop(data.data.data)
            } catch (error) {
                console.error(error)
            }
        },
        async loadWorkshopSurvey(id) {
            try {
                const response =
                    await WorkshopsProvider.getWorkshopSurvey(id).call()
                this.setWorkshopSurvey(response.data)
            } catch (error) {
                if (error.response.status == 404) {
                }
                console.error('Error while loading workshop survey:', error)
                throw error
            }
        },
        async saveWorkshopSurvey(id) {
            try {
                const response = await WorkshopsProvider.submitWorkshopSurvey(
                    id,
                    this.workshopSurvey
                ).call()
            } catch (error) {
                console.error('Error while saving workshop survey:', error)
                throw error
            }
        },
        async loadWorkshopSurveyResult(id) {
            try {
                const response =
                    await WorkshopsProvider.getWorkshopSurveyResult(id).call()

                this.setWorkshop(response.data)
            } catch (error) {
                if (error.response.status == 404) {
                }
                console.error(
                    'Error while loading workshop survey result:',
                    error
                )
                throw error
            }
        },
    },
})
