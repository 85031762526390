<script>
    export default {
        props: {
            workshopValue: {
                type: Object,
                default: undefined,
            },
        },
    }
</script>
<template>
    <loader v-if="!workshopValue" />
    <ra-box
        v-else
        class="ra-application-header"
        shadow
        tag="header"
    >
        <ra-stack
            alignement="center"
            direction="horizontal"
            gap="6"
            style="height: 61px"
        >
            <ra-stack
                alignement="center"
                direction="horizontal"
                style="border-right: 1px solid #e0e0e0; height: 100%"
                width="auto"
                padding=",6"
            >
                <img
                    alt="logo"
                    src="@assets/images/logos/logo_full.png"
                />
            </ra-stack>
            <ra-spacer />
          <ra-box flex="1 1 auto" padding=",6,,">
            <img height="50"
                 v-if="workshopValue?.custom_logo || workshopValue?.company?.logo"
                 :src="workshopValue?.custom_logo?.url || workshopValue?.company?.logo?.url" />
          </ra-box>
        </ra-stack>
    </ra-box>
</template>
