<script>
    export default {
        name: 'LicensesTypeChip',
        props: {
            license: {
                required: true,
            },
        },
        computed: {
            types() {
                return {
                    1: this.$t(
                        'licenses.properties.type.type_value.dateToDate'
                    ),
                    3: this.$t('licenses.properties.type.type_value.demo'),
                    4: 'Crédits',
                    5: this.$t(
                        'licenses.properties.type.type_value.unlimited_use'
                    ),
                    6: this.$t(
                        'licenses.properties.type.type_value.limited_use'
                    ),
                    7: this.$t(
                        'licenses.properties.type.type_value.unlimited_learners'
                    ),
                    8: this.$t(
                        'licenses.properties.type.type_value.limited_learners'
                    ),
                }
            },
            type() {
                return this.types[this.license.type]
            },
        },
    }
</script>

<template>
    <span>{{ type }}</span>
</template>
