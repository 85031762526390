import { EventEmmiter } from '../../../utils/EventEmmiter'
import { Queue } from '../../../utils/Queue.js'
import RTCConnectionHandShake from './RTCConnectionHandShake.js'

export const RTCEvent = {
    CONNECT: 'connect',
    OFFER: 'offer',
    ANSWER: 'answer',
    DATA: 'data',
    STREAM: 'stream',
    TRACK: 'track',
    REMOVE_TRACK: 'removeTrack',
    END: 'end',
    ERROR: 'error',
    READY: 'ready',
    ICE: 'iceCandidate',
}

export const RTCErrors = {
    FAILED: 'failed',
    TIMEOUT: 'timeout',
}

window.$peers = []

export class RaPeer extends RTCPeerConnection {
    static TIMEOUT = 5000
    constructor(config, headset = null) {
        super({ ...RaPeer.getConfig(headset?._isLAN), ...config })
        this.initiator = config.initiator || false
        this.headset = headset
        $peers.push(this)

        this.emiter = new EventEmmiter()
        this.dataChannels = new Map()
        this.mediasTracks = new Map()
        this.sendingQueue = new Queue()
        this.handShake = new RTCConnectionHandShake(this)
        this.debug = config.debug || true
        this.logsHistory = []

        this.connected = false

        this.setup(config)
    }

    static getConfig(isLan = false) {
        let uri = import.meta.env.VITE_RTC_TURN_URI
        let parsedURI = new URL('http://' + uri)
        let turnServer = isLan
            ? []
            : [
                  {
                      urls: 'turn:' + parsedURI.host,
                      username: parsedURI.username,
                      credential: parsedURI.password,
                  },
              ]
        return {
            iceServers: [
                {
                    urls: [
                        'stun:stun.l.google.com:19302',
                        'stun:stun1.l.google.com:19302',
                        'stun:stun2.l.google.com:19302',
                        'stun:stun3.l.google.com:19302',
                        'stun:stun4.l.google.com:19302',
                    ],
                },
                ...turnServer,
            ],
        }
    }

    setup(config) {
        if (this.initiator) {
            this.createOffer(config?.stream)
        }

        /* EnventBinding */

        this.onicecandidate = (e) => {
            if (e.candidate) {
                this.log('Peer received iceCandidate')
                this.emit(RTCEvent.ICE, {
                    candidate: e.candidate,
                    initiator: this.initiator,
                })
            }
        }
        this.onicecandidateerror = (e) => {
            console.warn('Peer iceCandidateError => ', e.url)
        }
        this.ondatachannel = (e) => {
            this.log('Peer received data channel')
            this.dataChannels.set(e.channel.label, e.channel)
            this.handleConnectionState() //try to bind on default dataChannel
            this.bindDataChannel(e.channel)
        }

        this.ontrack = (e) => {
            this.log('Peer received track')
            this.emit(RTCEvent.TRACK, e.track)
            e.track.onended = () => {
                this.emit(RTCEvent.REMOVE_TRACK, e.track)
            }
        }

        this.onclose = (e) => {
            this.log('Peer closed by remote')
            this.destroy()
        }

        this.onconnectionstatechange = (e) => {
            if (this.connectionState === 'failed') {
                this.emit(RTCEvent.ERROR, RTCErrors.FAILED)
            }
        }
    }

    destroy(force = false) {
        if (!this.connected && !force) return
        this.log('Peer disconnected')
        this.connected = false
        this.close()
        this.emit(RTCEvent.END)
    }

    handleConnect() {
        if (this.connected) return
        this.log('Peer connected')
        this.connected = true
        this.emit(RTCEvent.CONNECT)
        //empty sending queue
        while (!this.sendingQueue.isEmpty) {
            let { data, channel } = this.sendingQueue.dequeue()
            this.send(data, channel)
        }
    }

    async createOffer(stream = null) {
        if (stream) this.addStream(stream, false) //create offer with stream
        this.createDataChannel('default')
        let offer = await super.createOffer()
        await this.setLocalDescription(offer)
        this.log('Offer created')
        this.emit(RTCEvent.OFFER, {
            sdp: this.localDescription,
            type: RTCEvent.OFFER,
        })

        setTimeout(() => {
            if (this.remoteDescription === null)
                this.emit(RTCEvent.ERROR, RTCErrors.TIMEOUT)
        }, this.constructor.TIMEOUT)
    }

    async createAnswer(offer) {
        let remoteDesc = new RTCSessionDescription(offer.sdp)
        await this.setRemoteDescription(remoteDesc)
        let answer = await super.createAnswer()
        await this.setLocalDescription(answer)
        this.log('Answer created')
        return {
            sdp: this.localDescription,
            type: RTCEvent.ANSWER,
        }
    }

    send(data, channel = 'default') {
        this.log('Sending data ' + data)
        let dataChannel = this.dataChannels.get(channel)
        if (!dataChannel) throw new Error('Data channel not found')
        if (dataChannel.isOpen) {
            dataChannel.send(data)
        } else {
            this.sendingQueue.enqueue({ data, channel })
        }
    }

    async signal(payload) {
        if (payload.type === RTCEvent.OFFER) {
            this.log('Peer received offer')
            return await this.createAnswer(payload)
        }
        if (payload.type === RTCEvent.ANSWER) {
            this.log('Peer received answer')
            this.setRemoteDescription(new RTCSessionDescription(payload.sdp))
        }
        if (payload.candidate) {
            this.log('Peer received iceCandidate from the remote')
            this.addIceCandidate(payload)
        }
    }

    /* Media Channels */
    addStream(stream, offer = true) {
        stream.getTracks().forEach((track) => {
            this.addTrack(track, stream, false)
        })
        if (offer) this.createOffer()
    }

    removeStream(stream) {
        stream.getTracks().forEach((track) => {
            this.removeTrack(track, false)
        })
        this.createOffer()
    }

    addTrack(track, stream, offer = true) {
        if (this.mediasTracks.has(track.label)) return
        const sender = super.addTrack(track, stream)
        this.mediasTracks.set(track.label, sender)
        if (offer) this.createOffer()
    }

    removeTrack(track, offer = true) {
        const sender = this.mediasTracks.get(track.label)
        if (!sender) return
        super.removeTrack(sender)
        this.mediasTracks.delete(track.label)
        if (offer) this.createOffer()
    }

    /* Data Channels */
    createDataChannel(name = 'default') {
        if (this.dataChannels.has(name)) return
        const dataChannel = super.createDataChannel(name)
        this.dataChannels.set(name, dataChannel)
        this.bindDataChannel(dataChannel)
        if (name == 'default') this.handleConnectionState()
        this.log(`${name} data channel created`)
        return dataChannel
    }

    handleConnectionState() {
        if (this.dataChannels.has('default')) {
            const channel = this.dataChannels.get('default')
            channel.onopen = () => {
                this.log(`Default data channel opened`)
                this.startHandShake()
            }
            channel.onclose = () => {
                this.log(`Default data channel closed`)
                this.destroy()
            }
            this.bindDataChannel(channel)
        }
    }

    bindDataChannel(channel) {
        channel.isOpen = false
        let oldOnOpen = channel.onopen || (() => {})
        channel.onopen = () => {
            channel.isOpen = true
            oldOnOpen()
        }

        channel.onmessage = (e) => {
            this.log('Data received')
            this.emit(RTCEvent.DATA, e.data)
        }
    }

    startHandShake() {
        this.log('Starting handshake')
        const handshakePromise = this.initiator
            ? this.handShake.send()
            : this.handShake.reply()
        if (!this.initiator) this.handleConnect()
        handshakePromise
            .then((success) => {
                if (success) {
                    if (this.initiator) this.handShake.ack()
                    this.handleConnect()
                } else {
                    const errorMessage = this.initiator
                        ? 'Handshake failed, no response from receiving peer, closing connection'
                        : 'Handshake failed, no ACK message from emitting peer, closing connection'

                    this.log(errorMessage)
                    this.emit(RTCEvent.ERROR, RTCErrors.FAILED)
                }
            })
            .catch((error) => {
                this.log('Handshake process encountered an error: ' + error)
                this.emit(RTCEvent.ERROR, RTCErrors.FAILED)
            })
    }

    waitForResponse(event, callable, timeout = 1000) {
        return new Promise((resolve, reject) => {
            let listener
            let timeoutId = setTimeout(() => {
                this.off(event, listener)
                reject(new Error('Timeout'))
            }, timeout)

            listener = this.once(event, (...args) => {
                clearTimeout(timeoutId)
                resolve(callable(...args))
            })
        })
    }

    /* Events */
    on(...params) {
        this.emiter.on(...params)
    }

    off(...params) {
        this.emiter.off(...params)
    }

    emit(...params) {
        this.emiter.emit(...params)
    }

    once(...params) {
        this.emiter.once(...params)
    }

    log() {
        if (this.debug) {
            console.log(
                '[RTC PEER ' + (this.initiator ? 'E' : 'R') + ']',
                ...arguments
            )
        }
        this.logsHistory.push(...arguments)
    }
}
