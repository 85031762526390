import { Collection } from '@libs/Collections/Collection.js'
import { PaginationUtils } from '@utils/PaginationUtils.js'

/**
 * A collection with paginated data
 * @extends Collection
 */
export class PaginatedCollection extends Collection {
    static sortingNameMap = {}
    constructor(...args) {
        super(...args)
        this.pagination = null
    }

    /**
     * Get the number of items per page
     * @returns {*}
     */
    get perPage() {
        return this.pagination?.per_page || 0
    }

    /**
     * Set the number of items per page (use by vuetify v-data-table-server)
     * @param {Number} value
     */
    set perPage(value) {
        if (!Number.isInteger(value))
            throw new Error('PaginatedCollection.perPage :  must be an integer')
        this.pagination.per_page = value
    }

    /**
     * Get the total number of items
     * @returns {*}
     */
    get total() {
        return this.pagination?.total || 0
    }

    /**
     * Get the current page
     * @returns {Number}
     */
    get current_page() {
        return this.pagination?.current_page || 0
    }

    /**
     * Make a fetch request to the api and set the collection with the response data (for paginate request & response)
     * @param query
     * @returns {Promise<void>}
     */
    async fetchPaginated(query = null) {
        if (!query) {
            query = this.defaultQuery
        }
        query = this.getParsedQuery(query)
        let { data } = await this.fetch(query)
        this.paginate(data)
    }

    get defaultQuery() {
        return {
            page: this.current_page || 1,
            itemsPerPage: this.perPage || 5,
            sortBy: 'id',
            orderWay: 'desc',
        }
    }
    getParsedQuery(query, sortingNameMap = this.constructor.sortingNameMap) {
        PaginationUtils.parseLimit(query)
        PaginationUtils.parseSortBy(query, sortingNameMap)
        return query
    }

    /**
     * Set the pagination data
     * @param paginationResponse
     */
    paginate(paginationResponse) {
        this.pagination = paginationResponse?.meta ?? paginationResponse
        this.set(paginationResponse.data)
    }

    //overwrite array methods to set the pagination
    filter(...args) {
        const collection = super.filter(...args)
        collection.pagination = this.pagination
        return collection
    }
}
