import ApiClient from '@api'

export class UploadProvider {
    static route = '/files'
    static api = ApiClient.getInstance()

    /**
     *
     * @param file
     * @param payload {FormData}
     * @param onProgress
     * @returns {Promise<void>}
     */
    static upload = async (file, payload, onProgress = () => {}) => {
        if (typeof payload === 'function') {
            onProgress = payload
            payload = file
        }
        return await this.api
            .post(
                this.route,
                payload,
                {},
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress(progressEvent) {
                        onProgress(progressEvent)
                    },
                }
            )
            .call()
    }
}
