<script>
    import GlobalLanguageSelector from '@components/global/Languages/GlobalLanguageSelector.vue'

    export default {
        name: 'LoginLayout',
        components: { GlobalLanguageSelector },
    }
</script>

<template>
    <ra-stack
        class="login-container"
        direction="horizontal"
        distribution="fill-equaly"
        fill-height
        gap="0"
    >
        <ra-box>
            <slot></slot>
        </ra-box>
        <ra-box>
            <img
                alt=""
                src="@assets/images/aside_login.png"
            />
        </ra-box>
    </ra-stack>
</template>

<style scoped lang="scss"></style>
