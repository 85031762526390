<template>
    <div
        :class="classNames"
        @touchstart.prevent.stop="() => {}"
        @touchend.prevent.stop="() => {}"
    >
        <div
            ref="scroller"
            v-dragscroll
            class="hscroll-scroller"
            @mouseenter="updateClassNames"
            @scroll="updateClassNames"
        >
            <slot></slot>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'HorizontalScrollContainer',
        data() {
            return {
                classNames: ['hscroll-container'],
                isHover: false,
            }
        },

        mounted() {
            setTimeout(() => {
                this.updateClassNames()
            }, 500)
            this.listener = window.addEventListener(
                'resize',
                this.updateClassNames
            )
        },
        destroyed() {
            window.removeEventListener('resize', this.listener)
        },

        methods: {
            updateClassNames(e) {
                if (!this.$refs.scroller) return
                let hasRight = Math.round(
                    this.$refs.scroller.scrollWidth -
                        this.$refs.scroller.getBoundingClientRect().width -
                        1 >
                        this.$refs.scroller.scrollLeft
                )

                let hasLeft =
                    this.$refs.scroller.scrollWidth >
                        this.$refs.scroller.getBoundingClientRect().width &&
                    this.$refs.scroller.scrollLeft > 0

                this.classNames = {
                    'hscroll-container': true,
                    'hscroll-container-content-left': hasLeft,
                    'hscroll-container-content-right': hasRight,
                }
            },
        },
        watch: {
            '$refs.scroller': () => {
                this.updateClassNames()
            },
        },
    }
</script>
<style lang="scss" scoped>
    $shadow-size: 1.5rem;

    @mixin shadow($direction, $opacity) {
        $shadow-box: linear-gradient(
            $direction,
            rgba(0, 0, 0, $opacity) 0%,
            rgba(0, 0, 0, 0) 100%
        );
        background: $shadow-box;
    }

    .hscroll-container {
        flex: 1 1 100%;
        position: relative;
        transition: 0.2s;

        .hscroll-scroller {
            display: flex;
            flex-wrap: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;

            box-sizing: border-box;
            &::-webkit-scrollbar {
                display: none;
            }
        }
        &::before {
            @include shadow(90deg, 0.5);
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: $shadow-size;
            height: 100%;
            opacity: 0;
            transition: 0.2s;
            z-index: 10;
        }
        &::after {
            @include shadow(-90deg, 0.5);
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: $shadow-size;
            height: 100%;
            opacity: 0;
            transition: 0.2s;
        }

        &-content-left::before {
            opacity: 0.2;
        }
        &-content-right::after {
            opacity: 0.2;
        }
    }
</style>
