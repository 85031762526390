<script>
    import QrMethod from '@components/global/Dialogs/ShareMethods/QR.vue'
    import LinkMethod from '@components/global/Dialogs/ShareMethods/Link.vue'
    import EmailMethod from '@components/global/Dialogs/ShareMethods/Email.vue'
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import { WorkshopRoutesNames } from '@core/router/routes/generators/Workshop.names.js'

    export default {
        components: {
            QrMethod,
            LinkMethod,
            EmailMethod,
        },
        mixins: [mixins.RaDialogMixin],
        props: ['value', 'workshop'],
        data() {
            let now = new Date()
            let expire = new Date()
            expire.setMonth(expire.getMonth() + 1)
            return {
                workshopRoutes: WorkshopRoutesNames,
                menu1: null,
                selectedMethod: 0,
                expire_on: expire,
                loading: false,
            }
        },
        computed: {
            shareLink() {
                return (
                    location.origin +
                    this.$router.resolve({
                        name: WorkshopRoutesNames.inscription,
                        params: {
                            id: this.workshop.id,
                        },
                    }).fullPath
                )
            },
            shareMethods() {
                return [
                    {
                        name: this.$t('share.methods.link'),
                        icon: 'mdi-link',
                        component: 'link-method',
                    },
                    {
                        name: this.$t('share.methods.qr-code'),
                        icon: 'mdi-qrcode',
                        component: 'qr-method',
                    },
                    {
                        name: this.$t('share.methods.invitation'),
                        icon: 'mdi-email',
                        component: 'email-method',
                    },
                ]
            },
        },
    }
</script>
<template>
    <ra-base-dialog
        :header="{
            bg: 'primary-gradient',
        }"
    >
        <template #title> {{ $t('workshops.inscriptions_share') }} </template>
        <ra-stack padding="4,">
            <v-row>
                <v-col
                    v-for="(m, i) in shareMethods"
                    :key="i"
                    :class="i == selectedMethod && 'selected'"
                    class="share-method mb-6"
                    @click="selectedMethod = i"
                >
                    <div class="mb-2 pa-5">
                        <v-icon>{{ m.icon }}</v-icon>
                    </div>
                    <small>{{ m.name }}</small>
                </v-col>
            </v-row>
            <component
                :is="shareMethods[selectedMethod].component"
                :custom_name="
                    'qr_code_' +
                    workshop.name.replace(/[ -]/gi, '_').toLowerCase()
                "
                :link="shareLink"
                type="workshop"
                :id="workshop.id"
            />
        </ra-stack>
    </ra-base-dialog>
</template>
<style scoped>
    .share-method {
        cursor: pointer;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        flex-direction: column;
    }

    .share-method > div {
        background: rgba(9, 9, 84, 0.1);
        border-radius: 50%;
    }

    .share-method i {
        transform: scale(1.5);
        color: #090954 !important;
    }

    .selected > div {
        background-color: #090954;
    }

    .selected i {
        color: white !important;
    }
</style>
