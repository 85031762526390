import { Table } from './Table'

export class HeadsetLocalRenaming extends Table {
    static storeName = 'headset_local_renamings'
    static storeConfig = '++id, serialNumber, name, created_at'

    constructor(id, data) {
        super(id, data)
    }

    _patch(data) {
        this.serialNumber = data.serialNumber
        this.name = data.name
        this.created_at = data.created_at
    }
}
