import ApiClient from '@api'

export class LicensesProvider {
    static api = ApiClient.getInstance()

    static route(companyID) {
        return `/companies/${companyID}/licenses`
    }
    static getLicenses(companyID, query = {}) {
        return this.api.get(this.route(companyID), query)
    }
    static getLicense(companyID, id, query = {}) {
        return this.api.get(`${this.route(companyID)}/${id}`, query)
    }

    /**
     * @param companyID
     * @param {LicenseCollection} licenses
     * @param query
     */
    static attributeLicenses(companyID, licenses, query = {}) {
        return this.api.post(
            `${this.route(companyID)}/multiple`,
            licenses,
            query
        )
    }
    static attributeMultipleLicenses(companyID, ids, query = {}) {
        return this.api.post(`${this.route(companyID)}/multiple`, ids, query)
    }
    static deleteLicense(companyID, id, query = {}) {
        return this.api.delete(`${this.route(companyID)}/${id}`, query)
    }
    static massDeleteLicenses(companyID, ids, query = {}) {
        return this.api.post(`${this.route(companyID)}/delete`, ids, query)
    }
}
