<script>
    export default {
        name: 'FormationAttributeChip',
        props: {
            icon: {
                type: String,
                default: undefined,
            },
        },
    }
</script>

<template>
    <ra-stack
        v-bind="$attrs"
        class="formation-attribute-chip"
        direction="horizontal"
        gap="2"
        padding="2"
        rounded
        width="auto"
    >
        <ra-icon> {{ icon }} </ra-icon>
        <slot></slot>
    </ra-stack>
</template>

<style lang="scss">
    .formation-attribute-chip {
        font-size: 0.8rem !important;
    }
</style>
