export default function (to, from, next, auth) {
    const nextQuery = to.query.next
    const logout = to.query.logout
    if (logout) {
        auth.logout()
        return next()
    }
    if (auth.isAuth) return next(nextQuery || '/')
    else return next()
}
