export class PaginationUtils {
    static parseSortBy(query, sortingNameMap) {
        if (query.sortBy instanceof Array && query.sortBy.length > 0) {
            let sortName =
                query.sortBy[0].key in sortingNameMap
                    ? sortingNameMap[query.sortBy[0].key]
                    : query.sortBy[0].key
            query.orderBy = sortName
            query.orderWay = query.sortBy[0].order
            query.orderBy = query.orderBy || 'id'
            delete query?.sortBy
            query.orderWay = query.orderWay || 'desc'
        }
    }

    static parseLimit(query) {
        query.limit = query.itemsPerPage || query.limit || 15
        delete query?.itemsPerPage
    }
}
