import { createI18n } from 'vue-i18n'
import en from './langs/en.json'
import fr from './langs/fr.json'
import { EventEmmiter } from '@utils/EventEmmiter.js'

const i18n = createI18n({
    locale: 'fr',
    allowComposition: true,
    messages: {
        en: en,
        fr: fr,
    },
})

export default i18n

class i18nLocaleObserverClass extends EventEmmiter {
    constructor(i18n) {
        super()
        this.i18n = i18n
    }

    get locale() {
        return this.i18n.global.locale
    }

    set locale(locale) {
        this.i18n.global.locale = locale
        this.emit('change', i18n, locale)
    }
}

export const i18nLocaleObserver = new i18nLocaleObserverClass(i18n)
