<script>
export default {
  name: "StartFormationDescription",
  props: {
    formation:
        {
          type: Object,
          default: () => ({}),
        },
  },
};
</script>

<template>
  <ra-stack >
    <ra-typo>
      {{ formation.description }}
    </ra-typo>

    <ra-typo

      semi
      with-track
    >
      {{ $t('formations.show.informations.objectifs_title') }}
    </ra-typo>
    <ra-box padding="0,5">
      <ul>
        <li v-for="obj of formation.objectives">
          <ra-typo>
            {{obj}}
          </ra-typo>
        </li>
      </ul>
    </ra-box>
  </ra-stack>
</template>