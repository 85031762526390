<script>
    import ApplicationLayout from '@layouts/global/ApplicationLayout.vue'
    import Loader from '@components/tools/Loader.vue'
    import WorkshopInscription from '@components/Workshops/WorkshopInscription.vue'
    import WorkshopHeader from '@components/Workshops/WorkshopHeader.vue'
    import { AUTH_TYPE, useUserStore } from '@store/user.js'
    import { useWorkshopStore } from '@store/workshop'
    import { mapActions, mapState } from 'pinia'
    import Page404 from '@components/global/Page404.vue'

    export default {
        name: 'MyWorkshopsPublicIndexPage',
        data() {
            return {
                registered: false,
                workshopError: false,
            }
        },
        components: {
            ApplicationLayout,
            Loader,
            WorkshopInscription,
            WorkshopHeader,
            Page404,
        },
        mounted() {
            this.getWorkshop()
        },
        computed: {
            ...mapState(useWorkshopStore, ['workshopValue']),
            AUTH_TYPE() {
                return AUTH_TYPE
            },
            ...mapState(useUserStore, {
                userType: (state) => state.type,
            }),
            generateGoogleCalendarLink(date) {
                // Générer le lien vers Google Calendar avec la date spécifiée

                let dateFiltered = this.$filters.formatDate(
                    this.workshopValue.date,
                    'YYYYMMDD'
                )

                let starttimeFiltered = this.$filters.formatTime(
                    this.workshopValue.start_time,
                    'HHmmss'
                )

                let endtimeFiltered = this.$filters.formatTime(
                    this.workshopValue.end_time,
                    'HHmmss'
                )

                const eventDetails = {
                    text: this.workshopValue.name,
                    dates: `${dateFiltered}T${starttimeFiltered}/${dateFiltered}T${endtimeFiltered}`, // Format YYYY-MM-DD/YYYY-MM-DD pour une journée entière
                    details: this.workshopValue.description
                        ? `${this.workshopValue.description}`
                        : '',
                    location: this.workshopValue.address
                        ? `${this.workshopValue.address} ${this.workshopValue.city} ${this.workshopValue.state}`
                        : '',
                }
                const encodedEvent = Object.entries(eventDetails)
                    .map(
                        ([key, value]) => `${key}=${encodeURIComponent(value)}`
                    )
                    .join('&')
                //     return `${dateFiltered}T${this.workshopValue.start_time}/${dateFiltered}T${this.workshopValue.end_time}`
                return `https://www.google.com/calendar/render?action=TEMPLATE&${encodedEvent}`
            },
        },
        methods: {
            ...mapActions(useWorkshopStore, ['loadWorkshop']),
            minuteByFormation(formation) {
                return formation.modules.reduce(
                    (acc, module) => acc + module.nb_minutes,
                    0
                )
            },
            async getWorkshop() {
                try {
                    // second parameter can show the page without login
                    await this.loadWorkshop(
                        this.$route.params.workshop_id,
                        true
                    )
                } catch (error) {
                    this.workshopError = true
                }
            },
        },
    }
</script>

<template>
    <workshop-header :workshopValue="workshopValue" />
    <div v-if="workshopError"> <page404 /></div>
    <ra-stack
        v-else
        padding="10"
        scroll-y
        height="calc(100vh - 61px)"
    >
        <loader :wait-for="workshopValue">
            <ra-box>
                <ra-heading-title
                    subtitle="Session "
                    :title="workshopValue.name"
                >
                </ra-heading-title>
                <ra-stack>
                    <ra-stack
                        mobile-swap
                        direction="horizontal"
                        distribution="fill-equally"
                    >
                        <ra-card>
                            <ra-stack>
                                <ra-typo>
                                    <v-icon
                                        :color="workshopValue.status.color"
                                        class="mr-2"
                                        size="x-small"
                                    >
                                        mdi-circle
                                    </v-icon>
                                    {{ $t(workshopValue.status.text) }}
                                </ra-typo>
                                <img
                                    v-if="
                                        workshopValue.company &&
                                        workshopValue.company.logo
                                    "
                                    :src="workshopValue.company?.logo.url"
                                />
                                <ra-typo
                                    h3
                                    v-else
                                    >{{ workshopValue.company?.name }}</ra-typo
                                >
                                <div>
                                    <ra-typo
                                        bold
                                        padding="1,0"
                                        >{{
                                            $filters.formatDate(
                                                workshopValue.date,
                                                'dddd DD MMMM YYYY'
                                            )
                                        }}</ra-typo
                                    >
                                    <ra-typo padding="1,0">
                                        {{
                                            $filters.formatTime(
                                                workshopValue.start_time
                                            )
                                        }}
                                        -
                                        {{
                                            $filters.formatTime(
                                                workshopValue.end_time
                                            )
                                        }}
                                        •
                                        {{
                                            workshopValue.allow_inscription
                                                ? 'workshops.with_inscriptions'
                                                : 'workshops.without_inscriptions'
                                        }}
                                    </ra-typo>
                                    <ra-typo>
                                        {{ workshopValue.address }}
                                    </ra-typo>
                                </div>
                                <ra-card
                                    variant="outlined"
                                    v-for="formation in workshopValue.formations"
                                    :key="formation.id"
                                >
                                    <ra-stack
                                        distribution="side-spacing"
                                        direction="horizontal"
                                        mobile-swap
                                    >
                                        <ra-box height="100px">
                                            <v-img
                                                width="100"
                                                :aspect-ratio="1"
                                                cover
                                                :src="formation?.image?.url"
                                                alt="formation-image"
                                                class="formation-card-image"
                                            />
                                        </ra-box>
                                        <ra-stack flex="1 1 70%">
                                            <ra-typo h3>{{
                                                formation.name
                                            }}</ra-typo>
                                            <ra-typo span
                                                >{{ formation.modules.length }}
                                                modules .
                                                {{
                                                    minuteByFormation(formation)
                                                }}
                                                minutes</ra-typo
                                            >
                                            <ra-typo
                                                p
                                                size="x-small"
                                                class="formation-description"
                                            >
                                                {{ formation.description }}
                                            </ra-typo>
                                        </ra-stack>
                                    </ra-stack>
                                </ra-card>
                            </ra-stack>
                        </ra-card>
                        <ra-stack flex="1 1 40%">
                            <workshop-inscription
                                v-if="
                                    workshopValue.allow_inscription &&
                                    workshopValue.status.can_register &&
                                    !registered
                                "
                                :workshop="workshopValue"
                                :slots="workshopValue.slots"
                                :isApprenant="true"
                                @registered-learner="registered = true"
                            />
                            <ra-card v-else>
                                <ra-box
                                    center
                                    padding="4,"
                                >
                                    <ra-icon
                                        class="text-green"
                                        style="
                                            font-size: 100px;
                                            line-height: normal !important;
                                        "
                                        weight="700"
                                        padding="4,"
                                    >
                                        fa-circle-check
                                    </ra-icon>
                                    <ra-typo
                                        h2
                                        padding="4,"
                                    >
                                        {{ $t('workshops.slots.confirm') }}
                                    </ra-typo>
                                    <ra-typo variant="title">{{
                                        $filters.formatDate(
                                            workshopValue.date,
                                            'dddd DD MMMM YYYY'
                                        )
                                    }}</ra-typo>
                                    <ra-typo
                                        variant="title"
                                        padding="0,0,4,0"
                                    >
                                        {{
                                            $filters.formatTime(
                                                workshopValue.start_time
                                            )
                                        }}
                                        -
                                        {{
                                            $filters.formatTime(
                                                workshopValue.end_time
                                            )
                                        }}
                                    </ra-typo>
                                    <v-btn
                                        :href="generateGoogleCalendarLink"
                                        target="_blank"
                                        ><ra-stack direction="horizontal"
                                            ><ra-icon weight="700"
                                                >fa-calendar</ra-icon
                                            >{{
                                                $t(
                                                    'workshops.slots.add_to_calendar'
                                                )
                                            }}
                                        </ra-stack>
                                    </v-btn>
                                </ra-box>
                            </ra-card>
                        </ra-stack>
                    </ra-stack>
                </ra-stack>
            </ra-box>
        </loader>
    </ra-stack>
</template>

<style lang="scss" scoped>
    .giant-icon {
        font-size: 5rem;

        background: var(--primary-gradient);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .formation-description {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        -webkit-box-orient: vertical;
    }

    .former-card {
        display: flex;
        flex-direction: column;
        .v-card-text {
            padding: 0;
        }
    }
    .document {
        padding: 5px 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 250px;
    }
    .cardinscription {
        min-height: 120px;
    }
</style>
