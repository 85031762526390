<script>
    import { mapState } from 'pinia'
    import { useLanguageStore } from '@store/lang.js'

    export default {
        name: 'DatePicker',
        emits: ['update:dates', 'validated'],
        props: {
            dates: {
                type: Array,
                default: null,
            },
        },
        computed: {
            ...mapState(useLanguageStore, ['currentLocale']),
            value: {
                get() {
                    return this.dates
                },
                set(value) {
                    this.$emit('update:dates', value)
                },
            },
        },
    }
</script>

<template>
    <v-menu>
        <template v-slot:activator="{ props }">
            <div v-bind="props">
                <slot
                    name="activator"
                    v-bind="props"
                />
            </div>
        </template>
        <ra-stack
            bg="white"
            class="menu-container"
            padding="4"
            rounded
            shadow
        >
            <vue-date-picker
                v-model="value"
                :locale="currentLocale"
                auto-apply
                calendar-cell-class-name="dp-custom-cell"
                inline
                menu-class-name="dp-custom-menu"
                v-bind="$attrs"
            />
            <v-btn
                color="primary"
                @click="$emit('validated')"
                >Valider</v-btn
            >
        </ra-stack>
    </v-menu>
</template>

<style lang="scss">
    .menu-container {
        align-self: center;
    }
    .dp-custom-menu {
        border: none !important;
    }
    .dp-custom-cell {
        border-radius: 50%;
        &.dp__today {
            border-color: var(--secondary) !important;
            color: var(--secondary) !important;
        }
        &.dp__range_start,
        &.dp__range_end {
            background: var(--primary) !important;
            &.dp__today {
                border-color: var(--primary) !important;
                color: white !important;
            }
        }
    }
</style>
