import MyFormations from './MyFormations.routes.js'
import MyAccount from './MyAccount.routes.js'
import Resources from './Resources.routes.js'
import { authentificatedRoutesMeta as meta } from '@core/router/routes/Authentificated/meta.js'

export const AuthentificatedRoutesName = {
    home: 'home',
}
export default [
    ...MyFormations,
    ...MyAccount,
    ...Resources,
    {
        path: '/contact',
        name: 'contact',
        meta,
        component: () => import('@pages/Contact.vue'),
    },
]
