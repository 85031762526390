<script>
    import PackChip from '@components/Packs/PackChip.vue'

    export default {
        name: 'FormationEllipsizer',
        components: { PackChip },
        props: {
            items: {
                type: Array,
                default: () => [],
            },
            count: {
                type: Number,
                default: 3,
            },
        },

        computed: {
            hasOneFormation() {
                return this.items.length === 1
            },
            countText() {
                return this.items.length - this.count > 0
                    ? ' +' + (this.items.length - this.count)
                    : ''
            },
            ellipsizedText() {
                return this.ellipsis()
            },
        },

        methods: {
            ellipsis: function () {
                var str = ''
                for (var i = 0; i < this.items.length; i++) {
                    if (i >= this.count) {
                        str = str.substring(0, str.length - 2) + ', '
                        break
                    }
                    str += this.items[i]['name'] + ', '
                }
                str = str.substring(0, str.length - 2)
                return str
            },
        },
    }
</script>

<template>
    <div>
        <v-menu
            v-if="!hasOneFormation"
            offset-y
            open-on-hover
            rounded="lg"
        >
            <template #activator="{ props }">
                <div v-bind="Boolean(countText) ? props : {}">
                    {{ ellipsizedText }}
                    <span class="bold">
                        {{ countText }}
                    </span>
                </div>
            </template>

            <ra-box
                rounded
                shadow
                padding="2"
                bg="white"
                class="text-ellipsis"
            >
                <div
                    class="mb-1"
                    style="font-size: 0.6em"
                >
                    <ul>
                        <li
                            v-for="(f, i) in items"
                            :key="'formation-' + i"
                        >
                            {{ f.name }}
                        </li>
                    </ul>
                </div>
            </ra-box>
        </v-menu>
        <ra-stack v-else>
            <pack-chip
                v-if="items[0]?.pack"
                :pack="items[0].pack"
            />
            {{ items[0].name }}
        </ra-stack>
    </div>
</template>

<style lang="scss" scoped>
    .text-ellipsis {
        div {
            ul {
                padding-left: 0;
                list-style-type: none;
                max-height: 150px;
                overflow: auto;
            }
        }
    }
</style>
