<script>
    import { TranslationAlias } from '@core/i18n/TranslationAlias.js'
    import { formerFormInputs } from '@components/Formers/FormerForm.inputs.js'
    import LicenseFormRow from '@components/Licenses/LicenseFormRow.vue'
    import { LicensesProvider } from '@providers/LicensesProvider.js'
    import FormationCollection from '@libs/Collections/Formation.js'
    import Loader from '@components/tools/Loader.vue'
    import { mapActions } from 'pinia'
    import { useFormationStore } from '@store/formationStore.js'

    export default {
        name: 'WorkshopFormationAddForm',
        components: { Loader, LicenseFormRow },
        data() {
            return {
                translationAlias: new TranslationAlias('workshops', this),
                selectedLicenses: this.modelValue,
            }
        },
        props: {
            formations: {
                type: Array,
                default: [],
            },
            modelValue: String,
        },
        computed: {
            transformedFormations() {
                return this.selectedLicenses.map((formation) => ({
                    id: formation,
                    modules: [],
                }))
            },
        },
        methods: {
            submit() {
                this.$emit('update:modelValue', this.transformedFormations)
            },
        },
    }
</script>

<template>
    <ra-base-dialog>
        <template #title>{{ $_t('actions.addFormation') }}</template>
        <ra-stack>
            <loader :wait-for="formations">
                <ra-tree-formations-select
                    v-model="selectedLicenses"
                    :allText="$t('licenses.properties.formations.all')"
                    :items="formations"
                    :label="$t('licenses.properties.formations.select_label')"
                    density="compact"
                    multiple
                />
            </loader>
            <v-btn
                class="primary-gradient"
                variant="flat"
                @click="submit"
                >{{ $tc('formations.actions.add', 1) }}</v-btn
            >
        </ra-stack>
    </ra-base-dialog>
</template>
