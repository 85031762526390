import Dexie from 'dexie'
import { SyncedHeadsets } from './tables/SyncedHeadsets'
import { HeadsetLocalRenaming } from '@core/database/tables/HeadsetLocalRenaming.js'

const TABLES = {
    SyncedHeadsets,
    HeadsetLocalRenaming,
}

export class Database {
    static instance = null

    static getInstance() {
        if (!Database.instance) Database.instance = new Database()
        return Database.instance
    }

    constructor() {
        this.db = new Dexie('reality-pilotage-app')
        this._tables = new Tables(this.db)
        this.tables = (key = null) => {
            if (!key) return this._tables
            return this._tables.find(key)
        }

        this.stores = TABLES
        this.init()
    }

    get storesConfig() {
        let config = {}
        for (let store in this.stores) {
            let target = this.stores[store]
            config[target.storeName] = target.storeConfig
        }
        return config
    }

    init() {
        this.db.version(2).stores(this.storesConfig)
        for (let store in this.stores) {
            let table = this.stores[store]
            table.init(this.db)
            this.tables().set(table.storeName + '.' + store, table)
        }
    }

    needMigration() {
        return this.db.isOpen() && this.db.verno < this.db.version
    }
}

class Tables extends Map {
    constructor(db) {
        super()
        this.db = db
    }

    find(key) {
        let keys = [...this.keys()]
        let exist = keys.find((k) => k.includes(key))

        if (!exist) throw new Error(`Table ${key} not found`)
        return super.get(exist)
    }
}
