export class Queue extends Array {
    constructor() {
        super()
    }

    get isEmpty() {
        return this.length === 0
    }

    enqueue(item) {
        this.push(item)
    }

    dequeue() {
        return this.shift()
    }
}
