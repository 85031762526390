<script>
    import { Skeletor } from 'vue-skeletor'

    export default {
        name: 'GlobalStatsGrid',
        components: { Skeletor },
        props: {
            stats: Array,
            gridCol: Number,
        },
        computed: {
            colCounts() {
                if (this.gridCol) return this.gridCol
                if (this.stats?.length <= 4) return 2
                else return 3
            },
        },
    }
</script>

<template>
    <ra-box
        :style="{
            '--col-count': colCounts,
        }"
        class="grid-stats"
    >
        <ra-stack
            v-for="stat of stats"
            alignement="center"
            class="grid-item"
            direction="horizontal"
            gap="4"
            padding="6,3"
        >
            <template v-if="stat">
                <ra-icon
                    :icon="stat.icon"
                    class="grid-stat-icon"
                />
                <ra-stack
                    gap="1"
                    width="unset"
                >
                    <ra-typo
                        bold
                        class="grid-stat-value"
                    >
                        <span
                            v-html="stat.value"
                            data-test="stat-value"
                        ></span>
                    </ra-typo>
                    <ra-typo uppercase>
                        <span v-html="stat.label"></span>
                    </ra-typo>
                </ra-stack>
            </template>
            <template v-else>
                <Skeletor
                    as="div"
                    class="loader-skeletor"
                    height="60"
                    width="100%"
                />
            </template>
        </ra-stack>
    </ra-box>
</template>

<style lang="scss" scoped>
    .grid-stats {
        height: 100%;
        display: grid;
        grid-template-columns: repeat(var(--col-count), 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 1px;
        background: #0909542e;
        & > * {
            background: white;
            justify-content: center !important;
        }
        .grid-stat-icon {
            color: var(--secondary);
            font-size: 2em;
        }
        .grid-stat-value {
            font-size: 1.7em;
        }

        @media (max-width: 768px) {
            grid-template-columns: repeat(1, 1fr);
            & > * {
                justify-content: flex-start !important;
            }
        }
    }
</style>
