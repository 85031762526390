import Authentificated from '@core/router/routes/Authentificated/index.js'
import Former from '@core/router/routes/Former/index.js'
import God from '@core/router/routes/God/index.js'
import Login from '@pages/Login.vue'
import LoginWithToken from '@pages/Auth/LoginWithToken.vue'
import ForgotPassword from '@pages/Auth/ForgotPassword.vue'
import LoginAccessLink from '@pages/Auth/LoginAccessLink.vue'
import workshopsRoutes from '@core/router/routes/generators/Workshop.js'
import ValidateAccount from '@pages/Auth/ValidateAccount.vue'
import ChangePassword from '@pages/Auth/ChangePassword.vue'

export const routesNames = {
    login: 'login',
}
export default [
    ...Authentificated,
    ...Former,
    ...God,
    ...workshopsRoutes,
    {
        path: '/',
        name: 'index',
        meta: {
            script: 'index',
            allowTabletApp: true,
        },
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            allowTabletApp: true,
            script: 'login',
        },
        component: Login,
    },
    {
        path: '/login/:access_link_identifier',
        name: 'login_access_link',
        meta: {
            allowTabletApp: false,
        },
        component: LoginAccessLink,
    },
    {
        path: '/share/:access_link_identifier',
        name: 'login_access_link_old',
        redirect: (to) => {
            return { name: 'login_access_link', params: to.params }
        },
    },
    {
        path: '/login-with-token/:token/:old_id?',
        name: 'login_token',
        component: LoginWithToken,
    },
    {
        path: '/forgot-password',
        name: 'forgot_password',
        meta: {
            allowTabletApp: true,
            script: 'login',
        },
        component: ForgotPassword,
    },
    {
        path: '/validate-account',
        name: 'validate-account',
        component: ValidateAccount,
    },
    {
        path: '/change-password',
        name: 'change-password',
        component: ChangePassword,
    },
    {
        path: '/not-available',
        name: 'not_available',
        component: () => import('@pages/Errors/NotAvailable.vue'),
    },
]
