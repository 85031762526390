<script>
    import { Chart, registerables } from 'chart.js'
    export default {
        props: {
            data: {
                type: Object,
                required: true,
            },
        },
        mounted() {
            let ctx = this.ctx
            Chart.register(...registerables)
            this.chart = new Chart(ctx, this.chartConfig)
        },
        computed: {
            ctx() {
                return this.$refs.chart.getContext('2d')
            },
            chartConfig() {
                return {
                    type: 'bar',
                    data: {
                        labels: this.data.choices.map((choice) => choice.name),
                        datasets: [
                            {
                                label: this.data.name,
                                data: this.data.choices.map(
                                    (choice) => choice.answer_count
                                ),
                            },
                        ],
                    },
                    options: {
                        indexAxis: 'y',
                        responsive: true,
                        plugins: {
                            legend: {
                                display: false,
                            },
                        },
                    },
                }
            },
        },
    }
</script>
<template>
    <div style="height: 400px">
        <canvas
            id="chart"
            ref="chart"
        />
    </div>
</template>
