<script>
    import { UserProvider } from '@providers'
    import { mapActions } from 'pinia'
    import { useUserStore } from '@store/user.js'
    import { MonitoringLogError } from '@libs/Monitoring/MonitoringLogger.js'
    import { useAppStore } from '@store/app.js'

    export default {
        name: 'LoginAccessLink',
        mounted() {
            this.init()
        },
        methods: {
            ...mapActions(useUserStore, ['loginWithToken', 'logout']),
            async init() {
                try {
                    await this.logout()
                    const accessToken = await this.retrieveAccessLink(
                        this.$route.params.access_link_identifier
                    )
                    await this.loginWithToken(accessToken)
                    this.go()
                } catch (err) {
                    const appStore = useAppStore()
                    const monitoringError = new MonitoringLogError(
                        '[LOGIN ACCESS TOKEN] an error occurred'
                    ).setContext({
                        accessLinkUUID:
                            this.$route.params.access_link_identifier,
                        error: err,
                    })
                    setTimeout(() => {
                        appStore.goToLoginPage({
                            next_url: this.$route.query.next_url,
                            error: err?.response?.data?.message,
                        })
                    }, 500)
                }
            },
            go() {
                if (this.$route.query.next_url) {
                    this.$router.push(this.$route.query.next_url)
                } else {
                    this.$router.push('/')
                }
            },
            async retrieveAccessLink(uuid) {
                try {
                    const { data } = await UserProvider.loginAccessLink(uuid)
                        .doNotShowSnackBar()
                        .call()
                    return data.access_token
                } catch (error) {
                    throw error
                }
            },
        },
    }
</script>

<template>
    <ra-box
        bg="primary-gradient"
        height="10px"
    ></ra-box>
    <ra-box
        centered-content
        class="login-loader"
        height="100vh"
    >
        <ra-stack class="align-center">
            <img
                alt="logo"
                src="@assets/images/logos/logo_full.png"
            />
            <ra-spacer height="10px" />
            <ra-typo center>{{
                $t('global.auth.please_wait_during_authentication')
            }}</ra-typo>
            <v-progress-linear
                color="primary"
                indeterminate
            ></v-progress-linear>
        </ra-stack>
    </ra-box>
</template>

<style lang="scss" scoped>
    .login-loader {
        .ra-stack {
            width: 100%;
            margin: 0 auto;
            max-width: 500px;
            img {
                max-width: 350px;
                width: 90%;
            }
            @media screen and (max-width: 600px) {
                max-width: 100%;
                margin: 0 2em;
            }
        }
    }
</style>
