<script>
    import ApplicationLayout from '@layouts/global/ApplicationLayout.vue'
    import { useWorkshopStore } from '@store/workshop'
    import { mapActions, mapState } from 'pinia'
    import Loader from '@components/tools/Loader.vue'
    import pieChart from '@/components/ui/charts/pieChart.vue'
    import barChart from '@/components/ui/charts/barChart.vue'
    import { WorkshopStatsExport } from '@libs/PDFExports/WorkshopStatsExport.js'

    export default {
        data() {
            return {
                submited: false,
            }
        },
        components: {
            ApplicationLayout,
            Loader,
            pieChart,
            barChart,
        },
        mounted() {
            this.getWorkshop()
        },
        computed: {
            ...mapState(useWorkshopStore, ['workshopValue']),

            chartConfig(question) {
                return {
                    type: question.type === 'choice' ? 'bar' : 'pie',
                    data: {
                        labels: question.choices.map((choice) => choice.name),
                        datasets: [
                            {
                                label: question.name,
                                data: question.choices.map(
                                    (choice) => choice.answer_count
                                ),
                                backgroundColor: question.choices.map(
                                    (choice) => choice.color
                                ),
                            },
                        ],
                    },
                    options: {
                        responsive: true,
                        plugins: {
                            legend: {
                                position: 'right',
                            },
                        },
                    },
                }
            },
        },
        methods: {
            ...mapActions(useWorkshopStore, ['loadWorkshopSurveyResult']),
            async getWorkshop() {
                try {
                    await this.loadWorkshopSurveyResult(
                        this.$route.params.workshop_id,
                        true
                    )
                } catch (error) {
                    this.$router.push({ name: 'workshops.index' })
                }
            },
            async exportPDF() {
                this.exporting = true
                let exporter = new WorkshopStatsExport(
                    this.workshopValue,
                    this.$refs['pdf-export']
                )
                await exporter.export()
                exporter.title()
                exporter.download()
                this.exporting = false
            },
        },
    }
</script>
<template>
    <application-layout>
        <div ref="pdf-export">
            <loader :wait-for="workshopValue">
                <ra-heading-title
                    :subtitle="
                        $t(
                            'workshops.parameters.' +
                                workshopValue.parameters.ModeLancement
                        )
                    "
                    :title="workshopValue.name"
                >
                    <template #actions>
                        <ra-stack
                            direction="horizontal"
                            flex="0 1 150px"
                        >
                            <v-btn
                                @click="exportPDF"
                                color="primary-gradient"
                                prepend-icon="mdi-file-pdf-box"
                                variant="flat"
                            >
                                {{ $t('stats.export.cta') }}
                            </v-btn>
                        </ra-stack>
                    </template>
                </ra-heading-title>
                <ra-stack
                    class="custom_row"
                    direction="horizontal"
                    gap="10"
                    style="max-width: 1680px; margin: 0 auto"
                    data-pdf-page="1"
                    data-pdf-row="1"
                    v-if="workshopValue.survey_answers?.length > 0"
                >
                    <ra-card
                        padding="10"
                        v-for="question in workshopValue.questions"
                        class="custom_col"
                    >
                        <ra-typo h4>{{ question.name }}</ra-typo>
                        <pie-chart
                            v-if="question.type === 'choice'"
                            :data="question"
                        />
                        <bar-chart
                            v-if="question.type === 'formation-select'"
                            :data="question"
                        />
                    </ra-card>
                </ra-stack>
                <ra-stack v-else>
                    Pas encore de réponse pour ce formulaire
                </ra-stack>
            </loader>
        </div>
    </application-layout>
</template>
<style lang="scss">
    $col-md: 2;

    .custom_row {
        display: flex;
        flex-wrap: wrap;

        // Appliquer la mise en page pour chaque élément
        > .custom_col {
            flex: 0 0 calc(100% / #{$col-md} - 100px);

            @media (max-width: 1080px) {
                flex: 0 0 100%; // Chaque élément prendra toute la largeur sur les écrans plus petits
            }
        }
    }
</style>
