import { PaginatedCollection } from '@libs/Collections/PaginatedCollection.js'
export class CompanyCollection extends PaginatedCollection {
    constructor(companies = []) {
        super(companies)
        window.$f = this
    }

    /**
     * @returns {CompanyCollection}
     */
    get demos() {
        return this.filter((company) => company.is_demo)
    }
    /**
     * @returns {CompanyCollection}
     */
    get clients() {
        return this.filter((company) => !company.is_demo)
    }
}
