import ApiClient from '..'

export class CompaniesProvider {
    static route = '/companies'
    static api = ApiClient.getInstance()
    static alerts = {
        added(company) {
            return this.$t('companies.alerts.added', {
                name: company.name,
                type: company.is_demo ? 'prospect' : 'client',
                link: '/companies/' + company.id + '/edit',
            })
        },
        edited(company) {
            return this.$t('companies.alerts.edited', {
                name: company.name,
            })
        },
    }

    static getCompanies(query = {}) {
        return this.api.get(`${this.route}`, query)
    }
    static getCompany(id, query = {}) {
        return this.api.get(`${this.route}/${id}`, query)
    }
    static createCompany(payload, query = {}) {
        return this.api.post(`${this.route}`, payload, query)
    }
    static updateCompany(id, payload, query = {}) {
        return this.api.put(`${this.route}/${id}`, payload, query)
    }
    static deleteCompany(id, query = {}) {
        return this.api.delete(`${this.route}/${id}`, query)
    }
    static massDeleteCompanies(ids) {
        return this.api.post(`${this.route}/delete`, ids)
    }
    static getStats(id, query = {}) {
        return this.api.get(`${this.route}/${id}/sessions/stats`, query)
    }
    static exportCSVStats(id, query = {}) {
        return this.api.get(`${this.route}/${id}/sessions/stats/csv`, query)
    }
    // Get specific info about a company
    static getCompanyAvailableFormations(id) {
        return this.api.get(`${this.route}/${id}/formations`)
    }
}
