<script>
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import LangSelector from '@components/global/Languages/LangSelector.vue'
    import { UserProvider } from '@providers/UserProvider'
    import GlobalLanguageSelector from '@components/global/Languages/GlobalLanguageSelector.vue'
    import LoginLayout from '@layouts/global/LoginLayout.vue'

    export default {
        components: { LoginLayout, LangSelector, GlobalLanguageSelector },
        mixins: [mixins.RaAlertMixin],
        data() {
            return {
                email: '',
                errors: {},
                loading: false,
            }
        },
        methods: {
            async handleForgotPassword() {
                await UserProvider.forgotPassword({
                    email: this.email,
                }).call()
                this.alert(
                    `${this.$t(
                        'global.auth.forgot_password_alert'
                    )} <span class='bold'>${this.email}</span>`
                )
            },
        },
    }
</script>
<template>
    <login-layout>
        <ra-stack
            alignement="center"
            distribution="side-spacing"
            fill-height
            padding="px-0 py-14"
        >
            <div class="text-center">
                <web>
                    <img
                        alt="logo"
                        src="@assets/images/logos/logo_full.png"
                    />
                </web>
                <tablet>
                    <img
                        alt="logo"
                        src="@assets/images/logos/ra_link_logo_full.png"
                    />
                </tablet>
                <ra-typo variant="subtitle">
                    <web>
                        <span v-html="$t('global.auth.title.ra')"></span>
                    </web>
                    <tablet>
                        <span v-html="$t('global.auth.title.link')"></span>
                    </tablet>
                </ra-typo>
            </div>
            <ra-spacer />
            <ra-stack
                tag="form"
                @submit.prevent="handleForgotPassword"
            >
                <ra-input
                    :errors="errors"
                    :label="$t('users.properties.email.label')"
                    name="email"
                    data-test="email"
                >
                    <v-text-field
                        v-model="email"
                        :placeholder="$t('users.properties.email.placeholder')"
                        type="email"
                        required
                        variant="outlined"
                    />
                </ra-input>
                <ra-btn
                    :loading="loading"
                    bg="primary-gradient"
                    bold
                    type="submit"
                >
                    {{ $t('generics.validate') }}
                </ra-btn>
                <ra-alert ref="alert" />
                <ra-typo
                    tag="a"
                    variant="link"
                >
                    <router-link to="/login">
                        {{ $t('global.auth.back_login_page') }}
                    </router-link>
                </ra-typo>
                <ra-spacer />

                <global-language-selector
                    :dark="true"
                    :noEdit="true"
                    :isInline="true"
                />
            </ra-stack>
        </ra-stack>
    </login-layout>
</template>
