import { Table } from './Table'

export class SyncedHeadsets extends Table {
    static storeName = 'synced_headsets'
    static storeConfig = '++id, serialNumber, state'

    constructor(id, data) {
        super(id, data)
    }

    _patch(data) {
        this.serialNumber = data.serialNumber
        this.state = data.state
    }
}
