import { defineStore } from 'pinia'

export const useGlobalDialog = defineStore('globalDialogStore', {
    state: () => ({
        globalDialog: {
            visible: false,
            title: '',
            description: '',
            cancelable: true,
            positiveButton: { action: null },
            negativeButton: { action: null },
        },
    }),

    getters: {},
    actions: {
        setGlobalDialog(globalDialogState) {
            this.globalDialog = {
                ...this.globalDialog,
                ...globalDialogState,
                visible: true,
            }
        },
    },
})
