export function learnerAddFormInputs(
    learner,
    targetDisplayUser,
    company,
    licenses
) {
    return [
        {
            type: 'form-title',
            content: this.$t('learners.form.informations_section_title'),
        },
        {
            type: 'form-row',
            elements: [
                {
                    is: 'form-input',
                    type: 'text',
                    property: 'lastname',
                    label: this.$t('users.properties.lastname.label'),
                    required: true,
                },
                {
                    is: 'form-input',
                    type: 'text',
                    property: 'firstname',
                    label: this.$t('users.properties.firstname.label'),
                    required: true,
                },
            ],
        },
        {
            type: 'form-row',
            elements: [
                {
                    is: 'form-input',
                    type: 'text',
                    property: 'email',
                    label: this.$t('users.properties.email.label'),
                    required: true,
                },

                {
                    is: 'form-input',
                    type: 'text',
                    property: 'phone',
                    label: this.$t('users.properties.tel.label'),
                },
            ],
        },
        {
            type: 'form-row',
            visible: !targetDisplayUser,
            elements: [
                {
                    is: 'form-input',
                    type: 'user-selector',
                    label: this.$t('learners.properties.former.label') + '*',
                    property: 'user_id',
                    required: true,
                    inputProps: {
                        company_id: this.company.id,
                        label: this.$t(
                            'learners.properties.former.placeholder'
                        ),
                    },
                },
            ],
        },
        {
            type: 'form-row',
            visible: this.learner.user_id,
            elements: [
                {
                    is: 'form-input',
                    type: 'group-selector',
                    label: this.$tc('groups._name', 1),
                    property: targetDisplayUser?.admin
                        ? 'shared_group_id'
                        : 'groupe_id',

                    inputProps: {
                        user_id: this.learner.user_id,
                        company_id: this?.targetDisplayUser?.admin
                            ? company.id
                            : undefined,
                        category: 1,
                    },
                },
            ],
        },
        {
            type: 'form-row',
            elements: [
                {
                    is: 'form-input',
                    type: 'formation-selector',
                    property: 'formation_ids',
                    label: this.$t('learners.properties.assigned_trainings'),
                    inputProps: {
                        items: licenses.formations,
                        multiple: true,
                        label: this.$t(
                            'learners.properties.assigned_trainings'
                        ),
                    },
                },
            ],
        },
        {
            type: 'form-row',
            elements: [
                {
                    is: 'form-input',
                    type: 'checkbox',
                    property: 'is_vr',
                    label: this.$t('learners.properties.is_vr.label'),
                },
                {
                    is: 'form-input',
                    type: 'checkbox',
                    property: 'is_elearning',
                    label: this.$t('learners.properties.is_elearning.label'),
                },
            ],
        },
        {
            type: 'form-row',
            elements: [
                {
                    is: 'form-input',
                    type: 'checkbox',
                    property: 'notify',
                    label: this.$t('learners.form.notify'),
                },
            ],
        },
    ]
}
