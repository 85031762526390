<template>
    <ra-base-dialog>
        <template #title>
            {{ $t('groups.affect.title') }}
        </template>
        <ra-stack gap="8">
            <ra-input :label="$t('groups.affect.select') + '*'">
                <v-select
                    v-model="group_id"
                    :items="groupsWithNone"
                    hide-details
                    item-title="name"
                    item-value="id"
                    placeholder="Groupes"
                    variant="outlined"
                />
            </ra-input>

            <p
                v-if="groups.length == 0"
                class="blue-text ml-2 mt-2"
            >
                {{ $t('groups.affect.no_ref') }}.
            </p>

            <ra-stack direction="horizontal">
                <ra-typo
                    no-word-wrap
                    small
                    >* {{ $t('generics.need_input') }}</ra-typo
                >
                <ra-spacer />
                <ra-btn
                    :disabled="group_id === undefined"
                    :loading="loading"
                    bg="primary-gradient"
                    class="button primary block"
                    type="submit"
                    @click="affect()"
                >
                    {{ $t('groups.affect.cta') }}
                </ra-btn>
            </ra-stack>
        </ra-stack>

        <!--    <v-container>-->
        <!--      <v-row dense>-->
        <!--        <v-col cols="12"> </v-col>-->
        <!--      </v-row>-->
        <!--    </v-container>-->
    </ra-base-dialog>
</template>

<script>
    import { mixins } from '@realityteam/reality-academy-ui-kit'

    export default {
        mixins: [mixins.RaDialogMixin],
        props: ['value', 'groups'],
        data() {
            return {
                group_id: undefined,
                loading: false,
            }
        },

        computed: {
            groupsWithNone() {
                return [
                    {
                        id: null,
                        name: 'Aucun groupe',
                    },
                ].concat(this.groups)
            },
        },

        methods: {
            affect() {
                this.$emit('onGroupSelected', this.group_id, 'groupId')
                this.close()
            },
        },
    }
</script>
