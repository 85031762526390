import { formersRoutesMeta as meta } from './meta.js'
import { MyStatRoutesName } from '@core/router/routes/Former/MyStats.names.js'

export default [
    {
        path: '/my-stats',
        name: MyStatRoutesName.index,
        meta,
        component: () => import('@pages/My-stats.vue'),
    },
]
