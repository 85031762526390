<script>
    import FormationCard from '@components/Formations/FormationCard.vue'

    export default {
        name: 'FormationGrid',
        components: { FormationCard },
        props: {
            formations: {
                type: Array,
                default: () => [],
            },
            formationBind: {
                type: Object,
                default: {},
            },
        },
        emits: [
            'click:formation',
            'click:formation:edit',
            'click:formation:delete',
        ],
    }
</script>

<template>
    <ra-box
        class="formation-grid"
        padding="pb-4"
    >
        <slot
            v-for="formation in formations"
            v-bind="{ formation }"
        >
            <formation-card
                :key="formation.id"
                :formation="formation"
                shadow
                v-bind="formationBind"
                @click="$emit('click:formation', formation)"
                @click:edit="$emit('click:formation:edit', formation)"
                @click:delete="$emit('click:formation:delete', formation)"
            />
        </slot>
    </ra-box>
</template>

<style lang="scss" scoped>
    [data-device='tablet'] .formation-grid {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
    [data-device='web'] .formation-grid {
        grid-template-columns: repeat(auto-fit, minmax(270px, 350px));
    }

    .formation-grid {
        display: grid;

        grid-gap: 20px;

        .formation-card {
            width: 100%;
            height: 100%;
        }

        @media screen and (max-width: var(--tabletSize)) {
            background: red;
        }
    }
</style>
