<script>
    import { mapState } from 'pinia'
    import { useUserStore } from '@store/user.js'
    import { UserProvider } from '@providers'
    export default {
        data() {
            return {
                floatHover: true,
            }
        },
        mounted() {
            setTimeout(() => {
                this.floatHover = false
            }, 3000)
        },
        computed: {
            ...mapState(useUserStore, ['lastLoggedUser', 'user']),
            access_token() {
                return localStorage.getItem('access_token')
            },
        },
        methods: {
            async lastLogin() {
                const { data } = await UserProvider.logoutAs({
                    access_token: this.access_token,
                    old_id: this.lastLoggedUser.pop(),
                }).call()
                this.$router.push({
                    name: 'login_token',
                    params: { token: data.json_token, old_id: null },
                })
            },
        },
    }
</script>
<template>
    <div
        v-if="!lastLoggedUser.isEmpty"
        :class="['floating-login-as-container', floatHover ? '' : 'float-hide']"
        @mouseenter="floatHover = true"
        @mouseleave="floatHover = false"
    >
        <span>
            <v-icon
                class="pr-3"
                color="#7b34ab"
                >mdi-account-arrow-right</v-icon
            >
            <span
                v-html="
                    $t('global.auth.login_as_another_user', {
                        user: user ? user.firstname + ' ' + user.lastname : '',
                    })
                "
            ></span>
        </span>
        <button @click="lastLogin">
            {{ $t('global.auth.logout') }}
        </button>
    </div>
</template>

<style lang="scss">
    .floating-login-as-container {
        & > span {
            display: flex;
        }

        z-index: 20;
        padding: 1rem;
        position: fixed;
        top: 80px;
        border: 1.5px solid #7b34ab;
        background: #d4c3e4d8;
        right: 0;
        border-radius: 7px;
        color: #7b34ab;
        display: flex;
        gap: 2rem;
        flex-wrap: wrap;
        margin: 0 2rem;
        transition-duration: 0.5s;
        transform: translateX(-15px);
        &::after {
            content: '';
            position: absolute;
            background: transparent;
            width: 100px;
            height: 100%;
            right: -100px;
            top: 0px;
        }
        i {
            color: #7b34ab;
        }
        button {
            display: inline-block;

            text-transform: uppercase;
            font-weight: bold;
            color: #7b34ab !important;
            pointer-events: all;
        }
    }

    .float-hide {
        transform: translateX(calc(100% - 20px));
    }
</style>
