<script>
export default {
  name: "MDMHeader",
  props: {
    withClose: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <ra-stack bg="primary" class="mdm-header" direction="horizontal">
    <web>
      <img alt="" src="@assets/images/logos/logo_full_white.png" />
    </web>
    <tablet>
      <img alt="" src="@assets/images/logos/ra_link_logo_vertical_blanc.png" />
    </tablet>
    <ra-box
      v-if="withClose"
      centered-content
      padding="pr-5"
      @click="$emit('click')"
    >
      <v-btn class="text-primary" icon="mdi-close" />
    </ra-box>
  </ra-stack>
</template>

<style lang="scss">
.mdm-header {
  width: 100%;

  img {
    width: 60%;
    display: block;
    margin: auto;
    object-fit: contain;
    height: 70px;

    box-sizing: border-box;
  }
}
</style>
