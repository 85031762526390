<script>
    import NotFound from './NotFound.vue'

    export default {
        name: 'Loader',
        components: { NotFound },
        props: {
            waitFor: {
                default: false,
            },
            height: {
                type: String,
                default: '400px',
            },
        },
        data() {
            return {
                notFoundError: false,
                apiRequest : null,
                requestListenerRemove : null
            }
        },
        computed: {
            isLoading() {
                if (this.waitFor instanceof Array)
                    return this.waitFor.length === 0
                return !Boolean(this.waitFor)
            },
        },

        beforeUnmount() {
            if(this.requestListenerRemove){
                this.requestListenerRemove()
            }
        },

        methods:{
            /**
             *
             * @param {ApiRequest} apiRequest
             */
            registerRequest(apiRequest){
                this.apiRequest = apiRequest
                this.requestListenerRemove = this.apiRequest.event.on('apiError',(apiError)=>{
                    this.notFoundError = apiError.hasStatus(404)
                })
            }
        }
    }
</script>

<template>
    <slot v-if="!isLoading"></slot>
    <ra-box
        v-else
        :style="{ height }"
        class="loader-container"
    >
        <v-progress-circular
            v-if="!notFoundError"
            color="primary"
            indeterminate
        ></v-progress-circular>
        <not-found v-else/>
    </ra-box>
</template>

<style lang="scss" scoped>

    .loader-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
