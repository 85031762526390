export const ResourcesRoutesName = {
    index: 'resources',
    videos: {
        index: 'resources.videos.index',
        show: 'resources.videos.show',
    },
    faqs: {
        index: 'resources.faqs.index',
        show: 'resources.faqs.show',
        create: 'resources.faqs.create',
        edit: 'resources.faqs.edit',
    },
    educational_guide: {
        index: 'educational-guides.index',
    },
    synthesis_sheet: {
        index: 'synthesis-sheet.index',
    },
}
