<script>
export default {
  name: "FormationModuleProgression",
  props: ["stream"],
  computed: {
    currentModule() {
      return this.stream.modulesState?.currentModule;
    },
  },

  methods: {
    getAttrs(module, index) {
      return {
        class: {
          "formation-module-progression__module": true,
          "is-current": this.currentModule === module.number,
          "is-done": this.currentModule >= module.number,
        },
      };
    },
  },
};
</script>
<template>
  <ra-stack
    v-if="stream.modulesState"
    class="formation-module-progression"
    direction="horizontal"
    gap="0"
  >
    <div
      v-for="(module, index) of stream.modulesState.modules"
      :class="getAttrs(module, index).class"
    >
      M{{ module.number }}
    </div>
  </ra-stack>
</template>
<style lang="scss">
.formation-module-progression {
  --gap: 2em;
  position: absolute;
  bottom: 1.5em;
  left: 1.5em;
  gap: var(--gap);
  z-index: 20;
  color: white;
  pointer-events: none;
  overflow: visible !important;
  .formation-module-progression__module {
    background-color: var(--primary);
    padding: 0.5em 0.55em;
    font-weight: 600;
    border-radius: 100%;
    &.is-done {
      background-color: white;
      color: var(--primary);
      &::before {
        background: white !important;
      }
    }

    &.is-current {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 120%;
        height: 120%;
        border: 1px solid white;
        border-radius: 100%;
      }
    }

    & + .formation-module-progression__module {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: calc(-1 * var(--gap));
        transform: translateY(-50%);
        width: var(--gap);
        height: 2px;
        background-color: var(--primary);
        z-index: -1;
      }
    }
  }
}
</style>
