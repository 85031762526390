<script>
    export default {
        props: ['link', 'custom_name'],
        methods: {
            download() {
                let data = this.$refs.qrcode.$el.toDataURL('image/jpeg')
                let link = document.createElement('a')
                link.download = (this.custom_name ?? 'qr_code') + '.jpeg'
                link.href = data
                link.click()
            },
        },
    }
</script>

<template>
    <ra-box
        centered-content
        padding="pt-4"
    >
        <ra-box
            bg="tablet-light"
            centered-content
            class="qr-container"
            padding="4"
            rounded
        >
            <qr-code
                ref="qrcode"
                :size="300"
                :value="link"
            ></qr-code>
            <v-btn
                color="primary"
                icon="mdi-download"
                size="small"
                @click="download"
            ></v-btn>
        </ra-box>
    </ra-box>
</template>

<style lang="scss">
    .qr-container {
        position: relative;
        button {
            opacity: 0;
            position: absolute;
            transform: translateX(1em);
            top: 2em;
            right: 2em;
            transition: 0.3s;
        }

        &:hover {
            button {
                opacity: 1;
                transform: translateX(0em);
            }
        }
    }
</style>
