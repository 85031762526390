<template>
    <div class="d-flex flex-column">
        <div
            class="d-flex align-center justify-center text-center chart-session-dones"
        >
            <canvas
                id="chart"
                ref="chart"
            ></canvas>
        </div>
        <br />
        <div
            v-if="displayMinimizeLegend"
            class="text-center minimized-legend"
        >
            {{ $t('generics.to') }} {{ labels[0] }} {{ $t('generics.from') }}
            {{ labels[labels.length - 1] }}
        </div>
    </div>
</template>
<script>
    import { Chart, registerables } from 'chart.js'
    export default {
        name: 'ChartSessionDones',
        data() {
            return {
                width: window.innerWidth,
            }
        },
        props: ['sessions_done', 'success_rate'],
        computed: {
            ctx() {
                return this.$refs.chart.getContext('2d')
            },
            isYears() {
                return Number.isInteger(
                    Number(Object.keys(this.sessions_done)[0])
                )
            },
            labels() {
                return Object.keys(this.sessions_done).map((l) =>
                    l.replace(/[0-9]+__/g, '')
                )
            },
            datasets() {
                return {
                    sessions_done: Object.values(this.sessions_done),
                    success_rate: Object.values(this.success_rate),
                }
            },
            chartData() {
                const data = {
                    labels: this.labels,
                    datasets: [
                        {
                            type: 'line',
                            label: this.$t('stats.formation_done.y_axe_1'),
                            data: this.datasets.success_rate,
                            fill: false,
                            tension: 0,
                            borderColor: '#090954',
                            yAxisID: 'successRateAxis',
                        },
                        {
                            type: 'bar',
                            label: this.$t('stats.formation_done.y_axe_2'),
                            data: this.datasets.sessions_done,
                            //orderColor: "rgb(255, 99, 132)",
                            backgroundColor:
                                this.background || 'rgba(255, 99, 132)',
                            yAxisID: 'sessionsDonesAxis',
                        },
                    ],
                }
                return data
            },
            chartConfig() {
                return {
                    type: 'bar',
                    data: this.chartData,
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,

                        plugins: {
                            legend: {
                                display: false,
                            },
                        },
                        scales: {
                            x: {
                                ticks: {
                                    display: !this.displayMinimizeLegend, // masque les étiquettes de l'axe x
                                },
                            },
                            successRateAxis: {
                                title: {
                                    display: true,
                                    text: this.$t(
                                        'stats.formation_done.y_axe_1'
                                    ),
                                },
                                grid: {
                                    display: false,
                                },
                                position: 'left',
                                min: 0,
                                max: 100,
                                beginAtZero: true,
                            },
                            sessionsDonesAxis: {
                                title: {
                                    display: true,
                                    text: this.$t(
                                        'stats.formation_done.y_axe_2'
                                    ),
                                },
                                grid: {
                                    display: false,
                                },
                                position: 'right',
                                min: 0,
                                max: Math.max(
                                    [
                                        Math.ceil(
                                            (Math.max(
                                                ...this.datasets.sessions_done
                                            ) *
                                                1.2) /
                                                10
                                        ) * 10,
                                    ],
                                    10
                                ),
                                beginAtZero: true,
                            },
                        },
                    },
                }
            },
            displayMinimizeLegend() {
                return this.width < 600
            },
        },

        mounted() {
            let ctx = this.ctx
            var background_1 = ctx.createLinearGradient(0, 0, 0, 600)
            background_1.addColorStop(1, '#f5336b')
            background_1.addColorStop(0, '#7b34ab')
            this.background = background_1
            Chart.register(...registerables)
            this.chart = new Chart(ctx, this.chartConfig)
        },

        methods: {
            updateCharts() {
                this.width = this.$refs.chart.offsetWidth
                this.chart.destroy()
                this.chart = new Chart(this.ctx, this.chartConfig)
            },
        },
        watch: {
            sessions_done: {
                handler() {
                    this.chart.destroy()
                    this.chart = new Chart(this.ctx, this.chartConfig)
                },
            },
            currentLocale() {
                this.chart.destroy()
                this.chart = new Chart(this.ctx, this.chartConfig)
            },
        },
    }
</script>
<style lang="scss">
    .chart-session-dones {
        canvas {
            height: 100%;
            width: 100%;
            min-height: 200px;
            max-height: 400px;
        }
        position: relative;
        &::before {
            content: '';
            position: absolute;
            background: #090954;
            width: 3px;
            height: 15px;
            bottom: 20px;
            left: 10px;
        }
        &::after {
            content: '';
            position: absolute;
            background: linear-gradient(0deg, #f5336b, #7b34ab) !important;
            width: 15px;
            height: 15px;
            bottom: 20px;
            right: 4px;
        }
    }
    .minimized-legend {
        font-size: 12px;
        opacity: 0.6;
    }
</style>
