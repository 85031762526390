import { formersRoutesMeta as meta } from './meta.js'
import { godRoutesMeta } from '@routes/God/meta.js'
import { ContentRoutesNames } from './Content.names.js'

export default [
    {
        path: '/content',
        component: () => import('@pages/Content/ContentPage.vue'),
        children: [
            {
                path: '',
                name: ContentRoutesNames.content,
                redirect: {
                    name: ContentRoutesNames.formations.index,
                },
                meta,
            },
            {
                path: 'formations',
                children: [
                    {
                        path: '',
                        name: ContentRoutesNames.formations.index,
                        meta: godRoutesMeta,
                        component: () =>
                            import(
                                '@components/Formations/FormationTableList.vue'
                            ),
                    },
                    {
                        path: 'create',
                        name: ContentRoutesNames.formations.create,
                        meta,
                    },
                ],
            },
        ],
    },
    {
        path: '/content/formations/:formation_id',
        name: ContentRoutesNames.formations.show,
        meta,
        component: () => import('@pages/Content/FormationFormPage.vue'),
    },
    {
        path: '/content/formations/create',
        name: ContentRoutesNames.formations.create,
        meta,
        component: () => import('@pages/Content/FormationFormPage.vue'),
    },
]
