import { Structure } from '@libs/Structures/Structure.js'

export const FORMATION_SESSION_STATUS = {
    TERMINATED: 'TERMINATED',
    RUNNING: 'RUNNING',
    ABORTED: 'ABORTED',
}
export class FormationSessionStructure extends Structure {
    constructor(...args) {
        super(...args)
    }

    get scorePreview() {
        if (this.evaluation_result) {
            return {
                score: this.evaluation_result.score,
                baseScore: this.evaluation_result.score_base,
            }
        } else if (this.moduleResults) {
            return {
                score: this.moduleResults.score,
                baseScore: this.moduleResults.base_score,
            }
        }
        return null
    }

    get isTerminated() {
        return this.status === FORMATION_SESSION_STATUS.TERMINATED
    }
}
