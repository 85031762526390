<script>
    import HeadsetStreamControls from './HeadsetStreamControls.vue'
    import FormationModuleProgression from './FormationModuleProgression.vue'
    import MDMDebug from './MDMDebug.vue'

    export default {
        name: 'HeadsetVideoStream',
        components: {
            FormationModuleProgression,
            HeadsetStreamControls,
            MDMDebug,
        },
        props: ['headset'],
        data() {
            return {
                debugMDMactive: false,
            }
        },
        mounted() {
            if (!this.headset?.stream) return
            this.headset.stream.attach(
                this,
                this.$refs.videoPlayer,
                this.$refs.controls
            )
        },

        watch: {
            headset(h) {
                if (!h) return
                this.headset.stream.attach(
                    this,
                    this.$refs.videoPlayer,
                    this.$refs.controls
                )
            },
        },
    }
</script>
<template>
    <div class="headset-video-stream-container">
        <div v-if="headset">
            <div class="no-cast-warn">
                {{
                    headset.isIdle
                        ? $t('headsets.pilotage.idle_message')
                        : $t('headsets.pilotage.no_casting_started')
                }}
            </div>
            <video
                :style="{ opacity: headset.isIdle ? 0 : 1 }"
                ref="videoPlayer"
                :class="headset?.stream?.streaming && 'video-stream-started'"
                poster="https://cdn.indiawealth.in/public/images/transparent-background-mini.png"
            ></video>
            <HeadsetStreamControls
                v-if="headset?.stream?.streaming"
                ref="controls"
                :headset="headset"
                @debug="debugMDMactive = !debugMDMactive"
            />
            <FormationModuleProgression
                v-if="headset?.stream?.streaming"
                :stream="headset.stream"
            />
            <MDMDebug
                ref="monitoring"
                v-model="debugMDMactive"
                :headset="headset"
            />
        </div>
        <span v-else>
            {{ $t('headsets.pilotage.no_stream_headset_selected') }}
        </span>
    </div>
</template>
<style lang="scss">
    .mdm-stream-fullscreen {
        position: absolute !important;
        height: 100% !important;
        inset: 0;
        z-index: 100;
    }

    .headset-video-stream-container {
        position: relative;
        background: #dcdced;
        color: var(--primary-lighten);
        width: 100%;
        height: 100%;
        display: flex;

        justify-content: center;
        align-items: center;

        > div {
            position: relative;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        .no-cast-warn {
            display: block;
            position: absolute;
            z-index: 1;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            text-align: center;
        }

        video {
            position: relative;
            visibility: hidden;
            width: 100%;
            height: 100%;
            object-fit: contain;

            &.video-stream-started {
                visibility: visible;
                z-index: 2;
                background: black;
            }
        }
    }
</style>
