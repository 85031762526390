<script>
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import { mapActions, mapState } from 'pinia'
    import { useMDMStore } from '@store/mdm.js'

    import ApplicationLayout from '@layouts/global/ApplicationLayout.vue'

    import HeadsetsTableList from '@components/Headsets/HeadsetsTableList.vue'
    import ConnectedStatus from '@components/Headsets/ConnectedStatus.vue'
    import BatteryLevel from '@components/Headsets/BatteryLevel.vue'
    import HeadsetInformationDetails from '@components/Headsets/Details/HeadsetInformationDetails.vue'
    import HeadsetFormations from '@components/Headsets/Details/HeadsetFormations.vue'
    import HeadsetSettings from '@components/Headsets/HeadsetSettings.vue'
    import { HeadsetRoutesNames } from '@routes/generators/Headset.names.js'
    import HeadsetRenameDialog from '@components/Headsets/Details/HeadsetRenameDialog.vue'
    import { clamp } from '@utils/clamp.js'
    import CompanyOrMineMixin from '@mixins/CompanyOrMineMixin.js'
    import Loader from '@components/tools/Loader.vue'

    export default {
        mixins: [mixins.RaTabsMixin, mixins.RaDialogsMixin, CompanyOrMineMixin],
        components: {
            Loader,
            HeadsetRenameDialog,
            BatteryLevel,
            ConnectedStatus,
            HeadsetsTableList,
            ApplicationLayout,
        },
        data() {
            return {
                currentTab: 0,
                headset: null,
            }
        },
        created() {
            this.startMDM()
            const companyId = this.$route.query.companyId || this.companyID
            this.setCompanyID(companyId)
            this.fetchCompanyHeadsets(companyId).then(() => {
                this.headset = this.getHeadset(this.$route.params.serialNumber)
                if(!this.headset) {
                    this.$router.push({ name: HeadsetRoutesNames.index })
                }
            })
        },
        computed: {
            ...mapState(useMDMStore, [
                'startMDM',
                'getHeadset',
                'syncedHeadsets',
                'notSyncedHeadsets',
                'headsetsAppDriven',
                'companyHeadsets',
                'MDM_LOADING',
                'companyDevices',
                'fetchCompanyHeadsets',
            ]),
            tabsComponents() {
                return [
                    HeadsetInformationDetails,
                    HeadsetFormations,
                    HeadsetSettings,
                ]
            },
        },
        methods: {
            clamp,
            ...mapActions(useMDMStore, [
                'setCompanyID',
                'startMDM',
                'handleStartFormation',
                'handleStream',
            ]),
            goBack() {
                if (this.$route.query?.back) {
                    this.$router.push(this.$route.query.back)
                } else {
                    this.$router.push({ name: HeadsetRoutesNames.index })
                }
            },
            handleClickStartFormation(formation) {
                this.handleStartFormation([this.headset], () =>
                    this.$router.push({
                        name: HeadsetRoutesNames.startSession,
                        query: {
                            back: this.$route.fullPath,
                            formation_id: formation.id,
                        },
                    })
                )
            },
            handleClickStream() {
                this.handleStream([this.headset], () =>
                    this.$router.push({
                        name: HeadsetRoutesNames.drive,
                        query: {
                            back: this.$route.fullPath,
                        },
                    })
                )
            },
        },
        watch: {
            'headset.isConnected'() {
                this.currentTab = 0
            },
        },
    }
</script>

<template>
    <application-layout>
        <loader :wait-for="headset">
            <web v-if="headset">
                <ra-box>
                    <ra-stack gap="5">
                        <ra-card class="headset-details">
                            <template #right>
                                <v-btn
                                    icon
                                    variant="plain"
                                    density="comfortable"
                                    @click="openDialog('dialog-rename-headset')"
                                >
                                    <ra-icon>fa-edit</ra-icon>
                                </v-btn>
                            </template>
                            <template #title> Casque</template>
                            <ra-stack
                                direction="horizontal"
                                gap="4"
                                mobile-swap
                                padding="5,"
                            >
                                <ra-box width="80px">
                                    <img
                                        :src="headset.image"
                                        alt="headset"
                                        width="80"
                                    />
                                </ra-box>
                                <ra-box>
                                    <ra-typo
                                        :caption="true"
                                        maxWidth="150px"
                                        no-word-wrap
                                        semi
                                        uppercase
                                        variant="subtitle"
                                    >
                                        {{ headset.name }}
                                    </ra-typo>
                                    <ra-typo
                                        :caption="true"
                                        maxWidth="150px"
                                        no-word-wrap
                                        uppercase
                                        variant="x-small"
                                    >
                                        {{ headset.state.model }}
                                    </ra-typo>
                                    <ra-spacer />
                                    <ra-stack>
                                        <v-btn
                                            v-if="headset.isConnected"
                                            density="compact"
                                            variant="outlined"
                                            @click="handleClickStream"
                                        >
                                            <ra-typo
                                                semi
                                                uppercase
                                            >
                                                Caster
                                            </ra-typo>
                                            <ra-icon class="ml-2">
                                                $headset-stream
                                            </ra-icon>
                                        </v-btn>
                                        <v-btn
                                            v-if="headset.isConnected"
                                            density="compact"
                                            variant="outlined"
                                            @click="headset.goToHub()"
                                        >
                                            <ra-typo
                                                semi
                                                uppercase
                                            >
                                                Retourner au hub
                                            </ra-typo>
                                        </v-btn>
                                    </ra-stack>
                                </ra-box>
                                <ra-box>
                                    <ra-stack gap="4">
                                        <ra-stack direction="horizontal">
                                            <ra-typo semi>
                                                <connected-status
                                                    :isLAN="headset._isLAN"
                                                    :value="headset.isConnected"
                                                />
                                            </ra-typo>
                                            <ra-spacer />
                                            <battery-level
                                                :value="
                                                    clamp(
                                                        headset.state
                                                            .batteryLevel,
                                                        0,
                                                        100
                                                    )
                                                "
                                            />
                                        </ra-stack>
                                        <ra-stack
                                            direction="horizontal"
                                            gap="1"
                                            v-if="
                                                Boolean(
                                                    headset?.state?.leftHand
                                                        ?.batteryLevel
                                                )
                                            "
                                        >
                                            <connected-status
                                                :compact="true"
                                                :value="
                                                    Boolean(
                                                        headset?.state
                                                            ?.leftHand ||
                                                            headset?.state
                                                                ?.leftHand
                                                                ?.batteryLevel
                                                    )
                                                "
                                            />
                                            <ra-typo
                                                no-word-wrap
                                                semi
                                            >
                                                Mannette gauche</ra-typo
                                            >
                                            <ra-spacer />
                                            <battery-level
                                                :value="
                                                    clamp(
                                                        headset.state.leftHand
                                                            .batteryLevel,
                                                        0,
                                                        100
                                                    )
                                                "
                                                class="ml-3"
                                            />
                                        </ra-stack>
                                        <ra-stack
                                            direction="horizontal"
                                            distribution="equal-spacing"
                                            gap="1"
                                            v-if="
                                                Boolean(
                                                    headset?.state?.rightHand
                                                        ?.batteryLevel
                                                )
                                            "
                                        >
                                            <connected-status
                                                :compact="true"
                                                :value="
                                                    Boolean(
                                                        headset?.state
                                                            ?.rightHand ||
                                                            headset?.state
                                                                ?.rightHand
                                                                ?.batteryLevel
                                                    )
                                                "
                                            />
                                            <ra-typo
                                                no-word-wrap
                                                semi
                                            >
                                                Mannette droite</ra-typo
                                            >
                                            <ra-spacer />

                                            <battery-level
                                                :value="
                                                    clamp(
                                                        headset.state.rightHand
                                                            .batteryLevel,
                                                        0,
                                                        100
                                                    )
                                                "
                                                class="ml-3"
                                            />
                                        </ra-stack>
                                    </ra-stack>
                                </ra-box>
                            </ra-stack>
                        </ra-card>

                        <v-tabs
                            v-if="headset.isConnected"
                            v-model="currentTab"
                        >
                            <v-tab @click="hideHandShowTabs">
                                <ra-typo semi> Informations</ra-typo>
                            </v-tab>
                            <v-tab @click="hideHandShowTabs">
                                <ra-typo semi> Formations</ra-typo>
                            </v-tab>
                            <!--            <v-tab @click="hideHandShowTabs">-->
                            <!--              <ra-typo semi> Historique</ra-typo>-->
                            <!--            </v-tab>-->
                            <v-tab @click="hideHandShowTabs">
                                <ra-typo semi> Paramètres</ra-typo>
                            </v-tab>
                        </v-tabs>

                        <v-fade-transition>
                            <component
                                :is="tabsComponents[currentTab]"
                                v-if="tabsDisplay"
                                v-model="headset"
                                :headset="headset"
                                @start-formation="handleClickStartFormation"
                            />
                        </v-fade-transition>
                    </ra-stack>
                </ra-box>
            </web>
        </loader>
        <ra-dialog ref="dialog-rename-headset">
            <headset-rename-dialog :headset="headset" />
        </ra-dialog>
    </application-layout>
</template>

<style lang="scss" scoped>
    .headset-details {
        img {
            object-fit: contain;
            transform: scale(1.3);
        }
        max-width: 600px;

        .ra-stack {
            & > .ra-box:nth-child(2) {
                max-width: 40%;
                @media screen and (max-width: 760px) {
                    max-width: 100%;
                }
            }

            > .ra-stack {
                flex: 0 0 auto;
            }
        }
    }
</style>
