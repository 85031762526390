export class PWAUpdateUtils {
    constructor(showSnackBar, hideSnackbar, translate) {
        this.hideSnackbar = hideSnackbar
        this.showSnackBar = showSnackBar
        this.translate = translate
    }

    async listenForUpdate() {
        if (!window.navigator.serviceWorker) return
        const registration =
            await window.navigator.serviceWorker.getRegistration()
        if (!registration) return
        //has a waiting worker installed not activated ?
        if (
            registration &&
            registration.waiting &&
            registration.waiting.state === 'installed'
        ) {
            this.showUpdateSnackBar(registration.waiting)
        }

        //listening for new sw update
        registration.addEventListener('updatefound', () => {
            let newWorker = registration.installing
            this.showUpdateSnackBar(newWorker)
        })
    }

    async showUpdateSnackBar(sw) {
        let message = window.IS_TABLET
            ? this.translate('generics.new_update_tablet')
            : this.translate('generics.new_update')

        this.showSnackBar(message, {
            duration: -1,
            closable: false,
            class: ['default-alert', 'right-alert', 'text-left'],
            actions: [
                {
                    text: this.translate('generics.refresh'),
                    handler: () => {
                        this.showSnackBar(
                            this.translate('generics.installing_update'),
                            {
                                duration: -1,
                                closable: false,
                                class: [
                                    'default-alert',
                                    'right-alert',
                                    'text-left',
                                ],
                                actions: undefined,
                            }
                        )

                        sw.postMessage({ type: 'SKIP_WAITING' })
                        sw.addEventListener('statechange', () => {
                            if (sw.state === 'activated') {
                                window.location.reload()
                            }
                        })
                    },
                },
                {
                    text: this.translate('generics.ignore'),
                    handler: () => {
                        this.hideSnackbar()
                    },
                },
            ],
        })
    }
}
