import { defineStore } from 'pinia'
import { WSEvent, WebSocketClient } from '@libs/MDM/core/WebsocketClient'
import { useAppStore } from './app'
import { Database } from '@core/database/init'
import { useUserStore } from '@store/user.js'
import { HeadsetProvider } from '@providers/HeadsetProvider.js'
import { HeadsetManager } from '@libs/MDM/core/HeadsetManager.js'
import FormationCollection from '@libs/Collections/Formation.js'
import { Collection } from '@libs/Collections/Collection.js'
import { Headset } from '@libs/MDM/core/Headset.js'
import { PaginationUtils } from '@utils/PaginationUtils.js'
import { PaginatedCollection } from '@libs/Collections/PaginatedCollection.js'
import { LicensesProvider } from '@providers/LicensesProvider.js'

export const useMDMStore = () => {
    const store = defineStore('mdm', {
        state: () => {
            return {
                onlineHeadsets: new HeadsetManager(), // Ra api headset that are connected to websocket
                offlineHeadsets: new Collection(), // Ra api headset that are not connected to websocket
                apiHeadsets: [], // headset  from the api
                localHeadsets: new HeadsetManager(), // LAN websocket headset
                syncedHeadsets: new PaginatedCollection(), // headset synced with the app (all onlineHeadset + all synced localHeadsets even if offline)
                notSyncedHeadsets: new Collection(), // only localHeadset not synced with the app
                headsetsAppDriven: [], // headset selected for drive

                online_ws: null,
                local_ws: null,
                _listening: false,
                db: Database.getInstance(),

                MDM_LOADING: false,
                last_query: {},

                companyID: null,
                companyDevices: {
                    max: null,
                    count: null,
                },

                companyLicenses: null,
            }
        },

        getters: {
            packsReadyForDrive() {
                return this.formationReadyForDrive.packs
            },
            formationReadyForDrive() {
                const allFormations = this.headsetsAppDriven.reduce(
                    (prev, headset) => {
                        prev.push(...headset.state.formationsReadyToStart)
                        return prev
                    },
                    []
                )

                const headsetCount = this.headsetsAppDriven.length

                const formationIDsTested = []
                const formationsReadyForDrive = allFormations.filter(
                    (formation) => {
                        if (formationIDsTested.includes(formation.id))
                            return false
                        let formationCount = allFormations.filter(
                            (f) => f.id === formation.id
                        ).length
                        formationIDsTested.push(formation.id)
                        return formationCount == headsetCount
                    }
                )

                return new FormationCollection(formationsReadyForDrive)
            },
        },

        actions: {
            setCompanyID(companyID) {
                this.MDM_LOADING = false
                this.companyID = companyID
                if (companyID) this.loadCompanyLicenses().then()
            },
            /**
             * Fetch all the headsets of a company and add it to the onlineHeadset collection
             * @param companyID
             * @param query
             * @param cache
             * @returns {Promise<void>}
             */
            async fetchCompanyHeadsets(
                companyID,
                q = null,
                cache = false,
                resetLoading = false
            ) {
                const appStore = useAppStore()
                const sortingNameMap = {
                    'state.name': 'name',
                    user: 'lastSession.user.firstname',
                    'state.shared_group.name': 'sharedGroup.name',
                    'state.group.name': 'group.name',
                    'state.last_session.version': 'lastSession.version',
                }

                let query = JSON.parse(JSON.stringify(q)) || {} // clone the query to avoid side effect
                query = this.syncedHeadsets.getParsedQuery(
                    {
                        ...this.syncedHeadsets.defaultQuery,
                        itemsPerPage: appStore.isTablet ? -1 : 15,
                        ...query,
                    },
                    sortingNameMap
                )

                if (query) {
                    PaginationUtils.parseLimit(query)
                    query.sortBy =
                        query.orderBy && query.sortBy
                            ? [
                                  {
                                      key: query.orderBy,
                                      order: query.orderWay,
                                  },
                              ]
                            : []
                    PaginationUtils.parseSortBy(query, sortingNameMap)
                }
                this.lastQuery = query ? query : this.lastQuery
                if (this.MDM_LOADING != -1 || resetLoading)
                    this.MDM_LOADING = true
                if (appStore.isOffline) return
                if (!cache) {
                    const { data: body } = await HeadsetProvider.fetchAll(
                        companyID,
                        {
                            ...this.lastQuery,
                            connected_serials: Array.from(
                                Headset.instances.values()
                            )
                                .map((h) =>
                                    h.isConnected ? h.serialNumber : false
                                )
                                .filter(Boolean),
                        }
                    ).call()
                    this.syncedHeadsets.pagination = body
                    this.companyDevices = body.company_devices
                    this.apiHeadsets = body.data.map((headsetPayload) => {
                        return {
                            serialNumber: headsetPayload.serial_number,
                            config: {
                                ws: null,
                                state: {
                                    id: headsetPayload.id,
                                    last_session: headsetPayload.last_session,
                                    name: headsetPayload.name,
                                    custom_name: headsetPayload.custom_name,
                                    custom_name_updated_at:
                                        headsetPayload.custom_name_updated_at,
                                    model: headsetPayload.model,
                                    group: headsetPayload.group,
                                    shared_group: headsetPayload.shared_group,
                                    ...headsetPayload.data,
                                },
                            },
                        }
                    })
                }
                this.MDM_LOADING = -1
                let offlineHeadsets = []

                for (let headset of this.apiHeadsets) {
                    let headsetInstance = Headset.getInstance(
                        headset.serialNumber,
                        headset.config
                    )
                    headsetInstance.setState({
                        ...headsetInstance.state,
                        ...headset.config.state,
                    })

                    if (!this.onlineHeadsets.has(headset.serialNumber))
                        offlineHeadsets.push(headsetInstance)
                    else {
                        const connectedHeadset = this.onlineHeadsets.get(
                            headset.serialNumber
                        )
                        connectedHeadset.setState({
                            ...connectedHeadset.state,
                            ...headset.config.state,
                        })
                    }
                }
                this.offlineHeadsets.set(offlineHeadsets)
                await this.updateSyncedHeadsets(query?.status)
            },
            /**
             * Sync a LAN headset with the app
             * @param headset
             * @returns {Promise<void>}
             */
            async syncAnHeadset(headset) {
                const paylaod = {
                    serialNumber: headset.serialNumber,
                    state: JSON.stringify(headset.state),
                }
                let exist = await this.db.tables('synced_headsets').has(paylaod)
                if (exist) return
                await this.db.tables('synced_headsets').create(paylaod)
                await this.updateSyncedHeadsets()
            },

            /**
             * Unsync a LAN headset with the app
             * @param headset
             * @returns {Promise<void>}
             */
            async unSyncAnHeadset(headset) {
                const query = {
                    serialNumber: headset.serialNumber,
                }
                let exist = await this.db
                    .tables('synced_headsets')
                    .findOne(query)
                if (!exist) return
                await exist.delete()
                await this.updateSyncedHeadsets()
            },

            /**
             * Update the syncedHeadsets and notSyncedHeadsets collections
             * All the onlineHeadsets are synced by default, the LAN headset are synced if they are in the synced_headsets indexed DB table
             * @callBy fetchCompanyHeadsets, listen, syncAnHeadset
             * @returns {Promise<void>}
             */
            updateSyncedHeadsets: async function (statusFilter = null) {
                let tempOnlineHeadsets = this.onlineHeadsets.all
                let onlineHeadsets = Array(this.apiHeadsets.length)
                for (let h of tempOnlineHeadsets) {
                    let index = this.apiHeadsets.findIndex(
                        (apiH) => apiH.serialNumber === h.serialNumber
                    )
                    if (index !== -1) {
                        onlineHeadsets[index] = h
                    }
                }
                onlineHeadsets = onlineHeadsets.filter(Boolean)

                const offlineHeadsets = this.offlineHeadsets
                const LANHeadsets = this.localHeadsets.all
                const registeredHeadsets = await this.getRegisteredHeadsets()
                const allHeadsets = new Collection([
                    ...onlineHeadsets,
                    ...offlineHeadsets,
                    ...LANHeadsets,
                ])
                const appStore = useAppStore()
                const syncedHeadsets = this.syncedHeadsets.empty()
                const notSyncedHeadsets = this.notSyncedHeadsets.empty()

                for (let headset of allHeadsets) {
                    if (appStore.isTablet) {
                        const isRegistered = registeredHeadsets.some(
                            (h) => h.serialNumber == headset.serialNumber
                        )
                        if (isRegistered) {
                            syncedHeadsets.push(headset)
                        } else {
                            notSyncedHeadsets.push(headset)
                        }
                    } else {
                        syncedHeadsets.push(headset)
                    }
                }

                // //add the missing headset register in the indexedDB
                for (let headset of registeredHeadsets) {
                    if (
                        !syncedHeadsets.find(
                            (h) => h.serialNumber === headset.serialNumber
                        )
                    ) {
                        syncedHeadsets.push(headset)
                    }
                }

                this.syncedHeadsets = syncedHeadsets
                    .unique('serialNumber')
                    .filter((h) => {
                        if (
                            statusFilter == null ||
                            (statusFilter?.length - 2) % 2 == 0
                        )
                            return true
                        return statusFilter?.includes('connected')
                            ? h.isConnected
                            : !h.isConnected
                    })

                this.notSyncedHeadsets = notSyncedHeadsets
                    .unique('serialNumber')
                    .sort((a, b) => {
                        return a.name.localeCompare(b.name)
                    })

                this.setHeadsetsAppDriven()
            },

            setHeadsetsAppDriven() {
                this.headsetsAppDriven = this.headsetsAppDriven.filter(
                    (headset) => {
                        return this.syncedHeadsets.find(
                            (h) =>
                                h.serialNumber === headset.serialNumber &&
                                ((window.location.pathname ==
                                    '/my-headsets/drive' &&
                                    h.isConnected) ||
                                    h.isDrivable)
                        )
                    }
                )
            },

            emptyHeadsetsAppDriven() {
                this.headsetsAppDriven = []
            },

            tryConnectWSLocalLoop() {
                if (this.localHeadsets.size) {
                    this.localHeadsets.sync([])
                    this.updateSyncedHeadsets()
                }
                if (this.local_ws) {
                    WebSocketClient.instances.delete(this.local_ws.host)
                }
                this.local_ws = WebSocketClient.getInstance(
                    import.meta.env.VITE_WEBSOCKET_LAN
                )
                    .defineAsLAN()
                    .setHeadsetManager(this.localHeadsets)

                this.local_ws.on(WSEvent.HEADSETS, async ({ detail }) => {
                    await this.updateSyncedHeadsets()
                })

                let localWs = this.local_ws
                this.local_ws.on(WSEvent.CLOSE, async (e) => {
                    setTimeout(() => {
                        if (localWs.closedByUser) return
                        this.tryConnectWSLocalLoop()
                    }, 3000)
                })
            },

            async getRegisteredHeadsets() {
                const registeredHeadsets = await this.db
                    .tables('synced_headsets')
                    .findAll()
                // get a headset if already exist or create instance if not, to keep the state (ws,stream etc...)
                return new PaginatedCollection(
                    registeredHeadsets.map((h) => {
                        let oldState = JSON.parse(h?.state || '{}')
                        delete oldState.custom_name_updated_at
                        return Headset.getInstance(h.serialNumber, {
                            state: oldState,
                        })
                    })
                )
            },

            async loadCompanyLicenses() {
                const { data: licenses } = await LicensesProvider.getLicenses(
                    this.companyID,
                    {
                        type: [5, 6, 7, 8],
                    }
                ).call()
                this.companyLicenses =
                    licenses /** @use @libs/MDM/Headset.js:setState */
            },

            /**
             * Entry point of the store, listen to the websockets (LAN and/or INTERNET webSocketServer) and update the store
             * @use web / tablet
             * @returns
             */
            listen() {
                if (this._listening) console.warn('MDM already listening')
                this._listening = true

                this.getRegisteredHeadsets().then(
                    (collection) => (this.syncedHeadsets = collection)
                )

                let appStore = useAppStore()

                if (appStore.isTablet) {
                    this.tryConnectWSLocalLoop()
                } else console.log('[MDM] ignore local websocket (not tablet)')

                if (appStore.isOffline)
                    return console.log(
                        '[MDM] ignore online websocket (not Authentificated)'
                    ) // no try to connect to online websocket if offline

                this.online_ws = WebSocketClient.getInstance(
                    import.meta.env.VITE_WEBSOCKET_WEB
                ).setHeadsetManager(this.onlineHeadsets)

                this.online_ws.on(WSEvent.HEADSETS, ({ detail }) => {
                    useUserStore(async ({ user }) => {
                        await this.fetchCompanyHeadsets(this.companyID)
                    })
                })

                let onlineWS = this.online_ws
                this.online_ws.on(WSEvent.CLOSE, () => {
                    if (onlineWS.closedByUser) return
                    //if the websocket is closed by the server, fetch the headsets last time
                    useUserStore(async ({ user }) => {
                        await this.fetchCompanyHeadsets(this.companyID)
                    })
                })
            },
            startMDM() {
                //check if listening
                if (this._listening) return
                this.listen()
            },

            stop() {
                this.online_ws?.close()
                this.local_ws?.close()
                this.online_ws = null
                this.local_ws = null
                this._listening = false
                //reset vars
                this.onlineHeadsets = new HeadsetManager()
                this.offlineHeadsets = new Collection()
                this.apiHeadsets = []
                this.localHeadsets = new HeadsetManager()
                this.syncedHeadsets = new PaginatedCollection()
                this.notSyncedHeadsets = new Collection()
                this.headsetsAppDriven = []

                Headset.instances.clear()
            },

            /**
             * Drive a headset for the future action with the app
             * @use web / tablet
             * @param {Headset} headset
             */
            driveHeadset(headset) {
                if (!headset.isDrivable) return
                let isAlreadyDriven = this.headsetsAppDriven.find(
                    (h) => h.serialNumber === headset.serialNumber
                )
                if (isAlreadyDriven) return
                this.headsetsAppDriven.push(headset)
            },

            /**
             * UnDrive a headset for the future action with the app
             * @use web / tablet
             * @param {Headset} headset
             */
            unDriveHeadset(headset) {
                let index = this.headsetsAppDriven.findIndex(
                    (h) => h.serialNumber === headset.serialNumber
                )
                if (index === -1) return
                this.headsetsAppDriven.splice(index, 1)
            },

            /**
             * drive headsets and go to start-formation page
             * @env web only
             * @param {Headset[]} headsets
             */
            handleStartFormation(headsets, action = () => {}) {
                const toUnDrive = new Collection(this.headsetsAppDriven).except(
                    ...headsets
                )

                for (let headset of toUnDrive) this.unDriveHeadset(headset)
                for (let headset of headsets) this.driveHeadset(headset)
                action()
            },
            /**
             * drive headset and go to streaming page
             * @env web only
             * @param {Headset[]} headsets
             */
            handleStream(headsets, action = () => {}) {
                const toUnDrive = new Collection(this.headsetsAppDriven).except(
                    ...headsets
                )
                for (let headset of toUnDrive) this.unDriveHeadset(headset)
                for (let headset of headsets) this.driveHeadset(headset)
                action()
            },

            getHeadset(serialNumber) {
                return this.syncedHeadsets.find(
                    (h) => h.serialNumber == serialNumber
                )
            },
        },
    })
    const storeInstance = store()
    Headset.mdmStore = storeInstance
    window.$MDM = storeInstance
    return storeInstance
}
