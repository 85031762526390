export const ApplicationRoutesNames = {
    index: 'application',
    releases: {
        index: 'application.releases.index',
        show: 'application.releases.show',
    },
    platforms: {
        index: 'application.platforms.index',
        show: 'application.platforms.show',
    },
}
