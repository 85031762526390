<script>
import PilotageSettings from "@components/Headsets/PilotageSettings.vue";
import { mixins } from "@realityteam/reality-academy-ui-kit";

export default {
  components: {
    PilotageSettings,
  },
  mixins: [mixins.RaAlertMixin],
  props: {
    headsetsToParam: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      groupedSettings: {
        interactionMode: "pointer",
        controllerPreferences: "right",
        language: "fr",
        launchTraining: "use",
        quiz: {
          vr: true,
          el: true,
        },
      },
    };
  },
  methods: {
    handleSave() {
      this.headsetsToParam.forEach((h) => {
        h.state = { ...h.state, settings: this.groupedSettings };
        h.saveState();
      });
      this.alert(this.$tc("headsets.alerts.saved", 2), "success");
      this.$emit("close");
    },
  },
};
</script>

<template>
  <ra-box padding="10">
    <ra-alert ref="alert" global />
    <ra-stack bg="white" class="grouped-settings" gap="8" padding="8" rounded>
      <ra-typo bold class="text-center" uppercase variant="title">
        {{ $t("headsets.settings.title_grouped") }}
      </ra-typo>
      <v-btn
        class="grouped-settings-close"
        density="comfortable"
        icon="mdi-arrow-left"
        variant="plain"
        @click="$emit('close')"
      />
      <pilotage-settings v-model:settings="groupedSettings" />
      <ra-box centered-content>
        <ra-btn bg="primary-gradient" semi uppercase @click="handleSave">
          {{ $t("generics.save") }}
        </ra-btn>
      </ra-box>
    </ra-stack>
  </ra-box>
</template>

<style lang="scss">
.grouped-settings {
  position: relative;
  .grouped-settings-close {
    position: absolute;
    top: 1em;
    left: 1em;
  }
}
</style>
