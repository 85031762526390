<script>
    import { mixins } from '@realityteam/reality-academy-ui-kit'

    export default {
        name: 'TableView',
        props: {
            title: {
                type: String,
                default: '',
            },
            count: {
                default: null,
            },
        },
        mixins: [mixins.RaAlertMixin],
        computed: {
            hasFilters() {
                return this.$slots?.filters
            },
            hasGroups() {
                return this.$slots?.groups
            },
        },
    }
</script>

<template>
    <ra-box>
        <ra-alert
            global
            ref="alert"
        />
        <ra-aside v-if="hasFilters || hasGroups">
            <ra-filters v-if="hasFilters">
                <slot name="filters"></slot>
            </ra-filters>
            <slot
                v-if="hasGroups"
                name="groups"
            ></slot>
        </ra-aside>
        <ra-card
            :header="{
                bg: 'primary-gradient',
            }"
            v-bind="$attrs"
        >
            <template #title>
                {{ count }} {{ $tc(title, count ?? 2) }}
            </template>
            <slot></slot>
        </ra-card>
    </ra-box>
</template>
