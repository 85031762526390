export function workshopAddLearnerFormInputs(company, errors) {
    return [
        {
            type: 'form-row',
            elements: [
                {
                    is: 'form-input',
                    type: 'text',
                    property: 'lastname',
                    label: this.$t('users.properties.lastname.label') + '*',
                    required: true,
                    errors,
                },
                {
                    is: 'form-input',
                    type: 'text',
                    property: 'firstname',
                    label: this.$t('users.properties.firstname.label') + '*',
                    required: true,
                    errors,
                },
            ],
        },
        {
            type: 'form-row',
            elements: [
                {
                    is: 'form-input',
                    type: 'text',
                    property: 'email',
                    label: this.$t('users.properties.email.label'),
                    required: false,
                    errors,
                },
            ],
        },
    ]
}
