import { defineStore } from 'pinia'
import { useUserStore } from '@store/user.js'

export const GLOBAL_ENV = {
    VR: 'vr',
    ELEARNING: 'elearning',
}

export const useGlobalEnv = defineStore('globalEnv', {
    state: () => {
        let globalEnv = localStorage.getItem('globalEnv') || GLOBAL_ENV.VR
        localStorage.setItem('globalEnv', globalEnv)
        return {
            globalEnv,
            _availableEnv: [
                {
                    title: 'VR',
                    value: GLOBAL_ENV.VR,
                },
                {
                    title: 'E-learning',
                    value: GLOBAL_ENV.ELEARNING,
                },
            ],
        }
    },
    getters: {
        isVR(state) {
            return state.globalEnv == GLOBAL_ENV.VR
        },
        isEl(state) {
            return state.globalEnv == GLOBAL_ENV.ELEARNING
        },
        availableValueEnv(state) {
            return state.availableEnv.map((env) => env.value)
        },
        availableEnv(state) {
            const userStore = useUserStore()
            let filterEnv = {}
            if (userStore.user) {
                filterEnv = {
                    [GLOBAL_ENV.VR]: userStore.user.company.is_vr,
                    [GLOBAL_ENV.ELEARNING]: userStore.user.company.is_elearning,
                }
            } else {
                filterEnv = {
                    [GLOBAL_ENV.VR]: true,
                    [GLOBAL_ENV.ELEARNING]: true,
                }
            }

            let envs = this._availableEnv.filter((env) => filterEnv[env.value])
            if (!filterEnv[this.globalEnv])
                this.setGlobalEnv(envs[0].value, true)
            return envs
        },
    },
    actions: {
        setGlobalEnv(globalEnv, force = false) {
            if (!force && !this.availableValueEnv.includes(globalEnv))
                throw new Error('Invalid global env')
            this.globalEnv = globalEnv
            localStorage.setItem('globalEnv', globalEnv)
        },

        getAvailableEnv() {
            const userStore = useUserStore()
            const filterEnv = {
                [GLOBAL_ENV.VR]: userStore.company.is_vr,
                [GLOBAL_ENV.ELEARNING]: userStore.company.is_elearning,
            }
            let envs = this._availableEnv.filter((env) => filterEnv[env.value])
            if (!filterEnv[this.globalEnv])
                this.setGlobalEnv(envs[0].value, true)
            return envs
        },
    },
})
