import vue from '@config/vue'
import ApiClient from '@core/api'
import { MonitoringLogger } from '@libs/Monitoring/MonitoringLogger.js'

import "@mdi/font/css/materialdesignicons.css";

export  default async () => {
    if (window?.IS_TABLET) {
        document.body.setAttribute('data-device', 'tablet')
    } else {
        document.body.setAttribute('data-device', 'web')
    }

    // if (isProd) {
    //   window.console = {
    //     log: function () {},
    //     info: function () {},
    //     warn: function () {},
    //     error: window.console.error,
    //     trace: function () {},
    //   };
    // }

    MonitoringLogger.getInstance().setUp(window.console)
    globalThis.axios = ApiClient.getInstance()
    const app = await vue()
    app.mount('#app')
    app.remount = () => {
        app.unmount()
        app.mount('#app')
    }
    return app
}
