import { defineStore } from 'pinia'
import { useUserStore } from '@store/user.js'

export const useFAQStore = defineStore('faqTablet', {
    state: () => {
        return {
            faqIsOpen: false,
        }
    },
    actions: {
        toggleOpen() {
            this.faqIsOpen = !this.faqIsOpen
        },
        close() {
            this.faqIsOpen = false
        },
    },
})
