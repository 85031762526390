import { PDFExport } from './PDFExport.js'
import moment from 'moment'

export class WorkshopStatsExport extends PDFExport {
    constructor(workshop, ...args) {
        super(...args)
        this.workshop = workshop
        this.config = {
            width: 'auto',
            height: 'auto',
            zoomCoef: 1.5,
            containerWidth: 1440,
            rowWidth: 555,
            margin: 65,
            maxPageFill: 560,
            offsets: [80, 50],
            backgroundImage: false,
            backgroundColor: '#ffffff',
        }
    }

    header() {
        // ----------
        //heading texts
        this.doc.setFontSize(10)
        this.doc.setTextColor(9, 9, 84)
        this.doc.setFont('Montserrat-Bold')
        this.doc.text(
            this.$t('workshops.survey.pdf_content.title').toUpperCase(),
            15,
            28
        )
    }

    title() {
        this.doc.setPage(1)
        this.doc.setFont('Montserrat-Bold')
        this.doc.setFontSize(20)
        this.doc.setTextColor(9, 9, 84)

        this.doc.text(this.workshop.name.toUpperCase(), 15, 70)

        let workshopNameLength =
            20 + this.doc.getTextWidth(this.workshop.name.toUpperCase())

        this.doc.line(workshopNameLength, 20, workshopNameLength, 30)
        workshopNameLength += 5

        this.doc.setDrawColor(9, 9, 84)
        this.doc.setLineWidth(0.5)
        this.doc.line(15, 78, 100, 78)

        this.doc.setFont('Montserrat-Regular')
        this.doc.setFontSize(10)
    }
}
