<script>
    import { TranslationAlias } from '@core/i18n/TranslationAlias.js'
    import LicenseFormRow from '@components/Licenses/LicenseFormRow.vue'
    import Loader from '@components/tools/Loader.vue'
    import getTranslation from '@/mixins/getTranslation'

    export default {
        name: 'WorkshopFormationChooseModule',
        components: { Loader, LicenseFormRow },

        mixins: [getTranslation],
        data() {
            return {
                translationAlias: new TranslationAlias('workshops', this),
                selectedModules: [],
            }
        },
        props: {
            formation: {
                type: Object,
                default: {},
            },
            modelValue: String,
        },
        mounted() {
            this.selectedModules = this.modelValue.map((module) => module.id)
        },
        methods: {
            submit() {
                this.$emit(
                    'update:modelValue',
                    this.selectedModules,
                    this.formation.id
                )
                this.selectedModules = []
            },
            isSelected(moduleId) {
                return this.selectedModules.includes(moduleId)
            },
            toggleSelection(moduleId) {
                const index = this.selectedModules.indexOf(moduleId)
                if (index > -1) {
                    this.selectedModules.splice(index, 1)
                } else {
                    this.selectedModules.push(moduleId)
                }
            },
        },
    }
</script>

<template>
    <ra-base-dialog>
        <template #title>{{ $_t('actions.chooseModule') }}</template>
        <ra-stack>
            <loader :wait-for="formation.modules">
                <v-list
                    lines="three"
                    select-strategy="classic"
                >
                    <v-list-item
                        v-for="formationModule in formation.modules"
                        :key="formationModule.id"
                        :value="formationModule.id"
                        @click="toggleSelection(formationModule.id)"
                    >
                        <template v-slot:prepend>
                            <img
                                :src="formationModule.image.url"
                                width="50px"
                                style="margin-right: 20px"
                            />
                        </template>

                        <v-list-item-title>
                            {{ getTranslation(formationModule.name) }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{ getTranslation(formationModule.description) }}
                        </v-list-item-subtitle>

                        <template v-slot:append="{ isActive }">
                            <v-list-item-action start>
                                <v-checkbox
                                    @click="toggleSelection(formationModule.id)"
                                    :model-value="
                                        selectedModules.includes(
                                            formationModule.id
                                        )
                                    "
                                    @click.stop
                                ></v-checkbox>
                            </v-list-item-action>
                        </template>
                    </v-list-item>
                </v-list>
            </loader>
            <v-btn
                class="primary-gradient"
                variant="flat"
                @click="submit"
                >{{
                    $tc(
                        'formations.modules.actions.add',
                        selectedModules.length
                    )
                }}</v-btn
            ></ra-stack
        >
    </ra-base-dialog>
</template>
