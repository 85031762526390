import i18n, { i18nLocaleObserver } from '@core/i18n/index.js'
import {
    RaUIKitConfig,
    IconsDictionary,
} from '@realityteam/reality-academy-ui-kit'
import ApiClient from '@api'
import { FormersProvider, GroupProvider } from '@providers'
import { UploadProvider } from '@providers/UploadProvider.js'
import vuetifyConfig from '@config/vuetifyConfig.js'

/**
 * UiKitConfig translations generator
 * @returns {Object}
 */
function generateTranslations() {
    const t = i18n.global.t
    const tc = i18n.global.tc
    return {
        imagePicker: {
            reco: t('generics.image_picker.recommended'),
            upload: t('generics.image_picker.upload'),
        },
        filePiker: {
            select: t('generics.files_picker.select'),
            upload: t('generics.image_picker.upload'),
        },
        groupsSelector: {
            none: t('groups.no_group_select'),
            select: t('groups.select'),
            add: t('groups.create_group'),
            edit: t('groups.edit'),
        },

        select: {
            label: t('generics.select.label'),
            all: t('generics.select.all'),
        },

        texts: {
            edit: t('generics.edit'),
            delete: t('generics.delete'),
            massDelete: t('global.tables.mass_delete'),
            confirmMassDelete: t('global.tables.confirm_mass_delete'),
            cancel: t('generics.cancel'),
            validate: t('generics.validate'),
            send: t('generics.send'),
            filter: t('texts.filter'),
            groups: tc('groups._name', 2),
            wait: t('texts.wait'),
            groupedActionsSelectedLines: t(
                'global.tables.groupedActionsSelectedLines'
            ),
            groupedActionsBTN: t('global.tables.groupedActionsBTN'),
            requiredField: t('generics.required_fields'),
            yes: t('generics.yes'),
            no: t('generics.no'),
        },
    }
}

/**
 * Define UIKitConfig depending on the current locale
 * @returns {RaUIKitConfig}
 */
export default (app) => {
    let raUIKitConfig = new RaUIKitConfig({
        iconDictionary: new IconsDictionary({
            'fa-cubes': '&#xf1b3;',
            'fa-play': '&#xf04b;',
            'fa-archive': '&#xf187;',
            'fa-clock': '&#xf017;',
            'fa-home': '&#xf015;',
            'fa-users-class': '&#xf63d;',
            'fa-stopwatch': '&#xf2f2;',
            'fa-credits': '&#xf51e;',
            'fa-users': '&#xf0c0;',
            'fa-learners': '&#xf007;',
            'fa-books': '&#xf02d;',
            'fa-graduation-cap': '&#xf19d;',
            'fa-head-vr': '&#xf6ea;',
            'fa-edit': '&#xf044;',
            'fa-trash': '&#xf2ed;',
            'fa-plus': '&#xf067;',
            'fa-download': '&#xf019;',
            'fa-controller': '&#xf8bc;',
            'fa-eye': '&#xf06e;',
            'fa-eye-hide': '&#xf070;',
            'fa-check': '&#xf00c;',
            'fa-question-circle': '&#xf059;',
            'fa-file': '&#xf15b;',
            'fa-upload': '&#xf093;',
            'fa-calendar': '&#xf073;',
            'fa-success-average': '&#xf762;',
            'fa-time-spent': '&#xf017;',
            'fa-completion': '&#xf058;',
            'fa-satisfaction-rate': '&#xf164;',
            'fa-certificate-count': '&#xf559;',
            'fa-stars': '&#xf762;',
            'fa-vr': '&#xf729;',
            'fa-user': '&#xf007;',
            'fa-question-marker': '&#xf60b',
            'fa-bars': '&#xf0c9;',
            'fa-expand': '&#xf065;',
            'fa-compress': '&#xf066;',
            'fa-close': '&#xf00d;',
            'fa-chevron-left': '&#xf053;',
            'fa-chevron-right': '&#xf054;',
            'fa-chevron-up': '&#xf077;',
            'fa-chevron-down': '&#xf078;',
            'fa-arrow-left': '&#xf060;',
            'fa-arrow-right': '&#xf061;',
            'fa-arrow-up': '&#xf062;',
            'fa-arrow-down': '&#xf063;',
            'fa-circle-check': '&#xf058;',
            'fa-file-pdf': '&#xf1c1;',
            'fa-file-csv': '&#xf6dd;',
        }),
        http: {
            client: new ApiClient(),
            upload: UploadProvider.upload,
            groups: GroupProvider,
            user: {
                getUsers: FormersProvider.getFormers.bind(FormersProvider),
            },
        },
        ...generateTranslations(),
        vuetify: vuetifyConfig,
    })

    //listen for locale change
    i18nLocaleObserver.on('change', (i18n) => {
        raUIKitConfig.patch(generateTranslations())
        app.vuetify.locale.current.value = i18n.global.locale
    })

    return raUIKitConfig
}
