export function translateRecursively(object, lang, translatable = false) {
    if (typeof object !== 'object') return object
    if (translatable) {
        if (lang in object) return object[lang]
        return object[Object.keys(object)[0]]
    }
    let res = {}
    for (const key in object) {
        const target = object[key]
        if (Array.isArray(target)) {
            res[key] = target.map((item) =>
                translateRecursively(item, lang, translatable)
            )
        } else if (typeof target === 'object' && Boolean(target)) {
            const isTranslatable = Object.keys(target).some(
                (key) => key === 'fr' || key === 'en'
            )
            res[key] = translateRecursively(target, lang, isTranslatable)
        } else {
            res[key] = target
        }
    }
    return res
}
