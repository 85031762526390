import { Collection } from "@libs/Collections/Collection.js";
import { PackCollection } from "@libs/Collections/PackCollection.js";
import { FormationsProvider } from "@providers/FormationsProvider.js";
export const FORMATION_TYPE = {
  VR: "vr",
  EL: "elearning",
};

export default class FormationCollection extends Collection {
  constructor(formations) {
    super(formations);
    this.setFetcher(FormationsProvider.getFormations.bind(FormationsProvider)); //set default api provider
  }

  /**
   * Filter only usable formations
   * @returns {FormationCollection}
   */
  get usable() {
    return this.filter((formation) => formation?.usable);
  }

  /**
   * Filter only vr type formations
   * @returns {FormationCollection}
   */
  get vr() {
    return this.filter((f) => f.type == FORMATION_TYPE.VR);
  }

  /**
   * Filter only e-learning type formations
   * @returns {FormationCollection}
   */
  get elearning() {
    return this.filter((f) => (f.type = FORMATION_TYPE.EL));
  }

    /**
     * Get all packs from the formation collection (unique)
     * @returns {PackCollection}
     */
    get packs() {
        const packs = new Collection(this.map((f) => f.pack)).unique('id')
        packs.sort((a, b) => a.order - b.order)
        return new PackCollection(packs)
    }

  /**
   * filter the formations collection by the licenses given
   * @returns {Array|LicenceCollection}
   */
  filterByLicenses(licenses = []) {
    const uniqueFormationsMap = licenses.reduce((acc, license) => {
      license.formations.forEach((formation) => {
        acc.set(formation.id, formation);
      });
      return acc;
    }, new Map());
    return [...uniqueFormationsMap.values()];
  }

  /**
   * filter the formatin collection by the packs given
   * @param {Array|PackCollection} packs
   */
  filterByPack(packs = []) {
    return this.filter((formation) =>
      packs.some((p) => p.id == formation.pack.id)
    );
  }
}
