<script>
    import ApplicationLayout from '@layouts/global/ApplicationLayout.vue'
    import App from '@/App.vue'
    import LoginLayout from '@layouts/global/LoginLayout.vue'
    import GlobalLanguageSelector from '@components/global/Languages/GlobalLanguageSelector.vue'
    import { mapActions, mapState } from 'pinia'
    import { useGlobalEnv } from '@store/globalEnv.js'
    import ApiClient from '@api'
    import { LoginResponseType, useUserStore } from "@store/user.js";

    export default {
        name: 'ValidateAccount',
        components: {
            GlobalLanguageSelector,
            LoginLayout,
            App,
            ApplicationLayout,
        },
        data() {
            return {
                password_confirmation: '',
                password: '',
                general_condition: false,
                errors: {},
            }
        },
        computed: {
            ...mapState(useGlobalEnv, ['isEl']),
            email() {
                return this.$route.query.email
            },
        },
        methods: {
            ...mapActions(useUserStore, ['handleLoginResponse']),
            async handleSubmit() {
                if (!this.general_condition) {
                    this.errors.general_condition = [
                        this.$t('users.properties.cgu.error'),
                    ]
                    return
                }
                const api = new ApiClient()
                api.baseURL = null

                const fullUrl = atob(
                    this.$route.query.change_password_url
                ).replace(api._baseURL, '')
                const requestData = {
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                }
                try {
                    const response = await fetch(
                        import.meta.env.VITE_API_URL + fullUrl,
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Accept: 'application/json',
                            },
                            body: JSON.stringify(requestData),
                        }
                    )
                    const payload = await response.json()
                    if (response.status == 422) {
                        this.errors = payload.errors
                    } else {
                        const loggedResponseType = await this.handleLoginResponse(payload)
                        if (loggedResponseType == LoginResponseType.LOGGED) {
                            this.$router.push('/')
                        }
                    }
                } catch (e) {
                    console.error(e)
                }
            },
        },
    }
</script>

<template>
    <login-layout>
        <ra-stack
            alignement="center"
            distribution="side-spacing"
            fill-height
            padding="px-0 py-14"
        >
            <div class="text-center">
                <img
                    alt="logo"
                    src="@assets/images/logos/logo_full.png"
                />
                <ra-typo variant="subtitle">
                    <span v-html="$t('global.auth.validate_title')"></span>
                </ra-typo>
            </div>

            <ra-stack tag="form">
                <ra-input
                    :label="$t('users.properties.email.label')"
                    name="email"
                    :errors="errors"
                >
                    <v-text-field
                        :value="email"
                        :placeholder="$t('users.properties.email.placeholder')"
                        type="email"
                        disabled
                        required
                        variant="outlined"
                    />
                </ra-input>
                <ra-input
                    :label="$t('users.properties.password.label')"
                    name="password"
                    :errors="errors"
                >
                    <ra-password
                        v-model="password"
                        :placeholder="
                            $t('users.properties.password.placeholder')
                        "
                        required
                        variant="outlined"
                    />
                </ra-input>
                <ra-input
                    :label="$t('users.properties.password_confirmation.label')"
                    name="password"
                    :errors="errors"
                >
                    <ra-password
                        v-model="password_confirmation"
                        :placeholder="
                            $t(
                                'users.properties.password_confirmation.placeholder'
                            )
                        "
                        required
                        variant="outlined"
                    />
                </ra-input>
                <ra-input
                    class="cgu-cgv-checkbox"
                    :errors="errors"
                    name="general_condition"
                >
                    <v-checkbox v-model="general_condition">
                        <template v-slot:label>
                            <i18n-t
                                keypath="users.properties.cgu.label"
                                tag="div"
                            >
                                <template v-slot:cgu>
                                    <a
                                        target="_blank"
                                        :href="
                                            '/' +
                                            (isEl
                                                ? 'Reality Academy_CGV_E-learning.pdf'
                                                : 'Reality_Academy_CGU.pdf')
                                        "
                                        @click.stop
                                        v-text="
                                            $t('users.properties.cgu.cgu_long')
                                        "
                                    ></a>
                                </template>
                                <template v-slot:cgv>
                                    <a
                                        target="_blank"
                                        href="/CGV_RA_Utilisation.pdf"
                                        @click.stop
                                        v-text="
                                            $t('users.properties.cgu.cgv_long')
                                        "
                                    ></a>
                                </template>
                            </i18n-t>
                        </template>
                    </v-checkbox>
                </ra-input>
                <v-btn
                    @click="handleSubmit"
                    color="primary-gradient"
                    variant="flat"
                >
                    {{ $t('global.auth.validate_account') }}
                </v-btn>
            </ra-stack>
        </ra-stack>
    </login-layout>
</template>

<style scoped lang="scss">
    .cgu-cgv-checkbox {
        a {
            text-decoration: underline;
        }
    }
</style>
