import ApiClient from '@api'

export class HeadsetProvider {
    static api = ApiClient.getInstance()
    static route = (id = null) => (id ? '/devices' : `/devices/${id}`)

    static companyRoute(companyId, id = null) {
        if (!id) return `/companies/${companyId}/devices`
        return `/companies/${companyId}/devices/${id}`
    }

    static fetchAll(companyId, query = null) {
        return this.api.get(this.companyRoute(companyId), query)
    }

    static fetchOne(id) {
        return this.api.get(this.route(id))
    }

    static create(headsetPayload) {
        return this.api.post(this.route(), headsetPayload)
    }

    static update(companyId, id, headsetPayload) {
        return this.api.put(this.companyRoute(companyId, id), headsetPayload)
    }

    static updateMass(companyId, devicesIds, headsetPayload) {
        return this.api.put(this.companyRoute(companyId) + '/mass-update', {
            serial_numbers: devicesIds,
            ...headsetPayload,
        })
    }

    static detach(companyId, id) {
        return this.api.put(this.companyRoute(companyId, id) + "/detach")
    }
}
