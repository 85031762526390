<script>
    import { useUserStore, LoginResponseType } from '@store/user'
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import { mapActions, mapState } from 'pinia'
    import { useAppStore } from '@store/app.js'
    import LangSelector from '@components/global/Languages/LangSelector.vue'
    import GlobalLanguageSelector from '@components/global/Languages/GlobalLanguageSelector.vue'
    import LoginLayout from '@layouts/global/LoginLayout.vue'
    export default {
        components: { LoginLayout, LangSelector, GlobalLanguageSelector },
        mixins: [mixins.RaAlertMixin],
        data() {
            let params = new URLSearchParams(window.location.search)
            return {
                displayForm: !window.IS_TABLET || params.has('login'),
                email: '',
                customer_email: '',
                password: '',
                errors: {},
                loading: false,
                isPresta: false,
            }
        },
        computed: {
            ...mapState(useAppStore, ['hasInternet']),
            ...mapState(useUserStore, ['isAuth']),
            routeToRedirect() {
                return '/' //or the next query params
            },
        },
        created() {
            if (window.$MDM) window.$MDM.stop()
        },
        mounted() {
            if (this.$route.query.error) {
                this.alert(this.$route.query.error, 'error')
            }
        },
        methods: {
            ...mapActions(useUserStore, ['login', 'logout']),
            async handleLogin() {
                this.loading = true

                const loggedResponseType = await this.login(
                    this.email,
                    this.password,
                    this.isPresta ? this.customer_email : null,
                    this
                )

                if (loggedResponseType == LoginResponseType.PRESTA_MIDDLEWARE) {
                    this.isPresta = true
                } else {
                    this.isPresta = false
                    if (loggedResponseType == LoginResponseType.LOGGED) {
                        this.$gtag.event('login', { method: 'web_login' })
                        this.$router.push(this.routeToRedirect)
                    }
                }

                this.loading = false
            },
            offlineLogin() {
                localStorage.setItem('keepOffline', true)
                this.logout(false)
                this.$router.push(this.routeToRedirect + '?offline=true')
            },
        },
        watch: {
            displayForm() {
                this.$nextTick(() => {
                    //wait the render with the new displayForm
                    this.getAlertRef()
                })
            },
        },
    }
</script>
<template>
    <login-layout>
        <ra-stack
            alignement="center"
            distribution="side-spacing"
            fill-height
            padding="px-0 py-14"
        >
            <div class="text-center">
                <web>
                    <img
                        alt="logo"
                        src="@assets/images/logos/logo_full.png"
                    />
                </web>
                <tablet>
                    <img
                        alt="logo"
                        src="@assets/images/logos/ra_link_logo_full.png"
                    />
                </tablet>
                <ra-typo variant="subtitle">
                    <web>
                        <span v-html="$t('global.auth.title.ra')"></span>
                    </web>
                    <tablet>
                        <span v-html="$t('global.auth.title.link')"></span>
                    </tablet>
                </ra-typo>
            </div>
            <ra-spacer />
            <tablet v-if="!displayForm">
                <ra-stack
                    gap="6"
                    tag="form"
                >
                    <template v-if="hasInternet">
                        <ra-btn
                            bg="primary-gradient"
                            bold
                            margin=",6"
                            @click="displayForm = true"
                        >
                            {{ $t('global.auth.login') }}
                        </ra-btn>
                        <hr />
                    </template>

                    <ra-btn
                        bg="primary-lighten-5"
                        bold
                        margin=",6"
                        @click="offlineLogin"
                    >
                        {{ $t('global.auth.continue_offline') }}
                    </ra-btn>
                </ra-stack>
            </tablet>
            <ra-stack
                v-else
                tag="form"
                @submit.prevent="handleLogin"
            >
                <ra-input
                    :errors="errors"
                    :label="$t('users.properties.email.label')"
                    name="email"
                >
                    <v-text-field
                        v-model="email"
                        :placeholder="$t('users.properties.email.placeholder')"
                        type="email"
                        variant="outlined"
                        data-test="email"
                    />
                </ra-input>
                <ra-input
                    v-if="isPresta"
                    :errors="errors"
                    :label="$t('users.properties.customer_email.label')"
                    name="email"
                >
                    <v-text-field
                        v-model="customer_email"
                        :placeholder="
                            $t('users.properties.customer_email.placeholder')
                        "
                        type="email"
                        variant="outlined"
                        data-test="email"
                    />
                </ra-input>
                <ra-input
                    :errors="errors"
                    :label="$t('users.properties.password.label')"
                    name="password"
                >
                    <ra-password
                        v-model="password"
                        :placeholder="
                            $t('users.properties.password.placeholder')
                        "
                        icon-show="fa-eye"
                        icon-hide="fa-eye-hide"
                        data-test="password"
                    />
                </ra-input>
                <ra-btn
                    :loading="loading"
                    bg="primary-gradient"
                    bold
                    type="submit"
                >
                    {{ $t('global.auth.login') }}
                </ra-btn>
                <ra-alert
                    ref="alert"
                    :closable="false"
                    variant="outline"
                />
                <ra-typo
                    tag="a"
                    variant="link"
                >
                    <router-link
                        to="/forgot-password"
                        data-test="forgot-password-route"
                    >
                        {{ $t('global.auth.forgot_password') }}
                    </router-link>
                </ra-typo>
                <ra-spacer />
                <tablet>
                    <div>
                        <ra-btn
                            bg="primary-lighten-5"
                            prepend-icon="mdi-arrow-left"
                            @click="displayForm = false"
                        >
                            {{ $t('generics.go_back') }}
                        </ra-btn>
                    </div>
                </tablet>
                <global-language-selector
                    :dark="true"
                    :isInline="true"
                    :noEdit="true"
                />
            </ra-stack>
        </ra-stack>
    </login-layout>
</template>
<style lang="scss">
    .login-container {
        /* background-color: $surface-darken-1; */
        .ra-box:first-child {
            img {
                width: 400px;
            }

            form {
                max-width: 350px;
            }
        }

        .ra-box:last-child {
            overflow-y: hidden;

            img {
                width: 100%;
                height: 100vh;
                object-fit: cover;
                object-position: 10%;
            }

            @media screen and (max-width: 768px) {
                display: none;
            }
        }
    }
</style>
