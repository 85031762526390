import ApiClient from '@api'

export class WorkshopsProvider {
    static api = ApiClient.getInstance()

    static route = '/workshops'
    static getWorkshops(query = {}) {
        return this.api.get(`${this.route}`, query)
    }
    static getWorkshop(id, invited = false) {
        return this.api.get(`${this.route}/${id}${invited ? '/details' : ''}`)
    }
    static getWorkshopStats(id, query = {}) {
        return this.api.get(`${this.route}/${id}/stats`, query)
    }
    static createWorkshop(payload, query = {}) {
        return this.api.post(`${this.route}`, payload, query)
    }
    static updateWorkshop(payload, query = {}) {
        return this.api.put(`${this.route}/${payload.id}`, payload, query)
    }
    static deleteWorkshop(id, query = {}) {
        return this.api.delete(`${this.route}/${id}`, query)
    }
    static massDeleteWorkshops(ids) {
        return this.api.post(`${this.route}/delete`, ids)
    }
    static addApprenantWorkshop(id, slot_id, query = {}) {
        return this.api.post(
            `${this.route}/${id}/slot/${slot_id}/apprenant`,
            query
        )
    }
    static removeApprenantWorkshop(id, slot_id, apprenant_id) {
        return this.api.delete(
            `${this.route}/${id}/slot/${slot_id}/apprenant/${apprenant_id}`
        )
    }
    static switchWorkshop(id) {
        return this.api.post(`${this.route}/${id}/switch`)
    }
    static getWorkshopSurvey(id) {
        return this.api.get(`${this.route}/${id}/survey`)
    }
    static getWorkshopSurveyResult(id) {
        return this.api.get(`${this.route}/${id}/survey/results`)
    }
    static submitWorkshopSurvey(id, payload) {
        return this.api.post(`${this.route}/${id}/survey`, payload)
    }
    static sendShareEmail(payload) {
        return this.api.post(`${this.route}/${payload.id}/share`, payload)
    }
    static duplicateWorkshop(id) {
        return this.api.post(`${this.route}/${id}/duplicate`)
    }
}
