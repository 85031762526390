<template>
  <ra-input :errors="error" :label="label" name="emails">
    <div ref="container" class="chip-input-container">
      <div class="chip-list">
        <v-chip
          v-for="(chip, key) in chips"
          :key="key"
          close
          close-icon="mdi-close"
          @click:close="deleteChip(key, true)"
        >
          {{ chip }}
        </v-chip>
      </div>

      <div class="chip-input">
        <input
          ref="input"
          v-model="content"
          :placeholder="placeholder"
          :type="type"
          style="margin-bottom: 0px; height: 56px"
          @keydown.space="saveChip"
          @keydown.enter="saveChip"
          @keydown.tab="saveChip"
          @keydown.delete="deleteChip()"
        />
      </div>
    </div>
  </ra-input>
</template>
<script>
export default {
  props: ["placeholder", "type", "label"],
  data() {
    return {
      chips: [],
      content: "",
      error: null,
    };
  },
  mounted() {
    this.$refs.container.scrollLeft = this.$refs.container.scrollWidth;
  },
  updated() {
    this.$refs.container.scrollLeft = this.$refs.container.scrollWidth;
  },
  methods: {
    setErrors(errorMessage) {
      this.error = {
        emails: [errorMessage],
      };
    },
    saveChip(e = null) {
      if (!Boolean(this.$refs.input?.value))
        return this.setErrors(this.$t("generics.input_required"));
      let isValid = this.$refs.input.checkValidity();
      if (!isValid) return this.displayError(e);
      this.error = null;
      this.chips = [...this.chips, this.content];
      this.content = "";
      return true;
    },
    displayError(e = null) {
      if (e) e?.preventDefault();
      this.content = this.content.trim();
      this.setErrors(this.$refs.input.validationMessage.split(".")[0]);
      return false;
    },
    deleteChip(key = this.chips.length - 1, ignoreContent = false) {
      if (this.content.length && !ignoreContent) return;
      this.chips.splice(key, 1);
      return;
    },

    validate() {
      if (this.content === "") return this.chips;
      let valid = this.saveChip();
      return valid ? this.chips : false;
    },

    clear() {
      this.chips = [];
      this.content = "";
    },
  },
  watch: {
    chips(newChips) {
      this.$emit("chips:update", newChips);
    },
  },
};
</script>
<style lang="css">
.chip-input-container {
  display: flex;
  border: 2px solid #dfdbed;
  border-radius: 5px;
  min-height: 56px;
  align-items: center;
  padding: 0 12px;
  box-sizing: border-box;
  overflow-x: auto;
}
.chip-list {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.chip-input {
  width: 100%;
  min-width: 100px;
}
.chip-list > span {
  background: rgba(9, 9, 84, 0.1) !important;
  color: #090954 !important;
  margin: 0 2px;
}
.chip-input input {
  border: none !important;
  outline: none;
  padding: 0px;
  width: 100% !important;
  padding: 5px !important;
  height: 100%;
  margin-bottom: 0px !important;
}
</style>
