import { useLanguageStore } from '@store/lang.js'

export default {
    methods: {
        getTranslation(string) {
            const store = useLanguageStore()
            const locale = store.currentLocale
            return string[locale] || string.fr || ''
        },
    },
}
