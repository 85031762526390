<template>
    <ra-stack
        alignement="center"
        padding="10,0"
        height="90vh"
    >
        <div class="imagenotfoud" />
        <ra-typo
            h1
            padding="10"
            >Page {{ $t('generics.not_found') }}</ra-typo
        >
    </ra-stack>
</template>
<script>
    export default {
        name: 'Page404',
    }
</script>
<style lang="scss" scoped>
    .imagenotfoud {
        background: url('/images/robot404.webp') no-repeat center;
        background-size: cover;
        box-shadow: 0 0 28px 28px #ececf7 inset;
        width: 30vw;
        height: 80vh;
    }
</style>
