<script>
    import { mapState } from 'pinia'
    import { useFormationStore } from '@store/formationStore.js'
    import LicensesTypeChip from '@components/Licenses/LicensesTypeChip.vue'
    import Loader from '@components/tools/Loader.vue'
    import {
        LICENSE_TYPE,
        LicenseCollection,
    } from '@libs/Collections/LicenseCollection.js'
    import { useGlobalEnv } from '@store/globalEnv.js'
    import { useLanguageStore } from '@store/lang.js'
    import { Bag } from '@utils/Bag.js'
    export default {
        name: 'LicenseFormRow',
        components: { Loader, LicensesTypeChip },
        inject: ['getCompany'],
        data() {
            return {
                formations: null,
                formationsEnv: null,
                showList: false,
            }
        },
        props: {
            license: {
                type: Object,
                default: () => {},
            },
            licensesAssigned: {
                type: LicenseCollection,
                required: true,
            },
            editable: {
                type: Boolean,
                default: false,
            },
            errors: {
                type: Object,
                default: () => {},
            },
        },
        mounted() {
            this.init()
        },
        methods: {
            async init() {
                const formationStore = useFormationStore()
                const globalEnvStore = useGlobalEnv()
                this.formations = await formationStore.getFormations()
                this.formationsEnv = await formationStore.getFormations(
                    globalEnvStore.globalEnv
                )
            },
            toggleShowList() {
                this.showList = !this.showList
            },
        },
        computed: {
            ...mapState(useLanguageStore, ['currentLocale']),
            ...mapState(useGlobalEnv, ['isEl']),
            formationsAssignable() {
                const formationsAlreadyAssigned =
                    this.licensesAssigned.fullVersion.formations
                const formationAssignables = this.formationsEnv.excludeBy(
                    formationsAlreadyAssigned,
                    'id'
                )

                const company = this.getCompany()
                return formationAssignables.filter((f) => {
                    const formationLanguage = new Bag(f.languages).get(
                        this.currentLocale
                    )
                    if (!formationLanguage) return false
                    return company?.is_beta_company
                        ? formationLanguage.published ||
                              formationLanguage.published_beta
                        : formationLanguage.published
                })
            },
            LICENSE_TYPE() {
                return LICENSE_TYPE
            },
            value: {
                get() {
                    return this.license
                },
                set() {
                    this.$emit('update:license', value)
                },
            },
            types() {
                return [
                    {
                        value: 3,
                        name: this.$t(
                            'licenses.properties.type.type_value.demo'
                        ),
                    },
                    {
                        value: 5,
                        name: this.$t(
                            'licenses.properties.type.type_value.unlimited_use'
                        ),
                    },
                    {
                        value: 6,
                        name: this.$t(
                            'licenses.properties.type.type_value.limited_use'
                        ),
                    },
                    {
                        visible: this.isEl,
                        value: 7,
                        name: this.$t(
                            'licenses.properties.type.type_value.unlimited_learners'
                        ),
                    },
                    {
                        visible: this.isEl,
                        value: 8,
                        name: this.$t(
                            'licenses.properties.type.type_value.limited_learners'
                        ),
                    },
                ].filter((type) => type.visible !== false)
            },
            formationsText() {
                return {
                    title:
                        this.license?.formation_ids.length > 0
                            ? this.formations?.findById(
                                  this.license?.formation_ids[0] ?? 0
                              )?.name + '...'
                            : this.$t('licenses.no_formation_selected'),
                    list:
                        this.license?.formation_ids.length > 1
                            ? this.license?.formation_ids.map(
                                  (id) => this.formations?.findById(id)?.name
                              )
                            : null,
                }
            },
        },
    }
</script>
<template>
    <ra-stack gap="8">
        <!--  Editable false -> display recap  -->
        <ra-box
            v-if="!editable"
            v-ripple="{ class: `text-primary` }"
            :style="{ background: 'rgba(9, 9, 84, 0.06)' }"
            padding="4"
        >
            <ra-stack
                direction="horizontal"
                distribution="fill"
                gap="1"
            >
                <div>
                    <ra-typo>
                        <ra-typo
                            semi
                            span
                            >Formations : {{ ' ' }}
                        </ra-typo>

                        <v-expansion-panels
                            flat
                            class="inlined"
                            :disabled="!formationsText.list"
                            variant="inset"
                            v-if="!editable"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-title>
                                    <ra-typo span>
                                        {{ formationsText.title }}
                                    </ra-typo>
                                </v-expansion-panel-title>
                                <v-expansion-panel-text>
                                    <ul>
                                        <li
                                            v-for="formationName in formationsText.list"
                                            :key="formationName"
                                            >{{ formationName }}
                                        </li>
                                    </ul>
                                </v-expansion-panel-text>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <ra-typo
                            span
                            v-else
                        >
                            {{ formationsText.title }}
                        </ra-typo>
                    </ra-typo>
                    <ra-typo>
                        <ra-typo
                            semi
                            span
                        >
                            Type de license : {{ ' ' }}
                        </ra-typo>
                        <licenses-type-chip :license="license" />
                    </ra-typo>
                    <ra-typo>
                        <ra-typo
                            semi
                            span
                        >
                            Utilisations / Apprenants : {{ ' ' }}
                        </ra-typo>
                        <span v-if="[5, 7].includes(license.type)">
                            Illimitées du {{ license.start_date }} au
                            {{ license.expire_date }}
                        </span>
                        <span v-else>{{ license.nb_credits }}</span>
                    </ra-typo>
                </div>
                <v-btn
                    icon="mdi-delete"
                    size="x-small"
                    variant="plain"
                    @click.stop="$emit('delete')"
                ></v-btn>
            </ra-stack>
        </ra-box>
        <!--  Editable true -> display form  -->
        <ra-box v-else>
            <ra-stack
                v-if="formationsEnv"
                gap="4"
            >
                <ra-input
                    :errors="errors"
                    :label="$tc('formations._name', 2) + ' *'"
                    name="formation_id"
                >
                    <ra-tree-formations-select
                        v-model="value.formation_ids"
                        :allText="$t('licenses.properties.formations.all')"
                        :items="formationsAssignable"
                        :label="
                            $t('licenses.properties.formations.select_label')
                        "
                        density="compact"
                        multiple
                    />
                </ra-input>
                <ra-input
                    :errors="errors"
                    :label="$t('licenses.properties.type.label') + ' *'"
                    name="type"
                >
                    <v-select
                        v-model="value.type"
                        :items="types"
                        item-title="name"
                        item-value="value"
                        variant="outlined"
                    />
                </ra-input>
                <ra-stack
                    v-if="
                        [
                            LICENSE_TYPE.UNLIMITED_LEARNER,
                            LICENSE_TYPE.UNLIMITED_USAGE,
                        ].includes(value.type)
                    "
                    direction="horizontal"
                    distribution="fill-equaly"
                >
                    <ra-input
                        :errors="errors"
                        :label="$t('licenses.properties.start_date.label')"
                        name="start_date"
                    >
                        <v-text-field
                            v-model="value.start_date"
                            :placeholder="
                                $t('licenses.properties.start_date.label') +
                                ' *'
                            "
                            min="1"
                            type="date"
                            variant="outlined"
                        />
                    </ra-input>
                    <ra-input
                        :errors="errors"
                        :label="$t('licenses.properties.end_date.label')"
                        name="expire_date"
                    >
                        <v-text-field
                            v-model="value.expire_date"
                            :placeholder="
                                $t('licenses.properties.end_date.label') + ' *'
                            "
                            min="1"
                            type="date"
                            variant="outlined"
                        />
                    </ra-input>
                </ra-stack>
                <ra-input
                    v-else
                    :errors="errors"
                    :label="
                        [
                            LICENSE_TYPE.DEMO,
                            LICENSE_TYPE.LIMITED_USAGE,
                        ].includes(value.type)
                            ? $t('licenses.properties.credit_count.label') +
                              ' *'
                            : $t('licenses.properties.learner_count.label') +
                              ' *'
                    "
                    name="nb_credits"
                >
                    <v-text-field
                        v-model="value.nb_credits"
                        :placeholder="
                            [
                                LICENSE_TYPE.DEMO,
                                LICENSE_TYPE.LIMITED_USAGE,
                            ].includes(license.type)
                                ? $t(
                                      'licenses.properties.credit_count.placeholder'
                                  )
                                : $t(
                                      'licenses.properties.learner_count.placeholder'
                                  )
                        "
                        min="1"
                        type="number"
                        variant="outlined"
                    />
                </ra-input>
            </ra-stack>
        </ra-box>
        <hr />
    </ra-stack>
</template>

<style lang="scss">
    .inlined {
        display: inline-block;
        width: auto;
        .v-expansion-panel--disabled {
            .v-expansion-panel-title {
                color: inherit;
                .v-expansion-panel-title__overlay {
                    background: transparent;
                }
                .v-expansion-panel-title__icon {
                    display: none;
                }
            }
        }
        .v-expansion-panel {
            background: transparent;
        }
    }
</style>
