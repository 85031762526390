<script>
    import { Headset } from '@libs/MDM/core/Headset.js'
    import { translateRecursively } from '@utils/translateRecursively.js'

    export default {
        name: 'HeadsetFormations',
        props: {
            headset: {
                type: Headset,
                default: null,
            },
        },

        computed: {
            formations() {
                return translateRecursively(
                    this.headset.state.formationsReadyToStart
                )
            },
        },
    }
</script>

<template>
    <ra-box class="headset-formation-grid">
        <ra-box
            v-for="formation in formations"
            bg="white"
            padding="4"
            rounded
        >
            <ra-stack
                direction="horizontal"
                distribution="fill"
                gap="5"
            >
                <img :src="formation?.image?.url" />
                <ra-box>
                    <ra-stack gap="4">
                        <ra-typo
                            semi
                            variant="subtitle"
                        >
                            {{ formation.name }}
                        </ra-typo>
                        <ra-stack
                            direction="horizontal"
                            wrap
                        >
                            <ra-typo variant="x-small">
                                <ra-typo
                                    semi
                                    span
                                    >Version installée :</ra-typo
                                >
                                2.1
                            </ra-typo>
                            <ra-typo variant="x-small">
                                <ra-typo
                                    semi
                                    span
                                    >Taille :</ra-typo
                                >
                                450Mo
                            </ra-typo>
                            <ra-typo variant="x-small">
                                <ra-typo
                                    semi
                                    span
                                    >Dernière mise à jour :</ra-typo
                                >
                                20/12/2022
                            </ra-typo>
                        </ra-stack>

                        <v-btn
                            size="small"
                            variant="outlined"
                            @click="$emit('start-formation', formation)"
                        >
                            Lancer cette formation
                            <v-icon class="ml-2"> $headset-stream</v-icon>
                        </v-btn>
                    </ra-stack>
                </ra-box>
            </ra-stack>
        </ra-box>
    </ra-box>
</template>

<style lang="scss" scoped>
    .headset-formation-grid {
        max-width: 800px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
        grid-gap: 30px;

        img {
            width: 70px;
            height: 70px;
            aspect-ratio: 1/1;
            object-fit: cover;
            border-radius: 5px;
        }
    }
</style>
