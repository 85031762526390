import { formatDate } from '@utils/formatDate.js'
import { formatTime } from '@utils/formatTime.js'

export function calendarLink(
    name,
    date,
    start_time,
    end_time,
    description = '',
    address = '',
    city = '',
    state = ''
) {
    // Générer le lien vers Google Calendar avec la date spécifiée
    formatDate
    let dateFiltered = formatDate(date, 'YYYYMMDD')

    let starttimeFiltered = formatTime(start_time, 'HHmmss')

    let endtimeFiltered = formatTime(end_time, 'HHmmss')

    const eventDetails = {
        text: name,
        dates: `${dateFiltered}T${starttimeFiltered}/${dateFiltered}T${endtimeFiltered}`, // Format YYYY-MM-DD/YYYY-MM-DD pour une journée entière
        details: description || '',
        location: address ? `${address} ${city} ${state}`.trim() : '',
    }
    const encodedEvent = Object.entries(eventDetails)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join('&')

    return `https://www.google.com/calendar/render?action=TEMPLATE&${encodedEvent}`
}
