import { defineStore } from 'pinia'

export const useGlobalLoading = defineStore('globalLoading', {
    state: () => ({
        loading: false,
    }),
    getters: {
        isPercentage() {
            return typeof this.loading === 'number'
        },
    },
    actions: {
        setLoading(boolOrPercentValue) {
            this.loading = boolOrPercentValue
        },
    },
})
