import { mapState } from 'pinia'
import { useUserStore } from '@store/user.js'

export default {
    props: {
        targetedUser: {
            type: Object,
            default: null,
        },
    },
    computed: {
        ...mapState(useUserStore, {
            _user: 'user',
            _apprenant: 'apprenant',
        }),

        user() {
            const authUser = this._user || this._apprenant
            if (this._apprenant || !['god'].includes(this._user?.role?.name))
                return authUser
            return this.targetedUser || authUser
        },

        userID() {
            return this.user?.id
        },

        me() {
            return this._user || this._apprenant
        },
    },
}
