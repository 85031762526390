import { Structure } from '@libs/Structures/Structure.js'

export const AUTH_TYPE = {
    LEARNER: 'learner',
    USER: 'user',
}
export class UserStructure extends Structure {
    constructor(...args) {
        super(...args)
    }

    get company() {
        //if learner get company through user former referer property
        return this._company ? this._company : this.user?.company
    }

    set company(value) {
        this._company = value
    }

    get _type() {
        return this.role?.name === AUTH_TYPE.LEARNER
            ? AUTH_TYPE.LEARNER
            : AUTH_TYPE.USER
    }
    get isApprenant() {
        return this._type == AUTH_TYPE.LEARNER
    }
    get isUser() {
        return this._type == AUTH_TYPE.USER
    }

    get isGod() {
        return this.hasRole(['god', 'super_god'])
    }

    hasRole(roles = []) {
        if (this.isUser) {
            if (typeof roles == 'string') roles = [roles]
            return roles.includes(this?.role?.name)
        }
        return false
    }
}
