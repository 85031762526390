<script>
export default {
  name: "WifiIcon",
  props: {
    signalIntensity: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    signalCount() {
      if (this.signalIntensity == 0) return -1;
      return this.signalIntensity < 25 ? 0 : this.signalIntensity >= 75 ? 2 : 1;
    },
  },
};
</script>

<template>
  <svg v-bind="$attrs" viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg">
    <path
      :fill="'rgba(0, 0, 0, ' + (signalCount >= 2 ? '1' : '0.5') + ')'"
      d="M 54.25 200.121 C 123.25 133.921 216.85 93.221 320.05 93.221 C 423.25 93.221 516.85 133.921 585.85 200.121 C 598.65 212.321 618.85 211.921 631.05 199.221 C 643.25 186.521 642.85 166.221 630.15 154.021 C 549.75 76.721 440.45 29.221 320.05 29.221 C 199.65 29.221 90.35 76.721 9.85 153.921 C -2.85 166.221 -3.25 186.421 8.95 199.221 C 21.15 212.021 41.45 212.421 54.15 200.121 L 54.25 200.121 Z"
      transform="matrix(1, 0, 0, 1, 0, 5.684341886080802e-14)"
    />
    <path
      :fill="'rgba(0, 0, 0, ' + (signalCount >= 1 ? '1' : '0.5') + ')'"
      d="M 313.011 257.611 C 256.131 257.623 204.349 278.721 164.861 313.611 C 151.561 325.311 131.361 324.111 119.661 310.811 C 107.961 297.511 109.161 277.311 122.461 265.611 C 173.161 220.811 239.961 193.611 312.961 193.611 C 385.961 193.611 452.761 220.811 503.561 265.611 C 516.761 277.311 518.061 297.611 506.361 310.811 C 494.661 324.011 474.461 325.311 461.161 313.611 C 421.573 278.721 369.791 257.623 313.011 257.611 Z"
      style="stroke: rgba(0, 0, 0, 0)"
      transform="matrix(1, 0, 0, 1, 0, 5.684341886080802e-14)"
    />
    <path
      d="M 354.094 469.625 C 311.427 494.258 258.094 463.466 258.094 414.199 C 258.094 391.334 270.292 370.206 290.094 358.773 C 332.76 334.14 386.094 364.932 386.094 414.199 C 386.094 437.064 373.895 458.192 354.094 469.625 Z"
      fill="black"
      transform="matrix(1, 0, 0, 1, 0, 5.684341886080802e-14)"
    />
    <path
      v-if="signalCount == -1"
      d="M 554.931 227.185 L 554.931 284.816 L 348.816 284.816 L 348.816 490.931 L 291.184 490.931 L 291.184 284.816 L 85.069 284.816 L 85.069 227.185 L 291.184 227.185 L 291.184 21.069 L 348.816 21.069 L 348.816 227.185 L 554.931 227.185 Z"
      fill="black"
      stroke="black"
      style="
        stroke-dashoffset: 1px;
        stroke-miterlimit: 19.43;
        stroke-linecap: square;
        stroke-linejoin: round;
        stroke-width: 8px;
        transform-origin: 320px 256px;
      "
      transform="matrix(0.707107007504, 0.707107007504, -0.707107007504, 0.707107007504, -0.000003814697, -0.000003814697)"
    />
  </svg>
</template>
