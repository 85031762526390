<script>
    import ApiClient from '@api'
    import AvatarChip from '@ui/AvatarChip.vue'

    const COOLDOWN_TYPE = 300
    export default {
        name: 'SearchBar',
        components: { AvatarChip },
        data() {
            return {
                searchText: '',
                cooldown: null,
                active: false,
                loading: false,
                results: [],
            }
        },
        computed: {
            resultsGrouped() {
                let grouped = {}
                for (let item of this.results) {
                    let type = item.type
                    if (type.startsWith('Formation')) type = 'Formation'
                    if (grouped[type] == null) grouped[type] = []
                    grouped[type].push(item)
                }

                let results = []
                for (let key in grouped) {
                    results.push({
                        type: key,
                        items: grouped[key].splice(0, 5),
                    })
                }
                return results
            },
        },
        methods: {
            goToUrl(item) {
                window.location.href = item.url
                this.active = false
            },
            async searchResults() {
                this.loading = true
                if (this.searchText == null) this.active = false
                let api = new ApiClient()
                try {
                    let response = await api
                        .get('/search', { query: this.searchText })
                        .call()
                    this.results = response.data
                } catch (e) {
                    console.error(e)
                } finally {
                    this.active = true
                    this.loading = false
                }
            },
            handleSearch() {
                const searchBar = document.getElementById('searchBar')
                searchBar.focus()
            },
        },
        watch: {
            searchText(newValue) {
                if (this.cooldown != null) clearTimeout(this.cooldown)
                this.cooldown = setTimeout(() => {
                    this.searchResults(newValue)
                }, COOLDOWN_TYPE)
            },
        },
    }
</script>

<template>
    <v-menu
        v-model="active"
        max-height="600"
    >
        <template v-slot:activator="{ props }">
            <ra-input
                name="search"
                class="search-input-container"
            >
                <v-text-field
                    id="searchBar"
                    v-model="searchText"
                    :loading="loading"
                    density="compact"
                    hide-details
                    placeholder="Rechercher ..."
                    v-bind="props"
                    variant="outlined"
                    @click.stop="() => true"
                ></v-text-field>
                <ra-short-key
                    class="short-key-search"
                    :action="handleSearch"
                    :keys="['ctrl', 'k']"
                />
            </ra-input>
        </template>
        <v-list dense>
            <template v-for="(group, index) in resultsGrouped">
                <div class="search-result-group-items-container">
                    <ra-typo
                        class="search-result-group-title"
                        padding="2, 4"
                        bold
                    >
                        {{ group.type }}s
                    </ra-typo>
                </div>

                <v-list-item
                    v-for="(item, index) in group.items"
                    :key="index"
                    class="search-result-item"
                    @click="goToUrl(item)"
                >
                    <ra-stack
                        alignement="center"
                        direction="horizontal"
                        gap="4"
                    >
                        <div
                            class="search-image"
                            v-if="item.avatar === undefined"
                        >
                            <ra-image
                                :src="item.image"
                                fallback="/images/placeholder.webp"
                                width="50"
                                height="50"
                                object-fit="contain"
                                style="background: #c5c3c4"
                            />
                        </div>
                        <avatar-chip
                            v-else-if="item.firstname"
                            :user="item"
                            size="35"
                        />
                        <div>
                            <ra-typo variant="small">
                                {{ item.text }}
                            </ra-typo>
                            <ra-typo
                                semi
                                variant="small"
                            >
                                {{ item.type }}
                            </ra-typo>
                        </div>
                    </ra-stack>
                </v-list-item>
            </template>
        </v-list>
    </v-menu>
</template>

<style lang="scss" scoped>
    .search-result-item {
        .search-image {
            height: 50px;
            width: 50px;
            img {
                object-fit: contain;
                height: 100%;
                width: 100%;
            }
        }
        line-height: 1.2em !important;
    }
    .search-result-group-title {
        opacity: 0.85;
    }
    .search-result-group-items-container {
        background: #f2f2f8;
    }

    .search-input-container {
        position: relative;
        .short-key-search {
            width: auto;
            position: absolute;
            right: 0;
            top: 6px;
            transform: scale(0.7);
        }
    }
</style>
