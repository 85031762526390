import Stack from '../../../utils/Stack.js'
import { RTCEvent } from './RaPeer.js'

const HANDSHAKE_MESSAGE_TYPE = 'handshake'
export default class RTCConnectionHandShake {
    static sendingMessage = JSON.stringify({
        type: 'handshake',
        message: 'Hello from emitting',
    })
    static receivingMessage = JSON.stringify({
        type: 'handshake',
        message: 'Hello from receiving',
    })
    static receptionAck = JSON.stringify({
        type: 'handshake',
        message: 'ACK from emitting',
    })
    static tries = 10
    static cooldown = 400
    /**
     *
     * @param {RaPeer} peer
     */
    constructor(peer) {
        this.peer = peer
        this.triesMessagesStack = null
    }

    init() {
        this.triesMessagesStack = new Stack(
            new Array(RTCConnectionHandShake.tries).fill(
                this.peer.initiator
                    ? RTCConnectionHandShake.sendingMessage
                    : RTCConnectionHandShake.receivingMessage
            )
        )
    }

    async send() {
        this.init()
        let success = false

        const messageWaitingFor = this.peer.initiator
            ? RTCConnectionHandShake.receivingMessage
            : RTCConnectionHandShake.receptionAck

        const filter = (data) => {
            return data.toString().trim() === messageWaitingFor.trim()
        }
        while (!this.triesMessagesStack.isEmpty) {
            this.peer.send(this.triesMessagesStack.pop()) //send ignoring the connection queue
            this.peer.log(
                'Try sending handshake message to peer (left ' +
                    this.triesMessagesStack.size +
                    ')'
            )
            try {
                success = await this.peer.waitForResponse(
                    RTCEvent.DATA,
                    filter,
                    RTCConnectionHandShake.cooldown
                )
                if (success) break
                else continue
            } catch (e) {
                continue
            }
        }

        return success
    }

    /**
     * wait for the first message from the other peer and reply with the receiving message, then wait for the ack message
     * @returns {Promise<boolean>}
     */
    reply() {
        return new Promise((resolve, reject) => {
            const eventListen = (data) => {
                if (data.toString() === RTCConnectionHandShake.sendingMessage) {
                    this.send().then(resolve)
                    this.peer.off(RTCEvent.DATA, eventListen)
                }
            }
            this.peer.on(RTCEvent.DATA, eventListen)
        })
    }
    ack() {
        this.peer.send(RTCConnectionHandShake.receptionAck)
    }
}
