import { Structure } from '@libs/Structures/Structure.js'
import { minToHoursString } from '@utils/minToHoursString.js'
import i18n from '@core/i18n/index.js'

export class GlobalStatStructure extends Structure {
    get globalTradKey() {
        return 'stats.globals'
    }
    $t(key) {
        return i18n.global.t(key)
    }
    $tc(key, count) {
        return i18n.global.tc(key, count)
    }
    toPercent(value, fallback = '--') {
        if (this.isNull(value)) return fallback
        return parseInt(value) + '%'
    }

    toDuration(sec, fallback = '--') {
        if (this.isNull(sec)) return fallback
        return minToHoursString(sec)
    }

    toInt(value, fallback = 0) {
        if (this.isNull(value)) return fallback
        return parseInt(value)
    }

    toDecimal(value, decimal = 2, fallback = '--') {
        if (this.isNull(value)) return fallback
        return parseFloat(value).toFixed(decimal)
    }
    isNull(value) {
        if (value == null) return true
        if (value == undefined) return true
        if (value == 0) return true
        if (value == '') return true
        return false
    }
}
