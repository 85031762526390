import { MyUsersRoutesNames } from '@routes/Former/MyUsers.name.js'
import {
    adminFormersRoutesMeta as adminMeta,
    formersRoutesMeta as meta,
} from '@routes/Former/meta.js'

export default [
    {
        path: '/my-users',
        component: () => import('@pages/My-Users/MyUsersShowPage.vue'),
        children: [
            {
                path: '',
                name: MyUsersRoutesNames.index,
                redirect: { name: MyUsersRoutesNames.formers },
                meta,
            },
            {
                path: 'formers',
                name: MyUsersRoutesNames.formers,
                meta: adminMeta,
                component: () =>
                    import('@components/Formers/FormerTableList.vue'),
            },
            {
                path: 'learners',
                name: MyUsersRoutesNames.learners,
                component: () =>
                    import('@components/Learners/LearnerTableList.vue'),
                meta,
            },
        ],
    },
]
