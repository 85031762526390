export function JSONParseSelectedProperties(object = {}, properties = []) {
    if (typeof object !== 'object') {
        try {
            object = JSON.parse(object)
            return object
        } catch (e) {
            return object
        }
    }
    const res = {}
    for (const prop in object) {
        let val = object[prop]
        let match = properties.find(
            (property) => property.split('.')[0] === prop
        )
        if (match) {
            let [current, ...remanings] = match.split('.')
            if (Array.isArray(val)) {
                res[prop] = val.map((item) =>
                    JSONParseSelectedProperties(item, remanings)
                )
            } else {
                res[prop] = JSONParseSelectedProperties(val, remanings)
            }
        } else {
            res[prop] = val
        }
    }
    return res
}
