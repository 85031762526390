<script>
    import HeadsetReleaseInstalledCard from '@components/Headsets/Details/Cards/HeadsetReleaseInstalledCard.vue'
    import HeadsetSpecificationTableCard from '@components/Headsets/Details/Cards/HeadsetSpecificationTableCard.vue'

    import { useLanguageStore } from '@store/lang.js'
    import WifiIcon from '@assets/icons/WifiIcon.vue'
    import moment from 'moment'
    import { humanizeBytes } from '@utils/humanizeBytes.js'

    export default {
        name: 'HeadsetInformationDetails',
        components: {
            WifiIcon,
            HeadsetSpecificationTableCard,
            HeadsetReleaseInstalledCard,
        },
        props: {
            headset: {
                type: Object,
                required: true,
            },
        },
        computed: {
            applicationInformations() {
                if (!this.headset.state.applicationMonitoring) return []
                let { lastStart, kioskModeEnabled, language } =
                    this.headset.state.applicationMonitoring
                const languageStore = useLanguageStore()
                return [
                    {
                        label: 'Dernier lancement',
                        value: moment(lastStart).format('L HH:mm'),
                    },
                    {
                        label: 'Mode kiosk',
                        value: kioskModeEnabled ? 'Activé' : 'Désactivé',
                    },
                    {
                        label: 'Langue',
                        value:
                            languageStore.getLocale(language)?.name || language,
                    },
                ]
            },
            headsetInformations() {
                return [
                    {
                        label: 'Version du casque',
                        value: '2.0.1',
                    },
                    {
                        label: 'Version Android',
                        value: '8.1.0',
                    },
                    {
                        label: 'Stokage restant',
                        value: [
                            {
                                formated: humanizeBytes(
                                    this.headset.state.storageUse,
                                    0
                                ),
                                raw: this.headset.state.storageUse,
                            },
                            {
                                formated: humanizeBytes(
                                    this.headset.state.storageSize,
                                    -1
                                ),
                                raw: this.headset.state.storageSize,
                            },
                        ],

                        slot: 'storage',
                    },
                    {
                        label: 'Numéro de série',
                        value: this.headset.serialNumber,
                    },
                ]
            },
            networkInformations() {
                let { name, intensity, ipAddress, macAddress } =
                    this.headset.state.network
                return [
                    {
                        label: 'Nom Réseau',
                        value: name,
                    },
                    {
                        label: 'Intensité du Signal',
                        value: intensity,
                        slot: 'signal',
                    },
                    {
                        label: 'Adresse IP',
                        value: ipAddress,
                    },
                    {
                        label: 'Adresse Mac',
                        value: macAddress,
                    },
                ]
            },
        },
    }
</script>

<template>
    <ra-stack
        class="headset-information-details-container"
        gap="8"
    >
        <ra-stack
            direction="horizontal"
            distribution="fill-equaly"
            gap="8"
            mobile-swap
        >
            <headset-release-installed-card
                v-if="headset.state.releaseInstalled"
                :releaseInstalled="headset.state.releaseInstalled"
            />
            <headset-specification-table-card
                :rows="applicationInformations"
                title="Information Application"
            />
        </ra-stack>
        <ra-stack
            direction="horizontal"
            distribution="fill-equaly"
            gap="8"
            mobile-swap
        >
            <headset-specification-table-card
                :rows="headsetInformations"
                title="Information Casque"
            >
                <template v-slot:storage="{ value, labels }">
                    <ra-stack
                        alignement="center"
                        class="storage-progress-bar"
                        direction="horizontal"
                    >
                        <v-progress-linear
                            :model-value="(value[0].raw / value[1].raw) * 100"
                        />
                        <ra-typo
                            no-word-wrap
                            variant="x-small"
                            >{{ value[0].formated }} / {{ value[1].formated }}
                        </ra-typo>
                    </ra-stack>
                </template>
            </headset-specification-table-card>
            <headset-specification-table-card
                v-if="headset.isConnected"
                :rows="networkInformations"
                title="Information Réseau"
            >
                <template v-slot:signal="{ value }">
                    <wifi-icon
                        :signalIntensity="value"
                        width="20px"
                    />
                </template>
            </headset-specification-table-card>
        </ra-stack>
    </ra-stack>
</template>

<style lang="scss" scoped>
    .headset-information-details-container {
        max-width: 800px;
    }

    .storage-progress-bar {
        flex: 1 0 60%;

        .v-progress-linear {
            height: 8px !important;
            border-radius: 8px;
            left: 0% !important;
            transform: translateX(0) !important;
        }
    }
</style>
