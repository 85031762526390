import { godRoutesMeta as meta } from '@core/router/routes/God/meta.js'
import { ContentRoutesNames } from '@core/router/routes/God/Content.names.js'

export default [
    {
        path: '/content',
        component: () => import('@pages/Content/ContentPage.vue'),
        children: [
            {
                path: 'packs',
                children: [
                    {
                        path: '',
                        name: ContentRoutesNames.packs.index,
                        component: () =>
                            import('@components/Packs/PackTableList.vue'),
                        meta,
                    },
                    {
                        path: ':pack_id',
                        name: ContentRoutesNames.packs.show,
                        meta,
                    },
                    {
                        path: 'create',
                        name: ContentRoutesNames.packs.create,
                        meta,
                    },
                ],
            },
        ],
    },
]
