<script>
    import ChipInput from '@ui/ChipInput.vue'
    import { FormationsProvider } from '@providers/FormationsProvider.js'
    import { WorkshopsProvider } from '@providers/WorkshopsProvider.js'

    export default {
        components: {
            ChipInput,
        },
        props: ['link', 'standalone', 'id', 'type'],
        data() {
            return {
                emails: [],
                status: null,
                providers: {
                    formation: FormationsProvider,
                    workshop: WorkshopsProvider,
                },
            }
        },
        inject:['formation'],
        methods: {
            handle() {
                if (!this.standalone) {
                    this.sendEmail()
                } else {
                    this.standaloneHandle()
                }
            },
            standaloneHandle() {
                let emails = this.$refs.input.validate()
                if (!emails) return
                this.emails = emails
                this.$emit('submit:email', this.emails)
            },
            async sendEmail() {
                let emails = this.$refs.input.validate()
                if (!emails && emails?.length == 0) return
                this.emails = emails

                await this.providers[this.type]
                    .sendShareEmail({
                        emails: this.emails,
                        formation_id: this.id,
                        link: this.link,
                    })
                    .call()
                let self = this
                this.status = this.$t('formations.share.emails_sent')
                setTimeout(() => {
                    self.status = null
                }, 2000)
                this.$refs.input.clear()
            },
        },
    }
</script>

<template>
    <div>
        <chip-input
            ref="input"
            :label="$t('formations.share.email_invite')"
            placeholder="exemple@mail.com"
            type="email"
            @chips:update="(e) => (this.emails = e)"
        ></chip-input>
        <small v-if="status">{{ status }}</small>
        <v-row class="mt-3">
            <v-col></v-col>
            <v-col class="text-right">
                <v-btn
                    class="primary-gradient"
                    @click="handle"
                >
                    {{ $t('formations.share.email_send') }}
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<style lang=""></style>
