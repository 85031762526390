export const WorkshopRoutesNames = {
    index: 'workshops.index',
    show: 'workshops.show',
    invite: 'workshops.invite',
    create: 'workshops.create',
    create: 'workshops.createtype',
    edit: 'workshops.edit',
    inscription: 'workshops.inscription',
    survey: 'workshops.survey',
    result: 'workshops.result',
}
