export function workshopsTableHeaders() {
    return [
        {
            text: this.$tc('workshops.status'),
            value: 'is_published',
            sortableName: 'status',
            sortable: true,
        },
        {
            text: this.$t('workshops.properties.name.label'),
            value: 'name',
            sortableName: 'name',
            sortable: true,
        },
        {
            text: this.$tc('workshops.properties.date.label', 1),
            value: 'date',
            sortable: true,
        },
        {
            text: this.$t('workshops.type'),
            value: 'parameters',
            sortable: true,
        },
        {
            text: this.$t('workshops.formations'),
            value: 'formations',
        },
        {
            text: this.$t('workshops.nb_apprenants'),
            value: 'nb_apprenants',
            sortable: true,
        },
    ]
}
