<script>
    import { mapActions, mapState } from 'pinia'
    import { mixins } from '@realityteam/reality-academy-ui-kit'

    import { WorkshopsProvider } from '@providers/WorkshopsProvider.js'
    import { WorkshopCollection } from '@libs/Collections/WorkshopCollection.js'

    import Loader from '@components/tools/Loader.vue'
    import WorkshopsTableList from '@components/Workshops/WorkshopsTableList.vue'
    import ApplicationLayout from '@layouts/global/ApplicationLayout.vue'

    export default {
        name: 'MyWorkshopsPage',
        components: { ApplicationLayout, WorkshopsTableList, Loader },
        data() {
            return {
                workshops: new WorkshopCollection(),
                selectedTab: 0,
            }
        },

        computed: {
            isPastTab() {
                return this.selectedTab == 1
            },
            tabs() {
                return [
                    {
                        name: this.$tc('workshops.future_sessions', 2),
                    },
                    {
                        visible: this.isVR,
                        name: this.$tc('workshops.past_sessions', 2),
                    },
                ]
            },
        },
        mounted() {
            this.init()
        },
        methods: {
            async init() {
                this.workshops.setFetcher((query) => {
                    return WorkshopsProvider.getWorkshops({
                        type: this.isPastTab ? 'passed' : null,
                        ...query,
                    })
                })
            },
        },
        watch: {
            isPastTab() {
                this.workshops.fetchPaginated()
            },
        },
    }
</script>

<template>
    <application-layout>
        <ra-header-with-tabs
            v-model="selectedTab"
            :subtitle="$t('workshops._subtitle')"
            :tabs="tabs"
            :title="$tc('workshops._name', 2)"
            no-router
        >
        </ra-header-with-tabs>
        <workshops-table-list
            :workshops="workshops"
            :passed="isPastTab"
        ></workshops-table-list>
    </application-layout>
</template>
