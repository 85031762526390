import ApiClient, { getQueryString } from '..'

export class GroupProvider {
    static get route() {
        return `/groups`
    }

    static findAll(query = null) {
        const api = ApiClient.getInstance()
        return api.get(this.route, query)
    }

    static delete(id) {
        const api = ApiClient.getInstance()
        return api.delete(`${this.route}/${id}`)
    }
}
