import { Structure } from '@libs/Structures/Structure.js'
import { useGlobalEnv } from '@store/globalEnv.js'
import { GlobalStatStructure } from '@libs/Structures/GlobalStatStructure.js'

export class LearnerStructure extends Structure {
    constructor(...args) {
        super(...args)
    }

    get globalStats() {
        return new GlobalLearnerStatsStructure(this.stats.global).grid
    }

    static getDefault() {
        const globalEnv = useGlobalEnv()
        const structure = new LearnerStructure()
        return structure.patch({
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            user_id: null,
            formation_ids: [-1],
            groupe_id: null,
            notify: true,
            is_vr: globalEnv.isVR,
            is_elearning: globalEnv.isEl,
        })
    }
}

class GlobalLearnerStatsStructure extends GlobalStatStructure {
    constructor(...args) {
        super(...args)
    }

    get grid() {
        return [
            {
                icon: 'fa-calendar',
                label: this.$t(this.globalTradKey + '.session_count'),
                value: this.toInt(this.sessions_count),
            },
            {
                icon: 'fa-success-average',
                label: this.$t(this.globalTradKey + '.success_average'),
                value: this.toPercent(this.mean_result),
            },
            {
                icon: 'fa-time-spent',
                label: this.$t(this.globalTradKey + '.time_spent'),
                value: this.toDuration(this.time_passed),
            },
            {
                icon: 'fa-completion',
                label: this.$t(this.globalTradKey + '.completion_rate'),
                value: this.toPercent(this.completion_rate),
            },
        ]
    }
}
