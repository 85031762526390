export class APIError extends Error {
    static defaultMessage = 'API Error Occured'
    constructor(error) {
        if (!error.response) throw error // Not an axios/API error
        super(
            '[API ERROR][' +
                error.response.status +
                '] ' +
                (error.response?.data?.message || APIError.defaultMessage)
        )

        this.apiMessage =
            error.response?.data?.message || APIError.defaultMessage
        this.response = error.response
        this.request = error.request
        this.status = error.response.status
        this.body = error.response.data
        this.errors = this.body?.errors || {}
    }
    hasStatus(code) {
        return this.status == code
    }

    isAuthError() {
        if (
            this.hasStatus(401) ||
            this.response.apiMessage == 'Unauthenticated.'
        ) {
            return true
        }
    }

    get errorsFlat() {
        let errors = []
        for (let prop in this.errors) {
            errors.push(this.errors[prop])
        }
        return errors.flat()
    }
    static catch(request) {
        return request.catch((error) => {
            let apiError = new APIError(error)
            apiError.handle()
        })
    }
}
