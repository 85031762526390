<script>
    import workshopHeader from '@/components/Workshops/WorkshopHeader.vue'
    import { useWorkshopStore } from '@store/workshop'
    import { mapActions, mapState } from 'pinia'
    import Loader from '@components/tools/Loader.vue'
    import page404 from '@/components/global/Page404.vue'
    export default {
        data() {
            return {
                submited: false,
                workshopError: false,
            }
        },
        components: {
            Loader,
            workshopHeader,
            page404,
        },
        async mounted() {
            await this.getWorkshop()
            if (!this.workshopError)
                this.loadWorkshopSurvey(this.$route.params.workshop_id)
        },
        computed: {
            ...mapState(useWorkshopStore, ['workshopValue', 'workshopSurvey']),
        },
        methods: {
            ...mapActions(useWorkshopStore, [
                'loadWorkshop',
                'loadWorkshopSurvey',
                'saveWorkshopSurvey',
            ]),
            async getWorkshop() {
                try {
                    // second parameter can show the page without login
                    await this.loadWorkshop(
                        this.$route.params.workshop_id,
                        true
                    )
                } catch (error) {
                    this.workshopError = true
                }
            },
            async saveSurvey() {
                try {
                    await this.saveWorkshopSurvey(
                        this.$route.params.workshop_id
                    )
                    this.submited = true
                } catch (error) {
                    console.error(error)
                }
            },
        },
    }
</script>
<template>
    <ra-box
        height="100vh"
        scroll-y
    >
        <workshop-header :workshopValue="workshopValue" />
        <div v-if="workshopError"> <page404 /></div>
        <loader
            :wait-for="workshopSurvey"
            v-else
        >
            <ra-box
                padding="10"
                class="workshop-survey-container"
            >
                <ra-heading-title
                    :subtitle="$t('workshops.survey.title')"
                    :title="workshopValue.name"
                />
                <ra-card
                    v-if="!submited"
                    padding="0"
                >
                    <ra-box v-if="workshopSurvey">
                        <ra-box
                            padding="10"
                            v-for="workshopQuestion in workshopSurvey"
                            :key="workshopQuestion.id"
                        >
                            <h2>{{ workshopQuestion.name }}</h2>
                            <ra-stack
                                direction="horizontal"
                                distribution="side-spacing"
                                padding="5,"
                                wrap
                                v-if="workshopQuestion.type === 'choice'"
                            >
                                <div
                                    v-for="(
                                        choice, index
                                    ) in workshopQuestion.choices"
                                    :key="index"
                                >
                                    <input
                                        type="radio"
                                        :id="`option-${workshopQuestion.id}-${index}`"
                                        :name="workshopQuestion.id"
                                        :value="choice.id"
                                        v-model="workshopQuestion.answer"
                                    />
                                    <label
                                        class="btn-checkbox-label"
                                        :for="`option-${workshopQuestion.id}-${index}`"
                                        :class="{
                                            active:
                                                selectedOption === choice.id,
                                        }"
                                        >{{ choice.name }}</label
                                    >
                                </div>
                            </ra-stack>
                            <ra-stack
                                direction="horizontal"
                                distribution="side-spacing"
                                padding="5"
                                v-else-if="
                                    workshopQuestion.type === 'formation-select'
                                "
                            >
                                <ra-stack
                                    gap="8"
                                    width="300px"
                                >
                                    <ra-input>
                                        <v-select
                                            v-model="workshopQuestion.answer"
                                            :items="workshopQuestion.choices"
                                            item-title="name"
                                            item-value="id"
                                            multiple
                                            density="compact"
                                            hide-details
                                            variant="outlined"
                                        >
                                        </v-select>
                                    </ra-input>
                                </ra-stack>
                            </ra-stack>
                        </ra-box>
                    </ra-box>
                    <ra-stack
                        direction="horizontal"
                        padding=",10,4,10"
                    >
                        <ra-spacer />
                        <v-btn
                            color="primary-gradient"
                            margin=",6"
                            variant="flat"
                            @click="saveSurvey"
                        >
                            {{ $t('workshops.actions.save') }}
                        </v-btn>
                    </ra-stack>
                </ra-card>
                <ra-card v-else>
                    <ra-box
                        center
                        padding="4,"
                    >
                        <ra-icon
                            class="text-green"
                            style="
                                font-size: 100px;
                                line-height: normal !important;
                            "
                            weight="700"
                            padding="4,"
                        >
                            fa-circle-check
                        </ra-icon>
                        <ra-typo
                            h3
                            margin="10"
                        >
                            {{ $t('workshops.survey.submited') }}
                        </ra-typo>
                    </ra-box>
                </ra-card>
            </ra-box>
        </loader>
    </ra-box>
</template>
<style lang="scss">
    @media (max-width: 768px) {
        .workshop-survey-container .ra-heading-title-left h2 {
            white-space: wrap;
        }
    }
    .workshop-survey-container {
        max-width: 1000px;
        margin: 0 auto;
    }
    input[type='radio'] {
        display: none; // Hide the default radio buttons
    }

    .btn-checkbox-label {
        display: inline-block;
        padding: 10px 20px;
        margin: 5px;
        border: 1px solid #ccc;
        border-radius: 5px;
        cursor: pointer;
    }

    input[type='radio']:checked + label {
        background-color: var(--primary); // Active state background color
        color: #fff; // Active state text color
    }
</style>
