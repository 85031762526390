export class RouteHistory {
    constructor(route, goBackRoute) {
        this.route = route
        this.goBack = goBackRoute
    }
}

export class RouterHistory extends Map {
    static instance = null

    subscribers = []

    constructor(router) {
        super()
        this.router = router
    }

    static getInstance(router) {
        if (!this.instance) {
            this.instance = new RouterHistory(router)
        }
        return this.instance
    }

    resolve(locationRaw) {
        let route = this.router.resolve(locationRaw)
        if (!this.has(route)) return null
        let historyRoute = this.get(route)
        if (historyRoute?.goBack) return historyRoute.goBack
        else return null
    }

    has(route) {
        if (typeof route === 'string') return super.has(route)
        return super.has(route?.name) || super.has(route?.meta?.relatedName)
    }

    get(route) {
        if (typeof route === 'string') return super.get(route)
        return super.get(route?.name) || super.get(route?.meta?.relatedName)
    }

    back(locationRaw) {
        let backRoute = this.resolve(locationRaw)
        if (backRoute) this.router.push(backRoute)
    }

    /**
     * @param routeName
     -     * @param {RouteHistory} historyRoute
     +     * @param {string} routeName - The name of the route
     +     * @param {RouteHistory} historyRoute - The route history object
     +     * @returns {void}
     */

    set(routeName, historyRoute) {
        super.set(routeName, historyRoute)
        this.subscribers.forEach((callback) => {
            try {
                callback(routeName, historyRoute)
            } catch (error) {
                console.error('Error in route subscriber:', error)
            }
        })
    }

    /**
     * Subscribe to new route additions
     * @param {function(string, RouteHistory): void} callback - Called when a new route is added
     * @returns {function(): void} Unsubscribe function
     */
    onNewRoute(callback) {
        if (typeof callback !== 'function') {
            throw new TypeError('Callback must be a function')
        }
        this.subscribers.push(callback)
    }

    cleanRouteSubscribers() {
        this.subscribers = []
    }
}
