<script>
export default {
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      options: [
        {
          value: 1,

          images: {
            active: "/images/MDM/1-screen-active.svg",
            inactive: "/images/MDM/1-screen-inactive.svg",
          },
        },
        {
          value: 2,

          images: {
            active: "/images/MDM/2-screen-active.svg",
            inactive: "/images/MDM/2-screen-inactive.svg",
          },
        },
        {
          value: 4,

          images: {
            active: "/images/MDM/4-screen-active.svg",
            inactive: "/images/MDM/4-screen-inactive.svg",
          },
        },
      ],
    };
  },

  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        if (this.disabled) return;
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<template>
  <ra-box padding=", 5">
    <ra-typo h2>Écrans</ra-typo>
    <ra-stack
      alignement="center"
      class="screens-options"
      direction="horizontal"
      distribution="fill-equaly"
      wrap
    >
      <img
        v-for="o of options"
        :key="o.value"
        :alt="value == o.value ? o.images.active : o.images.inactive"
        :src="
          '/src/assets' +
          (value == o.value ? o.images.active : o.images.inactive)
        "
        @click="value = o.value"
      />
    </ra-stack>
  </ra-box>
</template>

<style lang="scss">
.screens-options {
  img {
    width: 50px;
    height: 50px;
    margin: 10px;
    cursor: pointer;
  }
}
</style>
