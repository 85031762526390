import ApiClient from '@api'

export class PacksProvider {
    static api = ApiClient.getInstance()

    static route = '/packs'
    static getPacks(query = {}) {
        return this.api.get(`${this.route}`, query)
    }
    static getPack(id, query = {}) {
        return this.api.get(`${this.route}/${id}`, query)
    }
    static createPack(payload, query = {}) {
        return this.api.post(`${this.route}`, payload, query)
    }
    static updatePack(id, payload, query = {}) {
        return this.api.put(`${this.route}/${id}`, payload, query)
    }
    static deletePack(id, query = {}) {
        return this.api.delete(`${this.route}/${id}`, query)
    }
}
