<script>
    import ApplicationLayout from '@layouts/global/ApplicationLayout.vue'
    import StartFormationProgress from '@components/Headsets/StartFormation/StartFormationProgress.vue'
    import StartFormationHeadsetList from '@components/Headsets/StartFormation/StartFormationHeadsetList.vue'

    export default {
        name: 'StartFormationLayout',
        components: {
            StartFormationHeadsetList,
            StartFormationProgress,
            ApplicationLayout,
        },
        props: {
            currentStep: {
                type: Number,
                default: null,
            },
        },
    }
</script>

<template>
    <ApplicationLayout v-bind="$attrs">
        <web>
            <ra-box height="100%">
                <start-formation-progress
                    :currentStep="currentStep"
                    @back="$emit('back')"
                />
                <ra-stack
                    alignement="leading"
                    class="web-stack-layout"
                    direction="horizontal"
                    distribution="fill"
                    gap="5"
                    mobile-swap
                >
                    <ra-stack
                        bg="white"
                        padding="7,"
                        rounded
                    >
                        <slot name="asideTitle"></slot>
                        <slot name="aside"></slot>
                    </ra-stack>
                    <slot></slot>
                </ra-stack>
            </ra-box>
        </web>
        <template #tabletAside>
            <ra-stack
                distribution="equal-spacing"
                gap="8"
            >
                <ra-spacer height="0" />
                <slot name="asideTitle"></slot>
                <ra-box
                    height="70vh"
                    scroll-y
                >
                    <slot name="aside" />
                </ra-box>
            </ra-stack>
        </template>

        <tablet>
            <start-formation-progress
                :currentStep="currentStep"
                @back="$emit('back')"
            />
            <ra-box
                class="headset-selection"
                padding="6,10"
            >
                <slot></slot>
            </ra-box>
        </tablet>
    </ApplicationLayout>
</template>

<style lang="scss" scoped>
    .web-stack-layout {
        & > .ra-stack {
            border: solid 0.5px rgba(9, 9, 84, 0.39);
            &:first-child {
                flex: 0 0 350px;
            }
            @media screen and (max-width: 700px) {
                flex: 0 0 auto;
            }
        }
    }
</style>
