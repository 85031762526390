<script>
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import LearnerAddForm from '@components/Learners/LearnerAddForm.vue'
    import { useGlobalDialog } from '@store/globalDialog.js'
    import { LearnerStructure } from '@libs/Structures/LearnerStructure.js'
    import { workshopAddLearnerFormInputs } from '@components/Workshops/WorkshopAddLearnerForm.inputs.js'
    import { WorkshopsProvider } from '@providers/WorkshopsProvider.js'
    import { mapActions } from 'pinia'
    import { useWorkshopStore } from '@store/workshop'

    export default {
        name: 'WorkshopInscription',
        components: {
            LearnerAddForm,
        },
        data() {
            return {
                learner: LearnerStructure.getDefault(),
                errors: {},
                error: null,
                selectedSlot: null,
                selectedApprenant: null,
                currentSlide: 0,
            }
        },
        mixins: [mixins.RaDialogsMixin],
        props: {
            slots: {
                default: () => [],
            },
            workshop: {},
            isApprenant: {
                default: false,
            },
        },
        computed: {
            batchedSlots() {
                const batchSize = window.innerWidth >= 800 ? 3 : 1

                return this.slots.reduce((acc, curr, i) => {
                    if (!(i % batchSize))
                        acc.push(this.slots.slice(i, i + batchSize))
                    return acc
                }, [])
            },
            inputs() {
                return workshopAddLearnerFormInputs.call(
                    this,
                    this.targetDisplayUser,
                    this.company,
                    this.licenses,
                    this.errors
                )
            },
        },
        methods: {
            ...mapActions(useGlobalDialog, ['setGlobalDialog']),
            ...mapActions(useWorkshopStore, ['setWorkshop']),
            remainingSlots(slot) {
                return Math.max(
                    0,
                    slot.max_apprenants - (slot.apprenants?.length || 0)
                )
            },
            removeApprenantDialog(selectedSlot, selectedApprenant) {
                this.selectedSlot = selectedSlot
                this.selectedApprenant = selectedApprenant
                this.setGlobalDialog({
                    title: this.$t('workshops.learners.actions.delete.title'),
                    visible: true,
                    message: this.$t(
                        'workshops.learners.actions.delete.message'
                    ),
                    positiveButton: {
                        title: this.$t(
                            'workshops.learners.actions.delete.positive'
                        ),
                        action: () => this.removeLeaner(),
                    },
                    negativeButton: {
                        title: this.$t(
                            'formations.show.dialogs.get_credits.negative_btn'
                        ),
                    },
                })
            },
            addApprenantDialog(selectedSlot) {
                if (
                    selectedSlot.apprenants.length >=
                    selectedSlot.max_apprenants
                ) {
                    return
                }
                this.selectedSlot = selectedSlot.id
                this.openDialog('dialog-add-apprenant')
            },
            async addLearner() {
                try {
                    const { data } =
                        await WorkshopsProvider.addApprenantWorkshop(
                            ...[
                                this.workshop.id,
                                this.selectedSlot,
                                this.learner,
                            ]
                        )
                            .bindVue(this)
                            .call()
                    this.selectedSlot = null
                    this.setWorkshop(data.data)
                    this.closeDialog('dialog-add-apprenant')
                    this.$emit('registeredLearner')
                    this.learner = LearnerStructure.getDefault()
                } catch (e) {
                    console.error(e)
                }
            },
            async removeLeaner() {
                try {
                    const { data } =
                        await WorkshopsProvider.removeApprenantWorkshop(
                            ...[
                                this.workshop.id,
                                this.selectedSlot,
                                this.selectedApprenant,
                            ]
                        ).call()
                    this.selectedApprenant = null
                    this.selectedSlot = null
                    this.setWorkshop(data.data)
                } catch (e) {
                    console.error(e)
                }
            },
            goToNext() {
                this.currentSlide =
                    (this.currentSlide + 1) % this.batchedSlots.length
            },
            goToPrevious() {
                this.currentSlide =
                    (this.currentSlide - 1 + this.batchedSlots.length) %
                    this.batchedSlots.length
            },
        },
    }
</script>
<template>
    <ra-card>
        <template #title>Inscriptions</template>
        <template
            #right
            v-if="!isApprenant"
        >
            <v-btn
                variant="text"
                @click="goToPrevious"
                icon="mdi-chevron-left"
            />
            <v-btn
                variant="text"
                @click="goToNext"
                icon="mdi-chevron-right"
            />
        </template>
        <ra-stack
            v-if="isApprenant"
            distribution="side-spacing"
            gap="6"
        >
            <ra-typo
                h2
                padding="5,"
                center
            >
                {{ $t('workshops.slots.choose') }}
            </ra-typo>
            <ra-box class="formation-grid">
                <slot v-for="slot in slots">
                    <ra-card
                        variant="outlined"
                        class="apprenant-selector"
                        :class="
                            slot.apprenants?.length == slot.max_apprenants
                                ? 'full-slot'
                                : ''
                        "
                        @click="addApprenantDialog(slot)"
                    >
                        <ra-typo
                            p
                            center
                            v-if="
                                slot.apprenants?.length == slot.max_apprenants
                            "
                        >
                            Complet
                        </ra-typo>

                        <ra-stack>
                            <ra-typo
                                center
                                h3
                                >{{ $filters.formatTime(slot.start_time) }}
                                -
                                {{
                                    $filters.formatTime(slot.end_time)
                                }}</ra-typo
                            >
                            <template v-if="isApprenant">
                                <ra-typo
                                    bold
                                    center
                                    text
                                    class="users"
                                >
                                    <ra-typo span>{{
                                        slot.apprenants?.length
                                    }}</ra-typo
                                    >/{{ slot.max_apprenants }}
                                    <ra-icon
                                        variant="plain"
                                        icon="mdi-account"
                                    ></ra-icon>
                                </ra-typo>
                            </template>
                            <template v-else>
                                <ra-typo
                                    bold
                                    center
                                    text
                                    class="users"
                                >
                                    <ra-typo span>{{
                                        slot.apprenants?.length
                                    }}</ra-typo
                                    >/{{ slot.max_apprenants }}
                                    <ra-icon
                                        variant="plain"
                                        icon="mdi-account"
                                    ></ra-icon>
                                </ra-typo>
                                <v-btn
                                    @click="
                                        removeApprenantDialog(
                                            slot.id,
                                            apprenant.id
                                        )
                                    "
                                    variant="outlined"
                                    class="btn-apprenant"
                                    v-for="apprenant in slot.apprenants"
                                >
                                    {{ apprenant.firstname }}
                                    <v-icon
                                        icon="mdi-close-circle"
                                        size="x-small"
                                        end
                                    ></v-icon>
                                </v-btn>
                                <v-btn
                                    v-for="i in remainingSlots(slot)"
                                    variant="tonal"
                                    @click="addApprenantDialog(slot)"
                                >
                                    <ra-icon
                                        class="more-icon"
                                        icon="mdi-plus"
                                    ></ra-icon>
                                </v-btn>
                            </template>
                        </ra-stack> </ra-card
                ></slot>
            </ra-box>
        </ra-stack>
        <v-carousel
            v-else
            v-model="currentSlide"
            hide-delimiter-background
            hide-delimiters
            ref="carousel"
            :show-arrows="false"
            style="padding-top: 20px; height: 100% !important"
        >
            <v-carousel-item v-for="slotBatch in batchedSlots">
                <ra-stack
                    direction="horizontal"
                    distribution="side-spacing"
                    gap="6"
                >
                    <ra-box
                        v-for="slot in slotBatch"
                        class="slot"
                    >
                        <ra-card variant="outlined">
                            <ra-stack>
                                <ra-typo
                                    center
                                    h3
                                >
                                    {{ $filters.formatTime(slot.start_time) }}
                                    -
                                    {{ $filters.formatTime(slot.end_time) }}
                                </ra-typo>
                                <ra-typo
                                    bold
                                    center
                                    text
                                    class="users"
                                >
                                    <ra-typo span>{{
                                        slot.apprenants?.length
                                    }}</ra-typo
                                    >/{{ slot.max_apprenants }}
                                    <ra-icon
                                        variant="plain"
                                        icon="mdi-account"
                                    ></ra-icon>
                                </ra-typo>
                                <ra-stack
                                    @click="
                                        removeApprenantDialog(
                                            slot.id,
                                            apprenant.id
                                        )
                                    "
                                    variant="outlined"
                                    class="btn-apprenant"
                                    direction="horizontal"
                                    v-for="apprenant in slot.apprenants"
                                    alignement="center"
                                >
                                    <ra-stack gap="0">
                                        <ra-typo center>
                                            {{ apprenant.firstname }}
                                            {{ apprenant.lastname }}
                                        </ra-typo>
                                        <ra-typo
                                            class="learner-email"
                                            center
                                        >
                                            {{ apprenant.email }}
                                        </ra-typo>
                                    </ra-stack>
                                    <v-icon
                                        icon="mdi-close-circle"
                                        size="x-small"
                                        end
                                    ></v-icon>
                                </ra-stack>
                                <v-btn
                                    v-for="i in remainingSlots(slot)"
                                    variant="tonal"
                                    @click="addApprenantDialog(slot)"
                                >
                                    <ra-icon
                                        class="more-icon"
                                        icon="mdi-plus"
                                    ></ra-icon>
                                </v-btn>
                            </ra-stack>
                        </ra-card>
                    </ra-box>
                </ra-stack>
            </v-carousel-item>
        </v-carousel>
    </ra-card>

    <ra-dialog ref="dialog-add-apprenant">
        <ra-base-dialog>
            <template #title>
                {{ $t('learners.actions.add') }}
            </template>
            <ra-form
                :element="learner"
                :inputs="inputs"
                @submit="addLearner"
                :error="error"
            />
        </ra-base-dialog>
    </ra-dialog>

    <ra-dialog ref="dialog-remove-apprenant">
        <ra-base-dialog>
            <template #title>
                {{ $t('learners.actions.remove') }}
            </template>
            <ra-typo padding="5,0">
                Voulez vous vraiment supprimer l'apprenant ?
            </ra-typo>
            <template #footer>
                <v-btn
                    @submit="removeLearner"
                    color="secondary"
                    >Confirmer</v-btn
                >
            </template>
        </ra-base-dialog>
    </ra-dialog>
</template>

<style lang="scss" scoped>
    .full-slot {
        background: var(--primary) !important;
        color: white;
        opacity: 0.5;
        cursor: not-allowed;
    }
    .more-icon {
        background: #adadc5;
        border-radius: 50%;
        padding: 0.7rem;
    }
    .users {
        font-size: 1.2em;
        span {
            font-size: 1.5em;
        }
    }
    .admin-selector {
        cursor: auto;
        .ra-card-new-body:hover {
            background: #fff !important;
        }
    }

    [data-device='web'] .formation-grid {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }

    .formation-grid {
        display: grid;

        grid-gap: 60px;

        .formation-card {
            width: 100%;
            height: 100%;
        }
    }

    .btn-apprenant {
        border: 1px solid var(--primary);
        border-radius: 5px;
        padding: 2px;
        cursor: pointer;
        padding-left: 15px;
    }

    .slot {
        // media
        width: 33%;
        @media (max-width: 800px) {
            width: 100%;
        }
    }

    .learner-email {
        font-size: 0.8em;
    }
</style>
