import { authentificatedRoutesMeta as meta } from '@core/router/routes/Authentificated/meta.js'
import { MyFormationsRoutesName } from '@core/router/routes/Authentificated/MyFormations.names.js'

export default [
    /**
     * Support legacy scorm remote
     */
    {
        path:"/play-formation/:formation_id",
        redirect: to=>{
            return {
                name: MyFormationsRoutesName.play,
                params: {
                    formation_id: to.params.formation_id
                }
            }
        }
    },
    {
        path: '/my-formations',
        children: [
            {
                path: '',
                name: MyFormationsRoutesName.index,
                meta,
                component: () =>
                    import('@pages/My-Formations/MyFormationsIndexPage.vue'),
            },
            {
                path: ':formation_id',
                children: [
                    {
                        path: '',
                        name: MyFormationsRoutesName.show,
                        meta,
                        component: () =>
                            import(
                                '@pages/My-Formations/MyFormationsShowPage.vue'
                            ),
                    },
                    {
                        path: 'play',
                        name: MyFormationsRoutesName.play,
                        component: () =>
                            import(
                                '@pages/My-Formations/PlayerFormationPage.vue'
                            ),
                        meta: {
                            ...meta,
                            roles: undefined,
                        },
                    },
                ],
            },
        ],
    },
]
