<script>
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import { HeadsetProvider } from '@providers/HeadsetProvider.js'
    export default {
        mixins: [mixins.RaDialogMixin],
        name: 'HeadsetRenameDialog',
        props: {
            headset: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                name: this.headset.name,
            }
        },
        methods: {
            async handleSubmit() {
                try {
                    await HeadsetProvider.update(
                        this.headset.user.company.id,
                        this.headset.id,
                        {
                            pretty_name: this.name,
                        }
                    ).call()
                    this.headset.name = this.name
                    this.close()
                } catch (e) {
                    console.error(e)
                }
            },
        },
    }
</script>

<template>
    <ra-base-dialog>
        <template #title>
            {{ $tc('headsets.actions.rename', 2) }}
        </template>
        <ra-stack>
            <ra-input>
                <v-text-field
                    v-model="name"
                    :label="$t('headsets.settings.headset_name_section')"
                    variant="outlined"
                />
            </ra-input>
            <ra-stack direction="horizontal">
                <ra-spacer />
                <v-btn
                    color="primary-gradient"
                    @click="handleSubmit"
                >
                    {{ $tc('headsets.actions.rename', 1) }}
                </v-btn>
            </ra-stack>
        </ra-stack>
    </ra-base-dialog>
</template>

<style scoped lang="scss"></style>
