<script>
    import { mapActions } from 'pinia'
    import { useGlobalSnackBar } from '@store/globalSnakbar.js'
    import { copyToClipboard } from '@utils/copyToClipboard.js'

    export default {
        props: ['link'],
        data() {
            return {
                shareLink: this.link,
            }
        },
        methods: {
            ...mapActions(useGlobalSnackBar, ['showSnackBar']),
            async copyLink() {
                await copyToClipboard(this.shareLink)
                this.showSnackBar(this.$t('share.copied_link'))
            },
            selectLink(e) {
                let input = this.$refs.inputShareLink.$el.querySelector('input')
                input.select()
                this.copyLink()
            },
        },
        watch: {
            link(newLink) {
                this.shareLink = newLink
            },
        },
    }
</script>

<template>
    <ra-input
        :label="$t('share.link.title')"
        class="link-share-container"
        name="share-link"
    >
        <v-text-field
            ref="inputShareLink"
            v-model="shareLink"
            hide-details
            readonly
            variant="outlined"
            @click="selectLink"
        >
        </v-text-field>
        <span
            class="copy-span"
            @click="selectLink"
            >{{ $t('generics.copy') }}</span
        >
    </ra-input>
</template>
<style>
    .link-share-container {
        position: relative;
    }
    .link-share-container input {
        padding-right: 90px;
    }
    .link-share-container > .copy-span {
        position: absolute;
        top: 35px;
        right: 10px;
        text-align: right;
        opacity: 0.7;
        text-transform: uppercase;
        font-weight: 600;
        background: white;
    }
</style>
