<script>
import PilotageSettings from "@components/Headsets/PilotageSettings.vue";
import { TranslationAlias } from "@core/i18n/TranslationAlias.js";
import { useFAQStore } from "@store/faqTablet.js";
import { mapActions } from "pinia";

export default {
  name: "FaqHeadset",
  components: { PilotageSettings },
  props: {
    type: {
      type: String,
      default: "first_part",
    },
  },
  data() {
    return {
      translationAlias: new TranslationAlias("headsets.faq", this),
    };
  },
  methods: {
    ...mapActions(useFAQStore, ["close"]),
  },
  computed: {
    content() {
      return this.translationAlias.get("headsets.faq.content");
    },
  },
};
</script>

<template>
  <ra-box padding="10">
    <ra-stack bg="white" class="faq-container" gap="8" padding="8" rounded>
      <ra-box>
        <ra-typo bold class="text-center" uppercase variant="title">
          {{ $_t("title") }}
        </ra-typo>
        <ra-typo class="text-center" italic variant="subtitle">
          {{ $_t("subtitle") }}
        </ra-typo>
      </ra-box>
      <v-btn
        class="faq-container-close"
        density="comfortable"
        icon="mdi-arrow-left"
        variant="plain"
        @click="close"
      />
      <ra-box height="calc(100vh - 270px)" padding="2" scroll-y>
        <v-expansion-panels elevation="0"  variant="accordion">
          <v-expansion-panel
            v-for="el in content.filter(
              (e) => e?.visible == undefined || e.visible,
            )"
          >
            <v-expansion-panel-title>
              <ra-typo italic semi variant="subtitle" x>
                {{ el.title }}
              </ra-typo>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div v-for="row in el.content" class="faq-content-row">
                <img
                  v-if="row?.image"
                  :src="row?.image"
                  :style="{ order: row?.reversed ? 1 : 0 }"
                />
                <ra-typo variant="small" v-html="row.text"></ra-typo>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </ra-box>
    </ra-stack>
  </ra-box>
</template>

<style lang="scss">
.faq-container {
  position: relative;
  .faq-container-close {
    position: absolute;
    top: 1em;
    left: 1em;
  }

  .faq-content-row {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1em 0;
    img {

      width: 100%;
      height: 300px;
      object-fit: contain;
      display: block;
      object-position: top;
    }
    ul {
      padding-left: 1.5em;
    }

    & + .faq-content-row {
      padding-top: 0em;
    }
  }
  faq-content-row:nth-child(2n + 3) {
    flex-direction: row-reverse;
  }
}
</style>
