import { defineStore } from 'pinia'
import { LangProvider } from '@core/api/providers'
import i18n, { i18nLocaleObserver } from '@core/i18n/index.js'

export const LANG = {
    FR: 'fr',
    EN: 'en',
}

export const useLanguageStore = (fetch = true) => {
    const store = defineStore('lang', {
        state: () => ({
            lang: localStorage.getItem('lang') || LANG.FR,
            locales: [
                {
                    code: LANG.FR,
                    name: 'Français',
                },
                {
                    code: LANG.EN,
                    name: 'English',
                },
            ],
            fetched: false,
            fetching: false,
        }),

        getters: {
            currentLocale(state) {
                return state.lang
            },

            locale(state) {
                return this.locales.find(({ code }) => code === state.lang)
            },
        },
        actions: {
            setLocale(locale) {
                this.lang = locale
                i18nLocaleObserver.locale = locale
                localStorage.setItem('lang', locale)
            },

            getLocale(key) {
                return this.locales.find(({ code }) => code === key) || null
            },

            async getLocales() {
                if (this.fetching) return
                this.fetching = true
                const { data } = await LangProvider.getLocales()
                    .call()
                    .catch((e) => {
                        console.error(e)
                    })
                this.fetching = false
                this.fetched = true
                this.setLocales(data)
            },

            setLocales(locales) {
                if (!Array.isArray(locales))
                    throw new Error('Locales must be an array')
                this.locales = locales
            },
        },
    })
    let defaultLanguage = navigator?.language?.split('-')[0] || LANG.FR
    if (!localStorage.getItem('lang'))
        localStorage.setItem('lang', defaultLanguage)
    const storeInstance = store()

    if (i18nLocaleObserver.locale !== storeInstance.lang)
        i18nLocaleObserver.locale = storeInstance.lang

    if (!storeInstance.fetched && fetch) storeInstance.getLocales()
    return storeInstance
}
