<script>
export default {
  props: ["value", 'isLAN', "compact"],
};
</script>
<template>
  <div :class="[value ? 'connected' : 'disconnected']" class="connected-status">
    <ra-icon :style="{fontSize:value && isLAN !== undefined ? '16px' :'8px'}">
      {{
        value
          ? isLAN == undefined ? 'mdi-circle' : isLAN
            ? 'mdi-lan'
            : 'mdi-signal-variant'
          : 'mdi-circle'
      }}
    </ra-icon>
    <ra-typo v-if="!compact" italic semi variant="small">
      {{
        value
          ? $t("headsets.properties.status.values.connected")
          : $t("headsets.properties.status.values.disconnected")
      }}
    </ra-typo>
  </div>
</template>
<style lang="scss">
.connected-status {
  color: var(--primary);
  position: relative;
  font-size: 13px;
  display:flex;
  gap: 10px;
  align-items: center;
  .v-icon{
    font-size: 8px;
  }
  .ra-typography {
    line-height: 1.4em;
    font-size: 12px;
  }

  &.connected {
    color: var(--success);
  }

  &.disconnected {
    color: var(--error);
  }
}
</style>
