export class WSHeartBeat {
    static pongMessage = new Uint8Array([0x0a])
    static pingMessage = new Uint8Array([0x09])
    static isPingMessage(uint8Array) {
        return WSHeartBeat.compare(uint8Array, WSHeartBeat.pingMessage)
    }
    static sendPong(socket) {
        socket.send(WSHeartBeat.pongMessage)
    }

    static compare(Uint8Array1, Uint8Array2) {
        if (Uint8Array1.length !== Uint8Array2.length) {
            return false
        }
        for (let i = 0; i < Uint8Array1.length; i++) {
            if (Uint8Array1[i] !== Uint8Array2[i]) {
                return false
            }
        }
        return true
    }
}
