<script>
    import { FormersProvider } from '@providers'
    import { WorkshopCollection } from '@libs/Collections/WorkshopCollection.js'
    import TableView from '@layouts/global/TableView.vue'
    import { TranslationAlias } from '@core/i18n/TranslationAlias.js'
    import { workshopsTableHeaders } from '@components/Workshops/WorkshopTableList.header.js'
    import Loader from '@components/tools/Loader.vue'
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import AffectGroupMixin from '@mixins/AffectGroupMixin.js'
    import { useGlobalDialog } from '@store/globalDialog.js'
    import { mapActions } from 'pinia'
    import { WorkshopRoutesNames } from '@core/router/routes/generators/Workshop.names.js'
    import { WorkshopsProvider } from '@providers/WorkshopsProvider.js'
    import DeleteConfirmDialog from '@ui/DeleteConfirmDialog.vue'
    import FormationEllipsizer from '@components/tools/FormationEllipsizer.vue'
    import { FormationsProvider } from '@providers/FormationsProvider.js'
    import { useGlobalSnackBar } from '@store/globalSnakbar.js'
    export default {
        name: 'WorkshopTableList',
        components: {
            FormationEllipsizer,
            Loader,
            TableView,
            DeleteConfirmDialog,
        },
        mixins: [
            mixins.RaMassDeleteMixin,
            AffectGroupMixin,
            mixins.RaDialogsMixin,
        ],
        props: {
            workshops: {
                type: WorkshopCollection,
                default: () => [],
            },
            passed: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                translationAlias: new TranslationAlias('workshops', this),
                selected: [],
                filters: {
                    status: [],
                    type: [],
                    formation_id: [],
                    date: [],
                },
                groups: [],
                workshopToDelete: null,
            }
        },

        computed: {
            headers() {
                return workshopsTableHeaders.call(this)
            },
            actions() {
                return [
                    {
                        label: this.$_t('actions.add'),
                        icon: 'mdi-plus',
                        handler: () => {
                            this.openDialog('dialog-add-workshop')
                        },
                    },
                ]
            },
            groupedActions() {
                return [
                    this.massDeleteAction(
                        (ids) => this.handleMassDelete(ids),
                        'workshops._name'
                    ),
                ]
            },
            itemActions() {
                return [
                    {
                        label: this.$_t('actions.edit'),
                        icon: 'mdi-pencil',
                        visible: (item) => item.is_published,
                        handler: (item) => {
                            this.$router.push(
                                {
                                    name: item.is_published
                                        ? WorkshopRoutesNames.show
                                        : WorkshopRoutesNames.edit,
                                    params: { workshop_id: item.id },
                                },
                                this.$route
                            )
                        },
                    },
                    {
                        label: this.$_t('actions.delete.title'),
                        icon: 'fa-trash',
                        handler: (item) => {
                            this.workshopToDelete = item
                            this.openDialog('dialog-workshop-delete')
                        },
                    },
                    {
                        label: this.$_t('actions.duplicate'),
                        icon: 'mdi-content-copy',
                        handler: async (item) => {
                            this.workshops.loading = true
                            this.showSnackBar(
                                this.$_t('actions.duplicate_processing')
                            )
                            await WorkshopsProvider.duplicateWorkshop(
                                item.id
                            ).call()
                            await this.fetchItems()
                            this.showSnackBar(
                                this.$_t('actions.duplicate_success')
                            )
                        },
                    },
                ]
            },
        },
        methods: {
            ...mapActions(useGlobalDialog, ['setGlobalDialog']),
            ...mapActions(useGlobalSnackBar, ['showSnackBar']),
            async fetchItems() {
                this.selected = []
                await this.workshops.fetchPaginated({
                    page: 1,
                    limit: 10,
                })
            },
            handleClick(e, { item }) {
                this.$router.push(
                    {
                        name: WorkshopRoutesNames.show,
                        params: { workshop_id: item.id },
                    },
                    this.$route
                )
            },
            alert(...args) {
                this.$refs['table-view'].alert(...args)
            },
            openWorkshopDialog() {
                this.openDialog('dialog-add-workshop')
            },
            goToWorkshop(type) {
                this.$router.push(
                    {
                        name: WorkshopRoutesNames.create,
                        params: { type: type },
                    },
                    this.$route
                )
            },
            async handleMassDelete(ids) {
                try {
                    await WorkshopsProvider.massDeleteWorkshops(ids).call()
                    this.fetchItems()
                } catch (error) {
                    console.error(error)
                }
            },
            closeDialogActions() {
                this.closeDialog('dialog-workshop-delete')
                this.workshopToDelete = null
                this.fetchItems()
            },
        },
        watch: {
            filters: {
                handler() {
                    this.workshops.fetchPaginated() //reload the current page
                },
                deep: true,
            },
        },
    }
</script>

<template>
    <table-view
        ref="table-view"
        :actions="actions"
        :title="$_tc('_name', 2)"
    >
        <ra-table
            v-model="selected"
            :groupedActions="groupedActions"
            :headers="headers"
            :items="workshops"
            :itemActions="passed ? '' : itemActions"
            :loading="workshops.loading"
            :items-length="workshops.total"
            @click:row="handleClick"
            @update:options="workshops.fetchPaginated($event)"
        >
            <template #[`item.is_published`]="{ item }">
                <v-chip
                    class="mr-2 small-published-workshop-chips"
                    variant="outlined"
                >
                    <v-icon
                        :color="item.is_published ? 'success' : 'warning'"
                        class="mr-2"
                        size="x-small"
                    >
                        mdi-circle
                    </v-icon>
                    <ra-typo upper>
                        {{
                            item.is_published
                                ? $t(
                                      'formations.properties.publish.values.published'
                                  )
                                : $t(
                                      'formations.properties.publish.values.draft'
                                  )
                        }}
                    </ra-typo>
                </v-chip>
            </template>
            <template #[`item.date`]="{ item }">
                <span>{{ $filters.formatDate(item.date, 'L') }}</span>
            </template>
            <template #[`item.parameters`]="{ item }">
                {{
                    $t('workshops.parameters.' + item.parameters?.ModeLancement)
                }}
            </template>
            <template #[`item.formations`]="{ item }">
                <formation-ellipsizer
                    :items="item.formations"
                    :count="3"
                />
            </template>
            <template #[`item.apprenants_count`]="{ item }"
                >{{ item.apprenants_count }}
            </template>
        </ra-table>
    </table-view>
    <ra-dialog ref="dialog-add-workshop">
        <ra-base-dialog>
            <template #title>
                {{ $t('workshops.select_section_type') }} :
            </template>
            <ra-stack
                alignement="center"
                gap="4"
                distribution="fill-equally"
                direction="horizontal"
            >
                <v-card @click="goToWorkshop('utilisation')">
                    <ra-stack
                        alignment="center"
                        padding="5"
                        gap="0"
                    >
                        <ra-typo center>
                            <img
                                alt=""
                                height="100px"
                                src="@assets/images/workshop/sensibilisation_vr.png"
                            />
                        </ra-typo>
                        <ra-typo
                            center
                            padding="4,,,"
                        >
                            Session
                        </ra-typo>
                        <ra-typo
                            center
                            bold
                        >
                            {{ $t('workshops.parameters.utilisation') }}
                        </ra-typo>
                    </ra-stack>
                </v-card>
                <v-card @click="goToWorkshop('learner')">
                    <ra-stack
                        alignment="center"
                        padding="5"
                        gap="0"
                    >
                        <ra-typo center>
                            <img
                                alt=""
                                height="100px"
                                src="@assets/images/workshop/formation_vr.png"
                            />
                        </ra-typo>
                        <ra-typo
                            center
                            padding="4,,,"
                        >
                            Session
                        </ra-typo>
                        <ra-typo
                            center
                            bold
                        >
                            {{ $t('workshops.parameters.learner') }}
                        </ra-typo>
                    </ra-stack>
                </v-card>
            </ra-stack>
        </ra-base-dialog>
    </ra-dialog>

    <ra-dialog ref="dialog-workshop-delete">
        <delete-confirm-dialog
            :element="workshopToDelete"
            :message="
                $_t('actions.delete.message', {
                    workshopName: workshopToDelete && workshopToDelete.name,
                })
            "
            :placeholder="$_t('actions.delete.title')"
            :title="$_tc('actions.delete.title', 3)"
            property="name"
            route="/workshops/"
            @close="closeDialogActions"
        />
    </ra-dialog>
</template>
<style scoped>
    .small-published-workshop-chips {
        border-color: transparent !important;
        color: var(--primary) !important;
        height: 25px !important;
    }
</style>
