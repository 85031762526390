import { Structure } from '@libs/Structures/Structure.js'

export class CompanyStructure extends Structure {
    constructor(...args) {
        super(...args)
    }

    get settings() {
        return this._settings
    }

    set settings(value) {
        this._settings = new CompanySettingStructure(value)
    }
}

class CompanySettingStructure extends Structure {
    constructor(...args) {
        super(...args)
    }

    get max_learners_unlimited() {
        return this.max_learners === -1
    }

    set max_learners_unlimited(value) {
        this.max_learners = value ? -1 : 5
    }

    get max_formers_unlimited() {
        return this.max_formers === -1
    }

    set max_formers_unlimited(value) {
        this.max_formers = value ? -1 : 5
    }

    get max_credit_usable_by_learner_unlimited() {
        return this.max_credit_usable_by_learner === -1
    }

    set max_credit_usable_by_learner_unlimited(value) {
        this.max_credit_usable_by_learner = value ? -1 : 5
    }

    get max_devices_unlimited() {
        return this.max_devices === -1
    }

    set max_devices_unlimited(value) {
        this.max_devices = value ? -1 : 5
    }
}
