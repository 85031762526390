const headsetImage = {
    g3: '/images/MDM/PICO_G3.webp',
    g2: '/images/MDM/PICO_G2.webp',
    'quest,3': '/images/MDM/META_QUEST_3.webp',
    'quest,2': '/images/MDM/META_QUEST_2.webp',
    neo: '/images/MDM/PICO_NEO_3.webp',
    'pico,4': '/images/MDM/PICO_4.webp',
    'vive,focus': '/images/MDM/VIVE_FOCUS_3.webp',
}

export class HeadsetImage {
    static getImage(headsetModel) {
        for (let key in headsetImage) {
            let needContains = key.split(',')
            if (
                needContains.every((v) =>
                    headsetModel.toLowerCase().includes(v.toLowerCase())
                )
            ) {
                return headsetImage[key]
            }
        }
        return '/images/MDM/PICO_G3.webp'
    }
}
