<script>
    import { Chart, registerables } from 'chart.js'
    import ChartDataLabels from 'chartjs-plugin-datalabels'

    export default {
        props: {
            data: {
                type: Object,
                required: true,
            },
        },
        mounted() {
            let ctx = this.ctx
            Chart.register(...registerables, ChartDataLabels)
            this.chart = new Chart(ctx, this.chartConfig)
        },
        computed: {
            ctx() {
                return this.$refs.chart.getContext('2d')
            },
            chartConfig() {
                return {
                    type: this.data.type === 'choice' ? 'pie' : 'bar',
                    plugins: [ChartDataLabels],
                    data: {
                        labels: this.data.choices.map((choice) => choice.name),
                        datasets: [
                            {
                                label: this.data.name,
                                data: this.data.choices.map(
                                    (choice) => choice.answer_count
                                ),
                                backgroundColor: this.data.choices.map(
                                    (choice) => choice.color
                                ),
                            },
                        ],
                    },
                    options: {
                        responsive: true,
                        plugins: {
                            legend: {
                                position: 'right',
                            },
                            datalabels: {
                                color: '#fff',
                                display: function (context) {
                                    var dataset = context.dataset
                                    var count = dataset.data.length
                                    var value = dataset.data[context.dataIndex]
                                    return value > count * 0.02
                                },
                            },
                        },
                    },
                }
            },
        },
    }
</script>
<template>
    <div class="pie">
        <canvas
            id="chart"
            ref="chart"
        />
    </div>
</template>
<style lang="scss">
    // .pie should be auto when screen is inf of 1080Px breakpoint media
    .pie {
        height: 400px;
    }
    @media (max-width: 1480px) {
        .pie {
            height: auto;
        }
    }
    @media (max-width: 1080px) {
        .pie {
            height: 400px;
            canvas {
                margin: 0 auto;
            }
        }
    }
</style>
