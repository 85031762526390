import getNestedProperty from '@utils/getNestedProperty.js'

export class TranslationAlias {
    constructor(alias, vue, setup = true) {
        this.vue = vue
        this.alias = alias
        if (setup) this.setup()
    }

    get functions() {
        let self = this
        return {
            t(key, params = {}) {
                return this.$t(self.alias + '.' + key, params)
            },
            tc(key, choice, params) {
                return this.$tc(self.alias + '.' + key, choice, params)
            },
            te(key, locale) {
                return this.$te(self.alias + '.' + key, locale)
            },
        }
    }

    /**
     * @returns {Object<{t:callable,tc:callable,te:callable}>}
     */
    getHelpers(alias = null) {
        let translationAlias = this
        if (alias)
            translationAlias = new TranslationAlias(
                this.alias + '.' + alias,
                this.vue,
                false
            )

        let helpers = {}
        for (let key in translationAlias.functions) {
            helpers[key] = translationAlias.functions[key].bind(this.vue)
        }
        return helpers
    }

    /**
     * Expose functions to vue
     */
    setup() {
        for (const key in this.functions) {
            this.vue['$_' + key] = this.functions[key].bind(this.vue)
        }
    }

    get(key) {
        return getNestedProperty(
            this.vue.$i18n.messages[this.vue.$i18n.locale],
            key
        )
    }
}
