import { Collection } from '@libs/Collections/Collection.js'
import FormationCollection from '@libs/Collections/Formation.js'

export const LICENSE_TYPE = {
    DEMO: 3,
    UNLIMITED_USAGE: 5,
    LIMITED_USAGE: 6,
    UNLIMITED_LEARNER: 7,
    LIMITED_LEARNER: 8,
}
export class LicenseCollection extends Collection {
    get demos() {
        return this.filter((license) => license.type == LICENSE_TYPE.DEMO)
    }

    get fullVersion() {
        return this.filter((license) => license.type != LICENSE_TYPE.DEMO)
    }

    get usable() {
        return this.filter((license) => license.usable)
    }

    get unlimitedUsage() {
        return this.filter(
            (license) => license.type == LICENSE_TYPE.UNLIMITED_USAGE
        )
    }

    get limitedUsage() {
        return this.filter(
            (license) => license.type == LICENSE_TYPE.LIMITED_USAGE
        )
    }

    get unlimitedLearner() {
        return this.filter(
            (license) => license.type == LICENSE_TYPE.UNLIMITED_LEARNER
        )
    }

    get limitedLearner() {
        return this.filter(
            (license) => license.type == LICENSE_TYPE.LIMITED_LEARNER
        )
    }

    get formations() {
        let formations = this.filter((l) => l.usable)
            .map((license) => license.formations)
            .flat()
        return new FormationCollection(formations).unique('id')
    }
}
