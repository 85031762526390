import { adminFormersRoutesMeta } from '@core/router/routes/Former/meta.js'
import { authentificatedRoutesMeta as meta } from './meta.js'
import { MyAccountRoutesName } from '@core/router/routes/Authentificated/MyAccount.names.js'
import { AUTH_TYPE, useUserStore } from '@store/user.js'

export default [
    {
        path: '/my-account',
        component: () => import('@pages/MyAccount.vue'),
        children: [
            {
                path: '',
                name: MyAccountRoutesName.index,
                redirect: { name: MyAccountRoutesName.profile },
                meta,
            },
            {
                path: 'profile',
                name: MyAccountRoutesName.profile,
                meta,
                component: () => {
                    return new Promise(async (resolve) => {
                        const userStore = useUserStore()
                        let importedComponent
                        if (userStore.isApprenant)
                            importedComponent = await import(
                                '@components/Learners/LearnerView.vue'
                            )
                        else
                            importedComponent = await import(
                                '@components/Formers/FormerView.vue'
                            )
                        resolve(importedComponent)
                    })
                },
            },
            {
                path: 'company',
                name: MyAccountRoutesName.company,
                meta: {
                    roles: adminFormersRoutesMeta.roles,
                },
                component: () =>
                    import('@components/Companies/CompanyView.vue'),
            },
        ],
    },
]
