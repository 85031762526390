<script>
import ModuleCard from "@components/FormationsModules/ModuleCard.vue";

export default {
  name: "ModuleGrid",
  components: { ModuleCard },
  props: {
    modules: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<template>
  <ra-box class="module-grid" padding="pb-4">
    <slot>
      <module-card
        v-for="module in modules"
        :key="module.id"
        :formation="module"
        shadow
        @click="$emit('click:module', module)"
      />
    </slot>
  </ra-box>
</template>

<style lang="scss" scoped>
.module-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  grid-gap: 8px 28px;
}
</style>
