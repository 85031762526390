import MyHeadsetIndex from '@pages/My-Headsets/Index.vue'
import MyHeadsetShow from '@pages/My-Headsets/[serialNumber].vue'
import MyHeadsetDrive from '@pages/My-Headsets/Drive.vue'
import MyHeadsetStartSession from '@pages/My-Headsets/Start-Session.vue'
import { HeadsetRoutesNames } from '@core/router/routes/generators/Headset.names.js'

export const headsetsRoutes = (
    namePrefix = '',
    meta = {},
    entry = 'headsets'
) => {
    function publicMeta() {
        if (entry == '/my-headsets') return {}
        else return meta
    }

    return [
        {
            path: entry,
            children: [
                {
                    path: '',
                    name: namePrefix + HeadsetRoutesNames.index,
                    meta: {
                        //authorize no connection to access this route (tablet offline mode)
                        allowTabletApp: entry == '/my-headsets',
                        ...publicMeta(),
                    },
                    component: MyHeadsetIndex,
                },
                {
                    path: ':serialNumber',
                    name: namePrefix + HeadsetRoutesNames.show,
                    meta,
                    component: MyHeadsetShow,
                },
                {
                    path: 'start-session',
                    name: namePrefix + HeadsetRoutesNames.startSession,
                    meta: {
                        allowTabletApp: entry == '/my-headsets',
                        ...publicMeta(),
                    },
                    component: MyHeadsetStartSession,
                },
                {
                    path: 'drive',
                    name: namePrefix + HeadsetRoutesNames.drive,
                    meta: {
                        allowTabletApp: entry == '/my-headsets',
                        ...publicMeta(),
                    },
                    component: MyHeadsetDrive,
                },
            ],
        },
    ]
}
