<script>
    import ApplicationLayout from '@layouts/global/ApplicationLayout.vue'
    import AuthMixin from '@mixins/AuthMixin.js'
    import { mapActions } from 'pinia'
    import { useGlobalLoading } from '@store/globalLoading.js'
    import { Skeletor } from 'vue-skeletor'

    export default {
        components: { ApplicationLayout, Skeletor },
        mixins: [AuthMixin],
        mounted() {
            this.init()
        },
        methods: {
            ...mapActions(useGlobalLoading, ['setLoading']),
            async init() {
                this.setLoading(true)
                await this.loginWithToken(
                    this.$route.params.token,
                    this.$route.params.old_id,
                    () => {
                        this.setLoading(false)
                        if (this.$route.query.next_url) {
                            this.$router.push(this.$route.query.next_url)
                        } else {
                            this.$router.push('/')
                        }
                    }
                )
            },
        },
    }
</script>
<template>
    <div>
        <application-layout>
            <ra-stack gap="6">
                <ra-stack>
                    <Skeletor
                        as="div"
                        height="30"
                        width="50%"
                    />
                    <Skeletor
                        as="div"
                        height="30"
                        width="30%"
                    />
                </ra-stack>
                <ra-stack
                    direction="horizontal"
                    gap="6"
                    mobile-swap
                >
                    <Skeletor
                        as="div"
                        height="400"
                        width="50%"
                    />
                    <Skeletor
                        as="div"
                        height="250"
                        width="50%"
                    />
                </ra-stack>
                <ra-stack
                    direction="horizontal"
                    gap="6"
                    mobile-swap
                >
                    <Skeletor
                        as="div"
                        height="300"
                        width="60%"
                    />
                    <Skeletor
                        as="div"
                        height="300"
                        width="40%"
                    />
                </ra-stack>
            </ra-stack>
        </application-layout>
    </div>
</template>
<style lang="scss">
    .vue-skeletor {
        background-color: var(--primary);
        opacity: 0.35;
        border-radius: 5px;
    }
</style>
