export default function getNestedProperty(obj, propertyPath) {
    const keys = propertyPath.split('.')
    let currentObject = obj
    for (const key of keys) {
        if (
            currentObject &&
            typeof currentObject === 'object' &&
            key in currentObject
        ) {
            currentObject = currentObject[key]
        } else {
            return undefined
        }
    }
    return currentObject
}
