<script>
import LangSelector from "@components/global/Languages/LangSelector.vue";
import { mapActions, mapState } from "pinia";
import { useLanguageStore } from "@store/lang.js";

export default {
  name: "GlobalLanguageSelector",
  components: { LangSelector },
  computed: {
    ...mapState(useLanguageStore, ["lang"]),
    _lang: {
      get() {
        return this.lang;
      },
      set(val) {
        this.setLocale(val);
      },
    },
  },
  methods: {
    ...mapActions(useLanguageStore, ["setLocale"]),
  },
};
</script>

<template>
  <lang-selector v-model="_lang" v-bind="$attrs" />
</template>

<style lang="scss" scoped></style>
