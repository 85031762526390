import Companies from '@core/router/routes/God/Companies.routes.js'
import Application from '@core/router/routes/God/Application.routes.js'
import Content from '@core/router/routes/God/Content.routes.js'
import GodRessources from '@core/router/routes/God/Ressources.routes.js'
import devRoutes from '@routes/God/Dev.routes.js'
export default [
    ...Companies,
    ...Application,
    ...Content,
    ...GodRessources,
    ...devRoutes,
]
