import { Collection } from '@libs/Collections/Collection.js'
import { FormersProvider } from '@providers'
import { PaginatedCollection } from '@libs/Collections/PaginatedCollection.js'

export class FormerCollection extends PaginatedCollection {
    static sortingNameMap = {
        identity: 'firstname',
    }
    constructor(...args) {
        super(...args)
        this.setFetcher(FormersProvider.getFormers.bind(FormersProvider))
    }
}
