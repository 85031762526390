<script>
import PilotageSettings from "@components/Headsets/PilotageSettings.vue";

export default {
  name: "SetFormationSettings",
  components: { PilotageSettings },
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    value: {
      get() {
        return this.settings;
      },
      set(value) {
        this.$emit("update:settings", value);
      },
    },
  },
};
</script>

<template>
  <ra-box center>
    <ra-typo bold uppercase variant="title">
      {{ $t("headsets.pilotage.steps.step1.settings_formation.title_card") }}
    </ra-typo>
  </ra-box>
  <pilotage-settings v-model:settings="value" />
</template>
