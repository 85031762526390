<script>
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import ApplicationLayout from '@layouts/global/ApplicationLayout.vue'
    import Loader from '@components/tools/Loader.vue'
    import WorkshopInscription from '@components/Workshops/WorkshopInscription.vue'
    import { AUTH_TYPE, useUserStore } from '@store/user.js'
    import { useWorkshopStore } from '@store/workshop'
    import { mapActions, mapState } from 'pinia'
    import { WorkshopRoutesNames } from '@core/router/routes/generators/Workshop.names.js'
    import AvatarChip from '@ui/AvatarChip.vue'
    import ShareWorkshopSurveyLink from '@components/Workshops/dialogs/ShareWorkshopSurveyLink.vue'
    import ShareWorkshopInscriptionLink from '@components/Workshops/dialogs/ShareWorkshopInscriptionLink.vue'
    import StatistiquesView from '@components/Stats/StatistiquesView.vue'
    import { calendarLink } from '@utils/calendarLink.js'

    export default {
        name: 'MyWorkshopsIndexPage',
        components: {
            ApplicationLayout,
            Loader,
            WorkshopInscription,
            AvatarChip,
            ShareWorkshopSurveyLink,
            ShareWorkshopInscriptionLink,
            StatistiquesView,
        },
        mixins: [mixins.RaDialogsMixin],
        setup() {
            const workshopStore = useWorkshopStore()
            return { workshopStore }
        },
        data() {
            return {
                workshopRoute: WorkshopRoutesNames,
            }
        },

        computed: {
            ...mapState(useWorkshopStore, ['workshopValue', 'workshopStats']),
            AUTH_TYPE() {
                return AUTH_TYPE
            },
            ...mapState(useUserStore, {
                userType: (state) => state.type,
                company: (state) => state.company,
                user: (state) => state.user,
            }),

            treeDotMenu() {
                return [
                    {
                        icon: 'fa-edit',
                        label: 'Éditer',
                        handler: () => {
                            this.$router.push(
                                {
                                    name: WorkshopRoutesNames.edit,
                                    params: {
                                        workshop_id: this.workshopValue.id,
                                    },
                                },
                                this.$route
                            )
                        },
                    },
                    {
                        icon: 'fa-download',
                        label: 'Exporter les statistiques',
                        visible: !this.workshopValue.status.can_register,
                        handler: () => {
                            if (!this.$refs['statistiques-view-ref']) return
                            this.$refs['statistiques-view-ref'].exportPDF()
                        },
                    },
                    {
                        icon: 'fa-calendar',
                        label: 'Ajouter au calendrier',
                        handler: () => {
                            window.open(
                                calendarLink(
                                    this.workshopValue.name,
                                    this.workshopValue.date,
                                    this.workshopValue.start_time,
                                    this.workshopValue.end_time,
                                    this.workshopValue.description,
                                    this.workshopValue.address,
                                    this.workshopValue.city,
                                    this.workshopValue.state
                                ),
                                '_blank'
                            )
                        },
                    },
                ]
            },
        },
        mounted() {
            this.loadWorkshop(this.$route.params.workshop_id,this.$refs['workshop-loader'])
                .then(() => this.loadStatsWorkshop())
                .catch(() => {
                    this.$router.push({ name: 'workshops.index' })
                })
        },
        methods: {
            ...mapActions(useWorkshopStore, [
                'setWorkshop',
                'loadWorkshop',
                'loadStatsWorkshop',
                'switchWorkshop',
            ]),
            timeOfFormation(formation) {
                return formation.modules.reduce(
                    (acc, module) => acc + module.nb_minutes,
                    0
                )
            },
            openShareSurvey() {
                this.openDialog('dialog-share-survey')
            },
            openShareInscription() {
                this.openDialog('dialog-share-inscription')
            },
        },
    }
</script>

<template>
    <application-layout>
        <loader ref="workshop-loader" :wait-for="workshopValue" >
            <ra-stack>
                <ra-heading-title
                    :title="workshopValue.name"
                    subtitle="Session "
                >
                    <template #actions>
                        <ra-stack
                            direction="horizontal"
                            flex="0 1 150px"
                        >
                            <v-switch
                                v-if="workshopValue.status.can_register"
                                v-model="workshopValue.is_published"
                                :label="
                                    $t(
                                        'workshops.actions.publish'
                                    ).toUpperCase()
                                "
                                color="primary"
                                density="compact"
                                hide-details
                                inset
                                @change="switchWorkshop"
                            />
                        </ra-stack>
                        <v-btn
                            v-if="
                                workshopValue.allow_inscription &&
                                workshopValue.is_published &&
                                workshopValue.status.can_register
                            "
                            variant="plain"
                            @click="openShareInscription"
                        >
                            <v-icon
                                link
                                size="x-large"
                                >mdi-share-variant
                            </v-icon>
                        </v-btn>
                        <ra-box centered-content>
                            <ra-tree-dot-menu :actions="treeDotMenu" />
                        </ra-box>
                    </template>
                </ra-heading-title>
                <hr />
                <ra-spacer />
                <ra-stack>
                    <ra-stack
                        direction="horizontal"
                        distribution="fill-equally"
                        mobile-swap
                    >
                        <ra-card>
                            <ra-stack>
                                <ra-typo>
                                    <v-icon
                                        :color="workshopValue.status.color"
                                        class="mr-2"
                                        size="x-small"
                                    >
                                        mdi-circle
                                    </v-icon>
                                    {{ $t(workshopValue.status.text) }}
                                </ra-typo>
                                <v-img
                                    v-if="workshopValue.custom_logo"
                                    :src="workshopValue.custom_logo.url"
                                    cover
                                    height="100"
                                />
                                <img
                                    v-else-if="
                                        workshopValue.company &&
                                        workshopValue.company.logo
                                    "
                                    :src="workshopValue.company?.logo.url"
                                />
                                <ra-typo
                                    v-else
                                    h3
                                    >{{ workshopValue.company?.name }}</ra-typo
                                >
                                <div>
                                    <ra-typo
                                        bold
                                        padding="1,0"
                                        >{{
                                            $filters.formatDate(
                                                workshopValue.date,
                                                'dddd DD MMMM YYYY'
                                            )
                                        }}</ra-typo
                                    >
                                    <ra-typo padding="1,0">
                                        {{
                                            $filters.formatTime(
                                                workshopValue.start_time
                                            )
                                        }}
                                        -
                                        {{
                                            $filters.formatTime(
                                                workshopValue.end_time
                                            )
                                        }}
                                        •
                                        {{
                                            workshopValue.allow_inscription
                                                ? $t(
                                                      'workshops.with_inscriptions'
                                                  )
                                                : $t(
                                                      'workshops.without_inscriptions'
                                                  )
                                        }}
                                    </ra-typo>
                                    <ra-typo>{{
                                        workshopValue.address
                                    }}</ra-typo>
                                </div>
                            </ra-stack>
                        </ra-card>
                        <ra-card
                            class="full-height-card-body"
                            padding="2"
                        >
                            <ra-stack
                                alignement="center"
                                class="cardinscription"
                                direction="horizontal"
                                distribution="side-spacing"
                                gap="6"
                                height="100%"
                            >
                                <ra-box>
                                    <ra-icon
                                        class="giant-icon"
                                        color="primary-gradient"
                                        >fa-users</ra-icon
                                    ></ra-box
                                >
                                <ra-stack width="auto">
                                    <ra-typo
                                        bold
                                        size="x-large"
                                        span
                                        >{{ workshopValue.nb_apprenants }}
                                    </ra-typo>
                                    <ra-typo
                                        size="large"
                                        span
                                    >
                                        {{
                                            $t('workshops.learners_registered')
                                        }}
                                    </ra-typo>
                                </ra-stack>
                            </ra-stack>
                        </ra-card>
                        <ra-card class="former-card">
                            <template #title>{{
                                $t('formers._name')
                            }}</template>
                            <ra-stack
                                v-if="workshopValue.formateur"
                                alignement="center"
                                direction="horizontal"
                                distribution="side-spacing"
                                gap="4"
                                height="100%"
                            >
                                <ra-box>
                                    <avatar-chip
                                        :user="workshopValue.formateur"
                                        size="100"
                                    />
                                </ra-box>
                                <ra-stack
                                    gap="0"
                                    width="auto"
                                >
                                    <ra-typo
                                        bold
                                        span
                                        >{{
                                            workshopValue.formateur?.firstname
                                        }}
                                        <ra-typo
                                            span
                                            uppercase
                                        >
                                            {{
                                                workshopValue.formateur
                                                    ?.lastname
                                            }}</ra-typo
                                        ></ra-typo
                                    >
                                    <ra-typo span>{{
                                        workshopValue.formateur?.email
                                    }}</ra-typo>
                                </ra-stack>
                            </ra-stack>
                            <loader v-else />
                        </ra-card>
                    </ra-stack>
                    <ra-stack
                        direction="horizontal"
                        mobile-swap
                    >
                        <ra-stack flex="1 1 82%">
                            <workshop-inscription
                                v-if="
                                    workshopValue.allow_inscription &&
                                    workshopValue.status.can_register
                                "
                                :slots="workshopValue.slots"
                                :workshop="workshopValue"
                            />
                            <ra-card>
                                <template #title>{{
                                    $t('workshops.workshop_program')
                                }}</template>
                                <ra-stack padding="4,0,0,0">
                                    <ra-card
                                        v-for="formation in workshopValue.formations"
                                        :key="formation.id"
                                        variant="outlined"
                                    >
                                        <ra-stack
                                            direction="horizontal"
                                            distribution="side-spacing"
                                            mobile-swap
                                        >
                                            <v-img
                                                :src="formation.image.url"
                                                width="30%"
                                            />
                                            <ra-stack flex="1 1 70%">
                                                <ra-typo h3>{{
                                                    formation.title
                                                }}</ra-typo>
                                                <ra-typo span
                                                    >{{
                                                        formation.modules.length
                                                    }}
                                                    {{
                                                        $tc(
                                                            'formations.modules._name',
                                                            formation.modules
                                                                .length
                                                        )
                                                    }}
                                                    •
                                                    {{
                                                        timeOfFormation(
                                                            formation
                                                        )
                                                    }}
                                                    {{
                                                        $tc(
                                                            'generics.minutes',
                                                            formation.nb_minutes
                                                        )
                                                    }}</ra-typo
                                                >
                                                <ra-typo
                                                    p
                                                    size="x-small"
                                                    >{{
                                                        formation.description
                                                    }}</ra-typo
                                                >
                                            </ra-stack>
                                            <ra-stack flex="1 1 10%">
                                                <v-btn
                                                    v-if="
                                                        formation.synthesis_pdf
                                                    "
                                                    link
                                                    size="x-small"
                                                    variant="text"
                                                    target="_blank"
                                                    :href="
                                                        formation.getSynthesisUrl()
                                                    "
                                                >
                                                    {{
                                                        $t(
                                                            'formations.see_synthesis'
                                                        )
                                                    }}
                                                </v-btn>
                                                <v-btn
                                                    v-if="
                                                        formation.educational_guide
                                                    "
                                                    size="x-small"
                                                    variant="text"
                                                    target="_blank"
                                                    :href="
                                                        formation.getEducationalGuideUrl()
                                                    "
                                                >
                                                    {{
                                                        $t(
                                                            'formations.see_educational_guide'
                                                        )
                                                    }}
                                                </v-btn>
                                                <v-btn
                                                    v-if="
                                                        formation.certificate_template
                                                    "
                                                    size="x-small"
                                                    variant="text"
                                                    target="_blank"
                                                    :href="
                                                        formation
                                                            .certificate_template
                                                            .url
                                                    "
                                                >
                                                    <v-icon
                                                        class="mr-2"
                                                        size="small"
                                                    >
                                                        mdi-download
                                                    </v-icon>

                                                    {{
                                                        $t(
                                                            'formations.download_certificate'
                                                        )
                                                    }}
                                                </v-btn>
                                            </ra-stack>
                                        </ra-stack>
                                    </ra-card>

                                    <ra-card variant="outlined">
                                        <ra-stack>
                                            <ra-stack
                                                direction="horizontal"
                                                distribution="side-spacing"
                                                mobile-swap
                                            >
                                                <ra-stack flex="1 1 90%">
                                                    <ra-typo h3>
                                                        {{
                                                            $t(
                                                                'workshops.survey.title'
                                                            )
                                                        }}
                                                    </ra-typo>
                                                    <ra-typo>
                                                        {{
                                                            $t(
                                                                'workshops.see_survey'
                                                            )
                                                        }}
                                                    </ra-typo>
                                                </ra-stack>
                                                <ra-stack flex="1 1 10%">
                                                    <v-btn
                                                        variant="plain"
                                                        @click="openShareSurvey"
                                                    >
                                                        <v-icon
                                                            link
                                                            size="x-large"
                                                            >mdi-share-variant
                                                        </v-icon>
                                                    </v-btn>
                                                    <v-btn
                                                        variant="plain"
                                                        @click="
                                                            $router.push(
                                                                {
                                                                    name: workshopRoute.result,
                                                                },
                                                                $route
                                                            )
                                                        "
                                                    >
                                                        <v-icon
                                                            link
                                                            size="x-large"
                                                            >mdi-eye-outline
                                                        </v-icon>
                                                    </v-btn>
                                                </ra-stack>
                                            </ra-stack>
                                        </ra-stack>
                                    </ra-card>
                                </ra-stack>
                            </ra-card>
                        </ra-stack>
                        <ra-stack
                            v-if="
                                workshopValue.description ||
                                workshopValue.documents.length
                            "
                            flex="1 1 40%"
                        >
                            <ra-card v-if="workshopValue.description">
                                <template #title>{{
                                    $t('workshops.informations')
                                }}</template>
                                <ra-typo padding="5,0,0,0">{{
                                    workshopValue.description
                                }}</ra-typo>
                            </ra-card>
                            <ra-card v-if="workshopValue.documents.length">
                                <template #title>{{
                                    $t('workshops.documents')
                                }}</template>
                                <ul>
                                    <li
                                        v-for="document in workshopValue.documents"
                                        :key="document.id"
                                        class="document"
                                    >
                                        {{ document.original_name }}
                                    </li>
                                </ul>
                            </ra-card>
                        </ra-stack>
                    </ra-stack>
                    <loader
                        v-if="!workshopValue.status.can_register"
                        :wait-for="workshopStats"
                    >
                        <statistiques-view
                            ref="statistiques-view-ref"
                            :company="workshopValue.company"
                            :former="user"
                            :former-scoped="true"
                            :only-formations="
                                workshopValue.formations.map((f) => f.id)
                            "
                            :stats="workshopStats"
                            hide-date
                        />
                    </loader>
                </ra-stack>
            </ra-stack>
            <ra-dialog ref="dialog-share-survey">
                <share-workshop-survey-link :workshop="workshopValue" />
            </ra-dialog>
            <ra-dialog ref="dialog-share-inscription">
                <share-workshop-inscription-link :workshop="workshopValue" />
            </ra-dialog>
        </loader>
    </application-layout>
</template>

<style lang="scss" scoped>
    .giant-icon {
        font-size: 5rem;

        background: var(--primary-gradient);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .former-card {
        display: flex;
        flex-direction: column;
        .v-card-text {
            padding: 0;
        }
    }
    .document {
        padding: 5px 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 250px;
    }
    .cardinscription {
        min-height: 120px;
    }
</style>
