export class Table {
    static storeConfig = '++id'
    static storeName = null //define in child class

    constructor(id, data = null) {
        this.id = id
        if (data) this._patch(data)
    }

    static init(db) {
        if (!this.storeName) throw new Error('storeName is not defined')
        this.db = db
        this.table = db[this.storeName]
    }

    static findAll() {
        return this.table.toCollection().toArray()
    }

    static async findOne(query) {
        let payload = await this.table.where(query).first()
        if (payload?.id) return new this(payload.id, payload)
        return null
    }

    static async create(data) {
        let id = await this.table.add(data)
        return new this(id)
    }

    static async has(query) {
        return Boolean(await this.table.where(query).count())
    }

    async fetch() {
        let data = await this.constructor.table.get(this.id)
        this._patch(data)
        return this
    }

    update(data) {
        return this.constructor.table.update(this.id, data)
    }
    delete() {
        return this.constructor.table.delete(this.id)
    }
}
