import ApiClient from '@api'

export class PWACacheProvider {
    static api = ApiClient.getInstance()

    static getAssets() {
        return this.api.get('/pwa/cached-assets')
    }

    static async loadAssets(assets, onProgress) {
        const batchSize = 10
        onProgress(0, assets.length)
        for (let i = 0; i < assets.length; i += batchSize) {
            const batch = assets.slice(i, i + batchSize)
            const fetches = batch.map(async (asset) => {
                try {
                    await fetch(asset)
                } catch (e) {
                    console.error(e)
                }
            })
            await Promise.all(fetches)
            onProgress(i + batchSize, assets.length)
        }
        onProgress(assets.length, assets.length)
    }
}
