import * as VueRouter from 'vue-router'

import routes, { routesNames } from './routes/index.js'
import { useUserStore } from '@store/user.js'
import { useAppStore } from '@store/app.js'
import { RouteHistory, RouterHistory } from '@core/router/RouterHistory.js'
import scripts from '@router/scripts/export.js'

const router = new VueRouter.createRouter({
    history: VueRouter.createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.meta.scrollPosition) {
            return { x: 0, y: to.meta.scrollPosition }
        }
        if (savedPosition && to) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
})
router.$history = RouterHistory.getInstance(router)
/**
 * @overwrite VueRouter.Router.push
 */
const oldPush = router.push
router.push = function (locationRaw, goBackRoute = null) {
    if (locationRaw?.goBack) {
        goBackRoute = locationRaw.goBack
        delete locationRaw.goBack
    }
    let route = this.resolve(locationRaw)
    if (goBackRoute) {
        RouterHistory.getInstance().set(
            route.name,
            new RouteHistory(route, goBackRoute)
        )
    }
    return oldPush.call(this, locationRaw)
}

router.beforeEach((to, from, next) => {
    function checkRoles(roles, userRole) {
        if (Array.isArray(roles) && userRole) {
            return roles.includes(userRole)
        } else return true
    }

    function executeScript(scriptName, auth) {
        const script = scripts[scriptName]
        return script.call(router, to, from, next, auth)
    }

    const userStore = useUserStore()
    if (!userStore.isReady) return next()
    if (to.matched.length == 0) {
        console.warn('[ROUTER] No route found for', to.path)
        return next(from)
    }
    const appStore = useAppStore()
    if (Array.isArray(to.meta?.roles) && !userStore.isAuth) {
        console.warn('[ROUTER] User is not authenticated')
        return next(routesNames.login)
    }

    if (!checkRoles(to.meta?.roles, userStore.user?.role?.name)) {
        console.warn(
            '[ROUTER] User ' +
                to.meta?.roles +
                ' is not allowed to access this route',
            to
        )
        return next(from)
    }

    if (to.meta.script) {
        let res = executeScript(to.meta.script, userStore)
        if (Boolean(res)) return res
    }

    if (appStore.isTablet) {
        if (!to.meta.allowTabletApp) {
            console.warn('[ROUTER] Tablet is not allowed to access this route')
            return next(routesNames.login)
        }
    }
    return next()
})
export default router
