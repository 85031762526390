<script>
    import PacksFilter from '@components/Packs/PacksFilter.vue'
    import FormationGrid from '@components/Formations/FormationGrid.vue'
    import FormationCollection from '@libs/Collections/Formation.js'
    import { PackCollection } from '@libs/Collections/PackCollection.js'

    export default {
        name: 'HeadsetStartFormation',
        components: { FormationGrid, PacksFilter },
        props: {
            formations: {
                type: FormationCollection,
                default: () => [],
            },
            packs: {
                type: PackCollection,
                default: () => [],
            },
        },
        emits: ['click:formation'],
        data() {
            return {
                packsFilter: [],
            }
        },
        computed: {
            filteredFormation() {
                if (this.packsFilter.length == 0) {
                    return this.formations
                }
                return this.formations.filterByPack(this.packsFilter)
            },
        },
    }
</script>

<template>
    <ra-box center>
        <ra-typo
            bold
            uppercase
            variant="title"
        >
            {{ $t('headsets.pilotage.steps.step1.title_card') }}
        </ra-typo>
    </ra-box>
    <div>
        <packs-filter
            v-model:selected="packsFilter"
            :packs="packs"
        />
        <ra-spacer />
        <hr />
    </div>
    <formation-grid
      v-if="filteredFormation.length > 0"
        :formations="filteredFormation"
        @click:formation="(...args) => $emit('click:formation', ...args)"
    />
    <ra-typo v-else center italic>{{ $t('headsets.pilotage.steps.step1.no_formation') }}</ra-typo>
</template>
