<script>
    import MDMHeader from '@components/Headsets/Pilotage/MDMHeader.vue'
    import ApplicationLayout from '@layouts/global/ApplicationLayout.vue'
    import StartFormationProgress from '@components/Headsets/StartFormation/StartFormationProgress.vue'

    export default {
        components: {
            StartFormationProgress,
            ApplicationLayout,
            MDMHeader,
        },
        name: 'HeadsetStreamLayout',
        emits: ['back'],
    }
</script>

<template>
    <web>
        <ApplicationLayout>
            <ra-box
                class="headset-stream-layout"
                height="100%"
            >
                <start-formation-progress
                    :current-step="2"
                    @back="$emit('back')"
                />
                <ra-stack
                    alignement="leading"
                    class="web-stack-layout"
                    direction="horizontal"
                    distribution="fill"
                    gap="5"
                    mobile-swap
                >
                    <ra-stack
                        bg="white"
                        padding="7,"
                        rounded
                    >
                        <slot name="aside"></slot>
                    </ra-stack>
                    <ra-box
                        height="100%"
                        rounded
                    >
                        <slot></slot>
                    </ra-box>
                </ra-stack>
            </ra-box>
        </ApplicationLayout>
    </web>
    <tablet>
        <div class="headset-stream-layout application-layout">
            <tablet>
                <aside>
                    <ra-drawer>
                        <MDMHeader />
                        <ra-box padding="5,">
                            <slot name="aside"></slot>
                        </ra-box>
                    </ra-drawer>
                </aside>
            </tablet>

            <main>
                <slot></slot>
            </main>
        </div>
    </tablet>
</template>

<style lang="scss">
    [data-device='tablet'] .headset-stream-layout {
        display: flex;
    }

    .headset-stream-layout .ra-list-item:first-child {
        border-top: none;
    }

    [data-device='web'] .headset-stream-layout {
        @media screen and (max-width: 760px) {
            height: auto !important;
            .web-stack-layout {
                & > .ra-stack {
                    flex: 1 1 auto;
                }
            }
        }
    }

    .web-stack-layout {
        height: 90%;

        & > .ra-stack {
            border: solid 0.5px rgba(9, 9, 84, 0.39);
            &:first-child {
                flex: 0 0 350px;
            }
        }
    }
</style>
