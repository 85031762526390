<script>
    import { HEADSET_ACTIONS } from '@libs/MDM/core/Headset.js'
    import { useMDMStore } from '@store/mdm.js'
    import { mapActions, mapState } from 'pinia'
    import HeadsetStreamLayout from '@layouts/MDM/HeadsetStreamLayout.vue'
    import HeadsetsList from '@components/Headsets/HeadsetsList.vue'
    import ScreensOptions from '@components/Headsets/Pilotage/ScreensOptions.vue'
    import HeadsetVideoStream from '@components/Headsets/Pilotage/HeadsetVideoStream.vue'
    import StopStreamDialog from '@components/Headsets/Pilotage/StopStreamDialog.vue'
    import BatteryLevel from '@components/Headsets/BatteryLevel.vue'
    import ConnectedStatus from '@components/Headsets/ConnectedStatus.vue'
    import AsideHeadsetList from '@components/Headsets/Pilotage/PilotageAsideHeadsetList.vue'
    import PilotageAsideHeadsetList from '@components/Headsets/Pilotage/PilotageAsideHeadsetList.vue'
    import MDMHeader from '@components/Headsets/Pilotage/MDMHeader.vue'
    import { useAppStore } from '@store/app.js'
    import { useGlobalDialog } from '@store/globalDialog.js'
    import { HeadsetStreamEvents } from '../../libs/MDM/core/HeadsetStream.js'

    export default {
        components: {
            MDMHeader,
            PilotageAsideHeadsetList,
            AsideHeadsetList,
            HeadsetStreamLayout,
            HeadsetsList,
            ScreensOptions,
            HeadsetVideoStream,
            StopStreamDialog,
            BatteryLevel,
            ConnectedStatus,
        },
        data() {
            return {
                HEADSET_ACTIONS,
                asideDrawerOpen: true,
                isFullScreen: false,
                streamedHeadsets: [],
                screens: 1,
                casting_started: false,
            }
        },
        computed: {
            ...mapState(useMDMStore, ['syncedHeadsets', 'headsetsAppDriven']),
            ...mapState(useAppStore, ['isTablet']),
        },

        mounted() {
            if (this.headsetsAppDriven.length === 0) {
                this.goBack()
            } else {
                this.streamedHeadsets = this.headsetsAppDriven.slice(0, 4)
            }
        },

        unmounted() {
            this.streamedHeadsets.forEach((h) => h?.stream.close())
            this.streamedHeadsets = []
            this.emptyHeadsetsAppDriven()
        },
        methods: {
            ...mapActions(useGlobalDialog, ['setGlobalDialog']),
            ...mapActions(useMDMStore, ['emptyHeadsetsAppDriven']),
            async wait(sec) {
                return new Promise((resolve) => setTimeout(resolve, sec * 1000))
            },
            goBack() {
                if (this.$route.query?.back) {
                    this.$router.push(this.$route.query.back)
                } else {
                    this.$router.push('/my-headsets')
                }
            },
            async toggleStream(confirm = false) {
                if (this.casting_started) {
                    if (!confirm) {
                        this.setGlobalDialog({
                            title: this.$t(
                                'headsets.pilotage.casting.stop_confirm.title'
                            ),
                            message: this.$t(
                                'headsets.pilotage.casting.stop_confirm_message'
                            ),
                            positiveButton: {
                                action: () => {
                                    this.toggleStream(true)
                                },
                            },
                        })
                        return
                    }
                    this.casting_started = false
                    this.streamedHeadsets = []
                    this.emptyHeadsetsAppDriven()
                    this.goBack()
                } else {
                    this.casting_started = true
                    this.streamedHeadsets.forEach((h) => h?.stream.start())
                }
            },
        },

        watch: {
            isFullScreen(c) {
                if (!c) this.asideDrawerOpen = true
                this.$nextTick(() => {
                    this.streamedHeadsets.forEach((headset) => {
                        headset.stream.resize()
                    })
                })
            },
            headsetsAppDriven(headsets) {
                if (headsets.length == 0) this.goBack()
            },
            async streamedHeadsets(headsets) {
                this.screens = Math.min(headsets.length, 4)
                if (this.screens === 3) this.screens = 4

                let notStreamedHeadsets = this.syncedHeadsets.filter(
                    (h) => !headsets.includes(h)
                )
                await Promise.all(
                    notStreamedHeadsets.map((h) => h?.stream.close())
                )
                if (headsets.length) {
                    this.casting_started = true
                    let i = 0
                    for (let headset of this.streamedHeadsets) {
                        setTimeout(() => {
                            headset?.stream.start()
                            headset?.stream?.setEventBindCallback((event) => {
                                if (event == HeadsetStreamEvents.CLOSE) {
                                    this.streamedHeadsets =
                                        this.streamedHeadsets.filter(
                                            (h) =>
                                                h.serialNumber !=
                                                headset.serialNumber
                                        )
                                }
                            })
                        }, 300 * i)
                        i++
                    }
                } else {
                    this.casting_started = false
                }
            },
        },
    }
</script>

<template>
    <HeadsetStreamLayout @back="toggleStream">
        <template #aside>
            <ra-stack
                :class="[
                    isFullScreen ? 'aside-fullscreen' : '',
                    asideDrawerOpen
                        ? 'aside-fullscreen-open'
                        : 'aside-fullscreen-close',
                ]"
                :gap="isFullScreen ? '0' : '6'"
                alignement="scretch"
            >
                <MDMHeader
                    v-if="isFullScreen"
                    with-close
                    @click="asideDrawerOpen = false"
                />
                <ra-stack
                    :padding="isFullScreen ? '5,' : ''"
                    alignement="scretch"
                >
                    <pilotage-aside-headset-list
                        v-model:streamed-headsets="streamedHeadsets"
                        :headsets-app-driven="headsetsAppDriven"
                        @click="toggleStream"
                    />
                </ra-stack>
            </ra-stack>
        </template>

        <div
            :class="[
                screens == 1 ? 'one-screen' : '',
                screens > 2 ? 'two-line' : '',
                isFullScreen ? 'screen-grid-fullscreen' : '',
                asideDrawerOpen ? 'screen-grid-fullscreen-with-aside' : '',
            ]"
            class="screen-grid"
        >
            <!-- headset selected streams -->
            <HeadsetVideoStream
                v-for="headset in streamedHeadsets"
                :key="headset.serialNumber"
                :ref="'stream-' + headset.serialNumber"
                :headset="headset"
            />
            <!-- No headset selected streams -->
            <HeadsetVideoStream
                v-for="i in screens - streamedHeadsets.length"
                :key="i"
                :headset="null"
            />
            <v-btn
                v-if="!isTablet"
                :icon="isFullScreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen'"
                class="fullscreen"
                @click="isFullScreen = !isFullScreen"
            />
            <v-btn
                v-if="!asideDrawerOpen"
                class="menu-btn"
                icon="mdi-menu"
                @click="asideDrawerOpen = true"
            />
        </div>
    </HeadsetStreamLayout>
</template>

<style lang="scss">
    .aside-fullscreen {
        background: white;
        width: 350px;
        position: fixed;
        z-index: 100;
        left: 0;
        bottom: 0;
        top: 0;

        &.aside-fullscreen-close {
            transform: translateX(-100%);
            pointer-events: none;
        }
    }

    [data-device='web'] .screen-grid {
        position: relative;
    }

    .screen-grid {
        display: grid;
        grid-template-rows: repeat(2, calc(50% - 1.5px));
        grid-template-columns: repeat(1, 1fr);
        height: 100%;
        gap: 3px;

        &-fullscreen {
            position: absolute !important;
            inset: 0;
            z-index: 20;
            [data-device='web'] & {
                background: #f3f3ff;
            }

            &.screen-grid-fullscreen-with-aside {
                left: 350px;
            }

            &:not(.screen-grid-fullscreen-with-aside) {
                .headset-video-stream-container:first-child
                    .headset-stream-controls {
                    left: 5em;
                }

                .menu-btn {
                    position: absolute;
                    top: 1em;
                    left: 1em;
                    z-index: 20;
                }
            }
        }

        &.one-screen {
            grid-template-rows: repeat(1, 100%);
            grid-template-columns: repeat(1, 1fr);
        }
        &.two-line {
            grid-template-columns: repeat(2, 1fr);
        }
    }
</style>
