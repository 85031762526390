import { godRoutesMeta as meta } from '@core/router/routes/God/meta.js'
import { ApplicationRoutesNames } from '@core/router/routes/God/Application.names.js'

export default [
    {
        path: '/application',
        component: () => import('@pages/Application/ApplicationPage.vue'),
        children: [
            {
                path: '',
                name: ApplicationRoutesNames.index,
                redirect: {
                    name: ApplicationRoutesNames.releases.index,
                },
                meta,
            },
            {
                path: 'releases',
                children: [
                    {
                        path: '',
                        name: ApplicationRoutesNames.releases.index,
                        meta,
                        component: () =>
                            import('@pages/Application/ReleasePage.vue'),
                    },
                    {
                        path: ':id',
                        name: ApplicationRoutesNames.releases.show,
                        meta,
                    },
                ],
            },
            {
                path: 'platforms',
                children: [
                    {
                        path: '',
                        name: ApplicationRoutesNames.platforms.index,
                        meta,
                        component: () =>
                            import('@pages/Application/PlatformPage.vue'),
                    },
                    {
                        path: ':id',
                        name: ApplicationRoutesNames.platforms.show,
                        meta,
                    },
                ],
            },
        ],
    },
]
