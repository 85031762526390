<script>
    import { TranslationAlias } from '@core/i18n/TranslationAlias.js'
    import {
        workshopFormInputs,
        workshopCompanyInputs,
        workshopRangeInputs,
        workshopRangeDurationInputs,
        workshopDocumentInputs,
        workshopCustomCompanyInputs,
    } from '@components/Workshops/WorkshopForm.inputs.js'

    import { mapActions, mapWritableState } from 'pinia'
    import WorkshopFormationAddForm from '@components/Workshops/WorkshopFormationAddForm.vue'
    import WorkshopFormationChooseModule from '@components/Workshops/WorkshopFormationChooseModule.vue'
    import ApplicationLayout from '@layouts/global/ApplicationLayout.vue'
    import { useUserStore } from '@store/user.js'
    import { useWorkshopStore } from '@store/workshop'

    import { CompaniesProvider } from '@providers/CompaniesProvider.js'
    import { CompanyCollection } from '@libs/Collections/Company.js'

    import { FormersProvider } from '@providers/FormersProvider.js'
    import { FormerCollection } from '@libs/Collections/FormerCollection.js'

    import FormationCollection from '@libs/Collections/Formation.js'
    import { LicenseCollection } from '@libs/Collections/LicenseCollection.js'

    import Loader from '@components/tools/Loader.vue'

    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import withUser from '@mixins/withUser.js'
    import { WorkshopRoutesNames } from '@core/router/routes/generators/Workshop.names.js'
    import { WorkshopStructure } from '@libs/Structures/WorkshopStructure.js'
    import FormerAddForm from '@components/Formers/FormerAddForm.vue'
    import AvatarChip from '@ui/AvatarChip.vue'

    export default {
        name: 'MyWorkshopsCreatePage',
        components: {
            ApplicationLayout,
            AvatarChip,
            Loader,
            WorkshopFormationAddForm,
            WorkshopFormationChooseModule,
            FormerAddForm,
        },
        mixins: [mixins.RaDialogsMixin, withUser],
        data() {
            return {
                translationAlias: new TranslationAlias('formers', this),
                companies: new CompanyCollection(),
                formers: new FormerCollection(),
                formations: new FormationCollection(),
                licenses: new LicenseCollection(),
                company: null,
                selectedFormationsId: [],
                selectedFormation: null,
                selectedModules: null,
                loadingWorkshop: false,
            }
        },
        computed: {
            ...mapWritableState(useWorkshopStore, ['workshopItem', 'error']),
            inputs() {
                return workshopFormInputs.call(this, this.company, false)
            },
            inputsRange() {
                return workshopRangeInputs.call(this)
            },
            inputsCompany() {
                return workshopCompanyInputs.call(this)
            },
            inputsRangeDuration() {
                return workshopRangeDurationInputs.call(this)
            },
            inputsDocument() {
                return workshopDocumentInputs.call(this)
            },
            inputsCustomCompany() {
                return workshopCustomCompanyInputs.call(this)
            },
            selectedFormations() {
                if (this.workshopItem.formations.length > 0) {
                    return this.workshopItem.formations.map((wf) =>
                        this.formations.find((f) => f.id === wf.id)
                    )
                }
            },
        },
        watch: {
            workshopItem: {
                deep: true,
                handler(wrk) {
                    if (wrk.start_time && this.workshopItem.slots[0]) {
                        this.workshopItem.slots[0].start_time = wrk.start_time
                    }
                    if (wrk.company) {
                        if (wrk.company.id !== this.workshopItem.company_id) {
                            this.workshopItem.formateur = null
                            this.workshopItem.formateur_id = null
                            this.workshopItem.company_id = wrk.company.id
                            this.getFormers(wrk.company.id)
                            this.getCompanyAvailableFormations(wrk.company.id)
                        }
                    }
                    if (wrk.formateur) {
                        if (
                            wrk.formateur.id !== this.workshopItem.formateur_id
                        ) {
                            this.workshopItem.formateur_id = null
                            this.workshopItem.formateur_id = wrk.formateur.id
                        }
                    }
                },
            },
        },
        mounted() {
            if (this.$route.params.workshop_id) {
                this.loadingWorkshop = true
                this.loadWorkshop(this.$route.params.workshop_id).then(() => {
                    this.loadingWorkshop = false
                    this.getFormers(this.workshopItem.company.id)
                    this.getCompanyAvailableFormations(
                        this.workshopItem.company.id
                    )
                })
            }
        },
        created() {
            this.init()
        },
        methods: {
            ...mapActions(useWorkshopStore, [
                'saveWorkshop',
                'loadWorkshop',
                'resetWorkshop',
                'updateWorkshop',
            ]),
            ...mapActions(useUserStore, ['hasRole']),
            openFormationAddFormDialog() {
                this.openDialog('dialog-add-formation')
            },
            getNumberOfModules(formationId) {
                const formation = this.workshopItem.formations.find(
                    (f) => f.id === formationId
                )
                return formation ? formation.modules.length : null
            },
            openFormerAddFormDialog() {
                this.openDialog('dialog-add-former')
            },
            openFormationChoseModuleDialog(selected_formation) {
                this.selectedFormation = selected_formation
                this.selectedModules =
                    this.workshopItem.formations.find(
                        (formation) => formation.id === selected_formation.id
                    )?.modules || null
                this.openDialog('dialog-choose-module-formation')
            },
            async getCompanies() {
                this.companies.setFetcher(() => {
                    return CompaniesProvider.getCompanies({
                        vr: true,
                        limit: -1,
                        page: 1,
                        logo: true,
                    })
                })
                await this.companies.fetchPaginated({
                    page: 1,
                    limit: 10,
                })
            },
            async getFormers(company_id) {
                this.formers.setFetcher((query) => {
                    return FormersProvider.getFormers(company_id)
                })
                await this.formers.fetchPaginated({
                    page: 1,
                    limit: 10,
                })
            },
            async getCompanyAvailableFormations(company_id) {
                const { data } =
                    await CompaniesProvider.getCompanyAvailableFormations(
                        company_id
                    ).call()
                this.formations = data
            },
            async init() {
                this.resetWorkshop()
                const userStore = useUserStore()
                if (userStore.hasRole(['god', 'super_god'])) {
                    this.getCompanies()
                } else if (userStore.hasRole['admin']) {
                    this.getFormers(userStore.company.id)
                    this.getCompanyAvailableFormations(userStore.company.id)
                } else {
                    this.formers.set([userStore.user])
                    this.workshopItem.formateur = userStore.user
                    this.getCompanyAvailableFormations(userStore.company.id)
                }
                this.company = userStore.company

                // Set itemWorkshop params
                this.workshopItem.parameters = {
                    ModeLancement: this.$route.params.type
                        ? this.$route.params.type
                        : this.$t('workshops.parameters.utilisation'),
                }
            },
            addFormations(transformedFormations) {
                this.selectedFormationsId = []
                this.workshopItem.formations = transformedFormations
                this.closeDialog('dialog-add-formation')
            },
            addModules(selectedModules, formation_id) {
                const updatedFormation = this.workshopItem.formations.find(
                    (formation) => formation.id === formation_id
                )
                updatedFormation.modules = []
                selectedModules.forEach((module) => {
                    if (updatedFormation) {
                        updatedFormation.modules.push({ id: module })
                    }
                })
                this.closeDialog('dialog-choose-module-formation')
                this.selectedFormation = null
                this.selectedModules = null
            },
            removeSelectedCompany() {
                this.workshopItem.company = null
                this.workshopItem.company_id = null
                this.workshopItem.formations = []
                this.workshopItem.formateur_id = null
            },
            removeSelectedFormateur() {
                this.workshopItem.formateur_id = null
                this.workshopItem.formateur = null
            },
            async save() {
                try {
                    if (this.workshopItem.id) {
                        const workshop = await this.updateWorkshop()
                    } else {
                        const workshop = await this.saveWorkshop()
                    }
                    this.$router.push({
                        name: WorkshopRoutesNames.show,
                        params: { workshop_id: this.workshopItem.id },
                    })
                } catch (e) {
                    console.log(e.errors)
                }
            },
        },
    }
</script>

<template>
    <application-layout>
        <ra-heading-title
            subtitle="Configuration"
            :title="
                $route.params.type == 'learner'
                    ? $t('workshops.parameters.learner')
                    : $t('workshops.parameters.utilisation')
            "
        >
            <template #actions>
                <ra-stack
                    direction="horizontal"
                    flex="0 1 330px"
                >
                    <v-switch
                        v-model="workshopItem.is_published"
                        :label="$t('workshops.actions.publish').toUpperCase()"
                        color="primary"
                        density="compact"
                        hide-details
                        inset
                    />
                    <v-btn
                        color="secondary"
                        @click="save"
                        >{{ $t('workshops.actions.save') }}</v-btn
                    >
                </ra-stack>
            </template>
        </ra-heading-title>
        <ra-stack
            direction="horizontal"
            gap="8"
            mobile-swap
        >
            <loader :wait-for="!loadingWorkshop">
                <ra-stack
                    flex="1 1 60%"
                    gap="8"
                    mobile-swap
                >
                    <ra-card>
                        <template #title>Informations Session</template>
                        <template #right>
                            <div>
                                <ra-typo bold>
                                    <v-icon
                                        :color="
                                            workshopItem.is_published
                                                ? 'success'
                                                : 'warning'
                                        "
                                        class="mr-2"
                                        size="x-small"
                                    >
                                        mdi-circle
                                    </v-icon>
                                    {{
                                        workshopItem.is_published
                                            ? $t(
                                                  'formations.properties.publish.values.published'
                                              )
                                            : $t(
                                                  'formations.properties.publish.values.draft'
                                              )
                                    }}
                                </ra-typo>
                            </div>
                        </template>
                        <ra-box>
                            <ra-form
                                :error="error"
                                :autosubmit="false"
                                :element="workshopItem"
                                :inputs="inputs"
                            >
                                <template
                                    #end
                                    v-if="$route.params.type == 'learner'"
                                >
                                    <ra-typo>
                                        {{ $t('workshops.learners_info') }}
                                    </ra-typo>
                                </template>
                            </ra-form>
                        </ra-box>
                        <ra-box v-if="workshopItem.allow_inscription">
                            <ra-stack width="75%">
                                <ra-form
                                    class="creneau"
                                    :autosubmit="false"
                                    :element="workshopItem"
                                    :inputs="inputsRange"
                                />
                                <ra-form
                                    :autosubmit="false"
                                    :element="workshopItem"
                                    :inputs="inputsRangeDuration"
                                />
                            </ra-stack>
                        </ra-box>
                    </ra-card>
                    <ra-card>
                        <template #title>{{
                            $t('workshops.workshop_program')
                        }}</template>

                        <ra-box padding="8,">
                            <ra-stack
                                gap="8"
                                mobile-swap
                            >
                                <ra-box
                                    padding="4"
                                    shadow
                                    rounded
                                    class="border"
                                >
                                    <ra-typo
                                        margin=",,4,"
                                        h3
                                    >
                                        {{ $t('formations._name') }}
                                    </ra-typo>
                                    <v-btn
                                        @click="openFormationAddFormDialog"
                                        :disabled="!workshopItem.company_id"
                                        variant="tonal"
                                        width="100%"
                                    >
                                        +
                                        {{
                                            $t(
                                                'workshops.properties.formation.label'
                                            )
                                        }}
                                    </v-btn>
                                    <ra-input
                                        name="formations"
                                        :errors="error.errors"
                                    >
                                    </ra-input>
                                </ra-box>
                                <ra-box
                                    v-if="selectedFormations?.length > 0"
                                    v-for="selectedFormation in selectedFormations"
                                    padding="4"
                                    shadow
                                    rounded
                                    class="border"
                                >
                                    <ra-stack
                                        distribution="side-spacing"
                                        direction="horizontal"
                                        mobile-swap
                                        v-if="selectedFormation"
                                    >
                                        <v-img
                                            :src="selectedFormation.image?.url"
                                            width="50px"
                                        />
                                        <ra-stack flex="1 1 60%">
                                            <ra-typo h3>
                                                {{ selectedFormation.name }}
                                            </ra-typo>
                                            <ra-typo
                                                span
                                                bold
                                                color="secondary"
                                                >{{
                                                    selectedFormation.nb_modules
                                                }}
                                                {{
                                                    $tc(
                                                        'formations.modules._name',
                                                        selectedFormation.nb_modules
                                                    )
                                                }}
                                                <template
                                                    v-if="
                                                        getNumberOfModules(
                                                            selectedFormation.id
                                                        ) > 0
                                                    "
                                                >
                                                    (
                                                    {{
                                                        getNumberOfModules(
                                                            selectedFormation.id
                                                        )
                                                    }}
                                                    {{
                                                        $tc(
                                                            'generics._selected',
                                                            getNumberOfModules(
                                                                selectedFormation.id
                                                            )
                                                        )
                                                    }}
                                                    )
                                                </template>
                                                •
                                                {{
                                                    selectedFormation.nb_minutes
                                                }}
                                                {{
                                                    $tc(
                                                        'generics.minutes',
                                                        selectedFormation.nb_minutes
                                                    )
                                                }}</ra-typo
                                            >
                                            <ra-typo
                                                p
                                                size="x-small"
                                                >{{
                                                    selectedFormation.description
                                                }}</ra-typo
                                            >
                                        </ra-stack>
                                        <ra-stack flex="1 1 10%">
                                            <v-btn
                                                @click="
                                                    openFormationChoseModuleDialog(
                                                        selectedFormation
                                                    )
                                                "
                                                >{{
                                                    $t(
                                                        'workshops.actions.chooseModule'
                                                    )
                                                }}
                                            </v-btn>
                                        </ra-stack>
                                    </ra-stack>
                                </ra-box>
                                <ra-box
                                    padding="4"
                                    shadow
                                    rounded
                                    class="border"
                                >
                                    <ra-stack direction="horizontal">
                                        <ra-stack>
                                            <ra-typo
                                                margin=",,4,"
                                                h3
                                            >
                                                {{
                                                    $t('workshops.survey.title')
                                                }}
                                            </ra-typo>
                                            <ra-typo p>
                                                {{
                                                    $t(
                                                        'workshops.survey.explication'
                                                    )
                                                }}
                                            </ra-typo>
                                        </ra-stack>
                                        <v-switch
                                            v-model="workshopItem.end_survey"
                                            density="compact"
                                            hide-details
                                            inset
                                        />
                                    </ra-stack>
                                </ra-box>
                            </ra-stack>
                        </ra-box>
                    </ra-card>
                </ra-stack>
                <ra-stack
                    flex="1 1 40%"
                    gap="8"
                    mobile-swap
                >
                    <ra-card>
                        <template #title>{{
                            $t('workshops.company_information')
                        }}</template>
                        <template
                            v-if="
                                workshopItem.company &&
                                hasRole(['god', 'super_god'])
                            "
                            #right
                            ><v-btn
                                icon="mdi-pencil"
                                flat
                                @click="removeSelectedCompany"
                            ></v-btn
                        ></template>
                        <loader
                            :wait-for="companies"
                            v-if="!workshopItem.company"
                        >
                            <ra-stack padding="5">
                                <ra-input
                                    name="company_id"
                                    :errors="error.errors"
                                >
                                    <v-autocomplete
                                        label="Clients"
                                        :items="companies"
                                        item-title="name"
                                        item-value="id"
                                        return-object
                                        variant="outlined"
                                        v-model="workshopItem.company"
                                    />
                                </ra-input>
                            </ra-stack>
                        </loader>
                        <ra-stack
                            v-else
                            padding="5"
                        >
                            <v-img
                                :src="workshopItem.company.logo.url"
                                v-if="workshopItem.company?.logo"
                            />
                            <ra-typo
                                h2
                                center
                                >{{ workshopItem.company?.name }}
                            </ra-typo>
                            <ra-typo
                                center
                                v-if="hasRole(['god', 'super_god'])"
                                >{{ workshopItem.company?.user?.firstname }}
                                {{ workshopItem.company?.user?.lastname }}
                            </ra-typo>
                            <ra-typo v-if="hasRole(['god', 'super_god'])">
                                <ra-typo
                                    span
                                    bold
                                >
                                    {{ $t('users.properties.tel.label') }}:
                                </ra-typo>
                                {{ workshopItem.company?.user?.phone }}
                            </ra-typo>
                            <ra-typo v-if="hasRole(['god', 'super_god'])">
                                <ra-typo
                                    span
                                    bold
                                >
                                    Email:
                                </ra-typo>
                                {{ workshopItem.company?.user?.email }}
                            </ra-typo>
                        </ra-stack>
                        <ra-stack>
                            <hr />
                            <ra-form
                                :autosubmit="false"
                                :element="workshopItem"
                                :inputs="inputsCustomCompany"
                            ></ra-form>
                        </ra-stack>
                    </ra-card>
                    <ra-card v-if="workshopItem.company">
                        <template #title>{{ $t('formers._name') }}</template>

                        <template
                            v-if="
                                workshopItem.formateur &&
                                hasRole(['god', 'super_god', 'admin'])
                            "
                            #right
                            ><v-btn
                                icon="mdi-pencil"
                                flat
                                @click="removeSelectedFormateur"
                            ></v-btn
                        ></template>
                        <loader
                            :wait-for="formers"
                            v-if="!workshopItem.formateur"
                        >
                            <ra-stack padding="5">
                                <ra-input
                                    name="formateur_id"
                                    :errors="error.errors"
                                >
                                    <v-autocomplete
                                        :label="$tc('formers._name', 2)"
                                        :items="formers"
                                        item-title="lastname"
                                        item-value="id"
                                        return-object
                                        variant="outlined"
                                        v-model="workshopItem.formateur"
                                    />
                                </ra-input>
                                <v-btn
                                    @click="openFormerAddFormDialog"
                                    variant="tonal"
                                    width="100%"
                                >
                                    {{ $t('formers.actions.add') }}
                                </v-btn>
                            </ra-stack>
                        </loader>
                        <ra-stack
                            v-else
                            padding="5"
                            direction="horizontal"
                        >
                            <ra-box style="margin-right: 20px">
                                <avatar-chip
                                    :user="workshopItem.formateur"
                                    size="75"
                                />
                            </ra-box>
                            <ra-stack>
                                <ra-typo h2
                                    >{{ workshopItem.formateur.firstname }}
                                    {{
                                        workshopItem.formateur.lastname
                                    }}</ra-typo
                                >
                                <ra-typo
                                    ><ra-typo
                                        span
                                        bold
                                        >Email:</ra-typo
                                    >
                                    {{ workshopItem.formateur.email }}</ra-typo
                                >
                            </ra-stack>
                        </ra-stack>
                    </ra-card>
                    <ra-card>
                        <template #title>Documents</template>
                        <ra-stack padding="5">
                            <ra-form
                                :autosubmit="false"
                                :element="workshopItem"
                                :inputs="inputsDocument"
                            ></ra-form>
                        </ra-stack>
                    </ra-card>
                </ra-stack>
            </loader>
        </ra-stack>

        <ra-dialog ref="dialog-add-formation">
            <workshop-formation-add-form
                v-model="selectedFormationsId"
                :formations="formations"
                @update:modelValue="addFormations"
            ></workshop-formation-add-form>
        </ra-dialog>

        <ra-dialog ref="dialog-add-former">
            <former-add-form
                :company="workshopItem.company"
                @added="getFormers(workshopItem.company.id)"
            />
        </ra-dialog>
        <ra-dialog ref="dialog-choose-module-formation">
            <workshop-formation-choose-module
                v-if="selectedFormation"
                v-model="selectedModules"
                :formation="selectedFormation"
                @update:modelValue="addModules"
            ></workshop-formation-choose-module>
        </ra-dialog>
    </application-layout>
</template>

<style lang="scss">
    .creneau {
        .related_item_row {
            align-items: end;
            .all-height {
                height: 50px;
                margin: 11px 11px;
            }
        }
    }
    .after-input-dot .v-input__control {
        &::after {
            content: '•';
            display: inline-block;
            font-size: 35px;
            margin-left: 10px;
        }
    }
</style>
