<script>
    import { mapState } from 'pinia'
    import { useWorkshopStore } from '@store/workshop'
    import { mixins } from '@realityteam/reality-academy-ui-kit'

    export default {
        name: 'WorkshopDropDownMenu',
        mixins: [mixins.RaDialogsMixin],
        computed: {
            ...mapState(useWorkshopStore, ['workshopValue']),
            items() {
                return [
                    {
                        title: this.$t('workshops.share'),
                        icon: 'mdi-share-variant-outline',
                        visible: true,
                        handler: () => this.openShareDialog(),
                    },
                    {
                        title: this.$t('workshops.share'),
                        icon: 'mdi-share-variant-outline',
                        visible: this.workshopValue?.allow_inscription,
                        handler: () => this.openShareDialogInscription,
                    },
                ]
            },
            visibleItems() {
                return this.items.filter((item) => item.visible)
            },
        },
        methods: {
            openShareDialog() {
                this.openDialog('dialog-share')
            },
        },
    }
</script>

<template>
    <v-menu>
        <template v-slot:activator="{ props }">
            <v-btn
                variant="plain"
                icon="mdi-share-variant-outline"
                v-bind="props"
                class="mr-2"
                size="small"
            />
        </template>
        <v-list>
            <v-list-item
                v-for="(item, index) in visibleItems"
                :key="index"
                @click="item.handler"
            >
                <ra-stack direction="horizontal">
                    <v-icon
                        color="primary"
                        size="small"
                        @click="item.handler()"
                        >{{ item.icon }}</v-icon
                    >
                    <ra-typo>{{ item.title }}</ra-typo>
                </ra-stack>
            </v-list-item>
        </v-list>
    </v-menu>
    <ra-dialog ref="dialog-share">
        <ra-base-dialog
            :header="{
                bg: 'primary-gradient',
            }"
        >
            <template #title>
                <span>{{ $t('workshop.share') }}</span>
            </template>
            <ra-box padding="1">
                <p class="font-weight-medium">
                    Partagez cette page aux responsables de l'action et aux
                    formateurs.
                </p>
            </ra-box>
        </ra-base-dialog>
    </ra-dialog>
</template>
