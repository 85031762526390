import { defineStore } from 'pinia'
import i18n from '@core/i18n/index.js'

export const useGlobalSnackBar = defineStore('globalSnackBar', {
    state: () => ({
        snackBar: null,
    }),

    getters: {
        displaySnackBar(state) {
            return state.snackBar != null
        },
    },
    actions: {
        _setSnackBar(snackBarState) {
            this.snackBar = {
                closable: true,
                class: [],
                ...this.snackBar,
                ...snackBarState,
            }
        },
        hideSnackBar() {
            this.snackBar = null
        },

        /**
         * Display a snackbar with a title and a duration
         * @param html
         * @param duration
         * @param options
         */
        showSnackBar(html, options = {}) {
            let htmlTranslated = i18n.global.t(html)
            this._setSnackBar({
                html: htmlTranslated,
                color: 'success',
                class: [],
                ...options,
            })
        },
    },
})
