import { formersRoutesMeta as meta } from '../Former/meta.js'
import MyWorkshopIndex from '@pages/My-Workshops/My-Workshops.vue'
import MyWorkshopShow from '@pages/My-Workshops/MyWorkshopsShowPage.vue'
import MyWorkshopInvite from '@pages/My-Workshops/MyWorkshopsInvitePage.vue'
import MyWorkshopInscription from '@pages/My-Workshops/MyWorkshopsInscriptionPage.vue'
import MyWorkshopResult from '@pages/My-Workshops/MyWorkshopsResultPage.vue'
import MyWorkshopSurvey from '@pages/My-Workshops/MyWorkshopsSurveyPage.vue'
import MyWorkshopCreate from '@pages/My-Workshops/Create.vue'
import { WorkshopRoutesNames } from '@core/router/routes/generators/Workshop.names.js'

export default [
    {
        path: '/workshops',
        children: [
            {
                path: '',
                name: WorkshopRoutesNames.index,
                meta,
                component: MyWorkshopIndex,
            },
            {
                path: 'inscription/:workshop_id',
                name: WorkshopRoutesNames.inscription,
                component: MyWorkshopInscription,
            },
            {
                path: 'show/:workshop_id',
                name: WorkshopRoutesNames.invite,
                component: MyWorkshopInvite,
            },
            {
                path: 'create',
                name: WorkshopRoutesNames.createtype,
                meta,
                component: MyWorkshopCreate,
            },
            {
                path: 'create/:type',
                name: WorkshopRoutesNames.create,
                meta,
                component: MyWorkshopCreate,
            },
            {
                path: 'edit/:workshop_id',
                name: WorkshopRoutesNames.edit,
                meta,
                component: MyWorkshopCreate,
            },
            {
                path: 'survey/:workshop_id',
                name: WorkshopRoutesNames.survey,
                component: MyWorkshopSurvey,
            },
            {
                path: 'result/:workshop_id',
                name: WorkshopRoutesNames.result,
                component: MyWorkshopResult,
            },
            {
                path: ':workshop_id',
                name: WorkshopRoutesNames.show,
                meta,
                component: MyWorkshopShow,
            },
        ],
    },
]
