<script>
    import { Skeletor } from 'vue-skeletor'

    export default {
        name: 'HeadsetCompanyCounter',
        components: {
            Skeletor,
        },
        props: {
            max: {
                type: Number,
                required: true,
            },
            count: {
                type: Number,
                required: true,
            },
            density: {
                type: String,
                default: 'normal',
            },
        },
        computed: {
            sizes() {
                if (this.density === 'normal') {
                    return {
                        padding: '3,6',
                        bar: '10px',
                        textVariant: 'main-title',
                    }
                } else if (this.density === 'comfortable') {
                    return {
                        padding: '2,4',
                        bar: '8px',
                        textVariant: 'title',
                    }
                } else if (this.density === 'compact') {
                    return {
                        padding: '1,2',
                        bar: '6px',
                        textVariant: 'title',
                    }
                }
            },
        },
    }
</script>

<template>
    <ra-box
        bg="white"
        rounded
    >
        <ra-box :padding="sizes.padding">
            <template v-if="Boolean(max) || max === 0">
                <ra-typo
                    :variant="sizes.textVariant"
                    bold
                    data-test="headset-company-counter"
                >
                    {{ count }} <span v-if="max > 0">/ {{ max }}</span>
                </ra-typo>
                <ra-typo>
                    {{ $tc('headsets._used', count) }}
                </ra-typo>
            </template>
            <Skeletor
                v-else
                :height="sizes.textVariant === 'main-title' ? '60' : '50'"
                width="100"
            />
        </ra-box>

        <ra-box
            :height="sizes.bar"
            bg="primary-gradient"
        />
    </ra-box>
</template>
