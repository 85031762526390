import { EventEmmiter } from '@utils/EventEmmiter.js'
import getNestedProperty from '@utils/getNestedProperty.js'

/**
 * Collection represent an array of non defined type data
 * It allows you to fetch data from api and mutate the collection with the response data
 */
export class Collection extends Array {
    /**
     * Create a new collection
     * @param array the array to initialize the collection
     */
    constructor(array) {
        super(
            array instanceof Array
                ? array.length
                : Number.isFinite(array)
                  ? array
                  : 0
        )
        this.fetcher = null
        this.loading = false
        this.lastFetchedAt = null

        this.emitter = new EventEmmiter()

        if (array instanceof Array) this.set(array)
    }

    /**
     * Empty the collection
     */
    empty() {
        this.splice(0, this.length)
        return this
    }

    /**
     * Set the collection with the given array
     * @param array
     * @returns {void|null}
     */
    set(array) {
        if (!Array.isArray(array)) {
            return null
            return console.error(
                new Error(
                    'Collection.set, property array must be valid type array'
                ),
                array
            )
        } else {
            this.empty()
            this.push(...array)
            return this
        }
    }

    /**
     * Set the fetcher function to fetch data from api
     * @param fetcher
     */
    setFetcher(fetcher) {
        this.fetcher = fetcher
        return this
    }

    /**
     * Fetch data from api using the fetcher it must be set before, it will mutate the collection with the response api data
     * @param query
     * @returns {Promise<*>}
     */
    async fetch(query = {}, evaluatedResponse = 'data') {
        if (!this.fetcher)
            throw new Error('Collection.fetch, fetcher must be set before')
        this.lastFetchedAt = new Date()
        this.loading = true
        query = { ...query }
        let response = await this.fetcher(query).call()
        this.loading = false
        this.emit('fetched', this)
        if ('fetchPaginated' in this) return response
        else {
            this.set(getNestedProperty(response, evaluatedResponse))
        }
    }

    /**
     * get unique element by the given key
     * @param by the key to compare
     * @returns {T[]}
     */
    unique(by = 'id') {
        return this.filter(
            (v, i, a) => a.findIndex((t) => t[by] === v[by]) === i
        )
    }

    /**
     * check if the collection is empty
     * @returns {boolean}
     */
    isEmpty() {
        return this.length === 0
    }

    /**
     * Except the given elements from the collection
     */
    except(...elements) {
        return this.filter((e) => !elements.includes(e))
    }

    /**
     * Except the given elements from the collection using another collection
     * @param {Collection} collection
     * @returns {Collection}
     */
    excludeBy(collection, by = 'id') {
        return this.filter((e) => !collection.some((c) => c[by] == e[by]))
    }

    /**
     * Merge the given collection with the current collection
     * All fetching properties will be lost
     * @param collection
     */
    merge(collection) {
        return new this.constructor([...this, ...collection])
    }

    clone() {
        return new this.constructor(JSON.parse(JSON.stringify(this.toArray())))
    }

    /**
     * Get element by id
     */
    findById(id) {
        return this.find((e) => e.id == id)
    }

    //overwrite array methods to set the pagination
    filter(...args) {
        const collection = new this.constructor(super.filter(...args))
        collection.setFetcher(this.fetcher)
        return collection
    }

    /**
     * Return a new array with the collection elements
     * @returns {*[]}
     */
    toArray() {
        return [...this]
    }

    on(...args) {
        this.emitter.on(...args)
        return this
    }

    emit(...args) {
        this.emitter.emit(...args)
        return this
    }

    off(...args) {
        this.emitter.off(...args)
        return this
    }
}
