<script>
export default {
  name: "HeadsetSpecificationTableCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
  },
};
</script>

<template>
  <ra-card>
    <template #title>
      {{ title }}
    </template>
    <ra-box>
      <ra-stack
        v-for="(row, i) in rows"
        class="headset-specification-row"
        direction="horizontal"
        padding="3,"
      >
        <ra-typo semi variant="x-small"> {{ row.label }} :</ra-typo>

        <slot :name="row?.slot || 'row' + i" v-bind="row">
          <ra-typo variant="x-small">
            {{ row.value }}
          </ra-typo>
        </slot>
      </ra-stack>
    </ra-box>
  </ra-card>
</template>

<style lang="scss" scoped>
.headset-specification-row {
  & + & {
    $c: #090954;
    border-top: 1px rgba($c, 0.1) solid;
  }

  &:last-child {
    padding-bottom: 0 !important;
  }
}
</style>
