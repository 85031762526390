import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import i18n from '@core/i18n/index.js'

//keeps these imports for pdf
import MontserratBoldFont from '@fonts/Montserrat-Bold-normal'
import MontserratRegularFont from '@fonts/Montserrat-Regular-normal'
export class PDFExport {
    constructor(containerRef) {
        this.container = containerRef
        this.doc = null
        this.generating = false
        this.config = {
            width: 'auto',
            height: 'auto',
            zoomCoef: 1.5,
            containerWidth: 1440,
            rowWidth: 395,
            margin: 25,
            maxPageFill: 540,
            backgroundImage: true,
        }
    }

    get pages() {
        let elements = this.container.querySelectorAll(
            '[data-pdf-page], [data-pdf-row]'
        )
        let pages = []

        elements.forEach((element) => {
            try {
                let page = element.getAttribute('data-pdf-page')
                let row = element.getAttribute('data-pdf-row')
                let pageNumber = Number(page) - 1
                let rowNumber = Number(row) - 1
                if (pages[pageNumber] === undefined) pages[pageNumber] = []
                pages[pageNumber][rowNumber] = {
                    box: element.getBoundingClientRect(),
                    dom: element,
                    img: null,
                }
            } catch (error) {
                console.warn(
                    'WARN PDF Export: the className has not the correct syntax',
                    error
                )
            }
        })
        return pages
    }

    $t(...args) {
        return i18n.global.t(...args)
    }

    $tc(...args) {
        return i18n.global.tc(...args)
    }

    async screenElement(element) {
        console.log('PDF Export: capture element', element)
        let canvas = await html2canvas(element.dom)
        if (canvas && canvas.width && canvas.height) {
            element.img = canvas.toDataURL('image/png') //evite les images vides
            element.box.width = canvas.width
            element.box.height = canvas.height
        }
        return element
    }

    header() {}
    footer() {}

    export() {
        this.generating = true
        let meta = document.querySelector('meta[name=viewport]')
        let oldMetaContent = meta.getAttribute('content')
        meta.setAttribute('content', 'width=' + this.config.containerWidth)

        return new Promise((resolve, rej) => {
            try {
                setTimeout(async () => {
                    let pagesScreened = await Promise.all(
                        this.pages.map(async (page) => {
                            let pageScreened = await Promise.all(
                                page.map(await this.screenElement)
                            )
                            return pageScreened.filter(
                                (e) => e != null && e.img
                            )
                        })
                    )
                    resolve(await this.generatePDF(pagesScreened))
                    this.generating = false
                    meta.setAttribute('content', oldMetaContent)
                }, 100)
            } catch (error) {
                this.generating = false
                meta.setAttribute('content', oldMetaContent)
                rej(error)
            }
            //return this.generatePDF(pagesScreened);
        })
    }

    async generatePDF(pagesScreened) {
        this.doc = new jsPDF('p', 'px', 'a4', true)
        this.createPDFPage(false)
        let offsets = this.config.offsets ? this.config.offsets : [125, 50]
        pagesScreened.forEach((page, i) => {
            if (i > 0) this.createPDFPage(true)
            let offset = offsets[i]
            let takenPlace = offset
            page.forEach((element) => {
                let w = this.config.rowWidth
                let h = (w * element.box.height) / element.box.width

                if (takenPlace + h > this.config.maxPageFill) {
                    let ratioReduce = (this.config.maxPageFill - takenPlace) / h
                    w = w * ratioReduce
                    h = h * ratioReduce
                }

                try {
                    this.doc.addImage(
                        element.img,
                        'PNG',
                        this.config.margin,
                        offset,
                        w,
                        h
                    )
                    offset += h + 5
                    takenPlace += h + 5
                } catch (error) {
                    console.error('ERROR PDF Export: ', error, element)
                }
            })
        })
    }

    generateHeader() {
        //rectangle d'entête
        this.doc.setFillColor('#B8348B')
        this.doc.rect(0, 0, 500, 3, 'F')
        //ligne horizontal
        this.doc.setDrawColor(9, 9, 84)
        this.doc.setLineWidth(0.5)
        this.doc.line(15, 30, 430, 30)
        //custom header
        this.header()
    }

    generateFooter() {
        //Logo de reality
        this.doc.addImage('/images/RA.png', 'PNG', 150, 600, 150, 12)
        //Ligne du footer
        this.doc.setDrawColor('#B8348B')
        this.doc.setLineWidth(0.5)
        this.doc.line(15, 570, 430, 570)
        //custom footer
        this.footer()
    }

    createPDFPage(addPage = false) {
        if (addPage) this.doc.addPage()
        this.doc.setFillColor(this.config.backgroundColor ?? '#FBFAFF')
        this.doc.rect(0, 0, 500, 1000, 'F')
        this.doc.rect(0, 0, 500, 1000, 'F')
        if (this.config.backgroundImage) {
            this.doc.addImage(
                '/images/rectangle.png',
                'PNG',
                100,
                -200,
                300,
                400
            )
            this.doc.addImage(
                '/images/rectangle.png',
                'PNG',
                100,
                150,
                300,
                400
            )
            this.doc.addImage(
                '/images/rectangle.png',
                'PNG',
                -150,
                150,
                300,
                400
            )
        }

        this.generateHeader()
        this.generateFooter()
    }

    download(filename = 'export.pdf') {
        if (this.generating) return
        this.doc.save(filename)
    }
}
