import AffectGroupDialogContainer from '../components/Groups/AffectGroupDialogContainer.vue'

export default {
    methods: {
        __affect_group_mixin_test_context() {
            const refStr = this.getAffectGroupDialogRef()
            if (!Boolean(refStr in this.$refs))
                throw new Error(
                    "AffectGroupMixin : 'dialog-affect-group' ref not found on this vue"
                )
            if (typeof this.$refs[refStr].openDialog !== 'function')
                throw new Error(
                    "AffectGroupMixin : 'openDialog' function not found on the ref of the vue"
                )
            if (!Array.isArray(this.selected))
                throw new Error(
                    "AffectGroupMixin : 'selected' props array not found on this vue"
                )
        },
        getAffectGroupItemAction(icon = undefined) {
            return {
                label: this.$t('groups.affect.title'),
                icon,
                handler: (item) => {
                    this.__affect_group_mixin_test_context()
                    this.selected = [item]
                    this.$refs[this.getAffectGroupDialogRef()].openDialog(
                        'dialog-affect-group'
                    )
                },
            }
        },
        getAffectGroupGrouppedAction(icon = undefined) {
            return {
                label: this.$t('groups.affect.title'),
                icon,
                handler: () => {
                    this.__affect_group_mixin_test_context()
                    this.$refs[this.getAffectGroupDialogRef()].openDialog(
                        'dialog-affect-group'
                    )
                },
            }
        },
        getAffectGroupDialog() {
            return AffectGroupDialogContainer
        },
        getAffectGroupDialogRef() {
            return 'dialog-affect-group'
        },
    },
}
