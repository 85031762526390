<script>
    import { humanizeBytes } from '@utils/humanizeBytes.js'

    export default {
        name: 'HeadsetReleaseInstalledCard',
        props: {
            releaseInstalled: {
                type: Object,
                required: true,
            },
        },
        methods: {
            humanizeBytes(...args) {
                return humanizeBytes(...args)
            },
        },
    }
</script>

<template>
    <web>
        <ra-card>
            <template #title> Application</template>
            <ra-stack
                alignement="strech"
                direction="horizontal"
                gap="4"
                padding="4,"
                wrap
            >
                <div>
                    <img
                        alt="version_image"
                        height="70"
                        src="@assets/images/logos/RA_placeholder.png"
                    />
                </div>
                <ra-stack
                    gap="1"
                    width="auto"
                >
                    <ra-typo
                        semi
                        uppercase
                    >
                        {{ releaseInstalled.name }}</ra-typo
                    >
                    <ra-stack
                        direction="horizontal"
                        wrap
                    >
                        <ra-typo variant="x-small">
                            <ra-typo
                                semi
                                span
                            >
                                {{
                                    $t(
                                        'headsets.settings.headset_application_release.version'
                                    )
                                }}
                            </ra-typo>
                            {{ releaseInstalled.version }}
                        </ra-typo>
                        <ra-typo variant="x-small">
                            <ra-typo
                                semi
                                span
                            >
                                {{
                                    $t(
                                        'headsets.settings.headset_application_release.size'
                                    )
                                }}
                            </ra-typo>
                            {{ humanizeBytes(releaseInstalled.size) }}
                        </ra-typo>
                    </ra-stack>
                    <ra-typo variant="x-small">
                        <ra-typo
                            semi
                            span
                        >
                            {{
                                $t(
                                    'headsets.settings.headset_application_release.version'
                                )
                            }}
                        </ra-typo>
                        {{
                            $filters.formatDate(
                                releaseInstalled.installedAt,
                                'L'
                            )
                        }}
                    </ra-typo>
                </ra-stack>
            </ra-stack>
        </ra-card>
    </web>
    <tablet>
        <ra-stack
            bg="tablet-light"
            padding="4"
            rounded
        >
            <ra-typo
                bold
                variant="subtitle"
                >Application</ra-typo
            >
            <ra-stack
                alignement="strech"
                direction="horizontal"
                gap="4"
            >
                <div>
                    <img
                        alt="version_image"
                        height="40"
                        src="@assets/images/logos/RA_placeholder.png"
                    />
                </div>
                <ra-stack
                    gap="1"
                    width="auto"
                >
                    <ra-typo
                        semi
                        uppercase
                        variant="x-small"
                    >
                        Reality Academy</ra-typo
                    >
                    <ra-stack
                        direction="horizontal"
                        wrap
                    >
                        <ra-typo variant="x-small">
                            <ra-typo
                                semi
                                span
                            >
                                {{
                                    $t(
                                        'headsets.settings.headset_application_release.version'
                                    )
                                }}
                            </ra-typo>
                            {{ releaseInstalled.version }}
                        </ra-typo>
                        <ra-typo variant="x-small">
                            <ra-typo
                                semi
                                span
                            >
                                {{
                                    $t(
                                        'headsets.settings.headset_application_release.size'
                                    )
                                }}
                            </ra-typo>
                            {{ humanizeBytes(releaseInstalled.size) }}
                        </ra-typo>
                    </ra-stack>
                    <ra-typo variant="x-small">
                        <ra-typo
                            semi
                            span
                        >
                            {{
                                $t(
                                    'headsets.settings.headset_application_release.last_update'
                                )
                            }}
                        </ra-typo>
                        {{
                            $filters.formatDate(
                                releaseInstalled.installedAt,
                                'L'
                            )
                        }}
                    </ra-typo>
                </ra-stack>
            </ra-stack>
        </ra-stack>
    </tablet>
</template>
